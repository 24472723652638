import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'

export function useNativeBalance(
  account?: string | null,
  provider?: JsonRpcProvider | Web3Provider
): BigNumber | undefined {
  const [balance, setBalance] = useState<BigNumber | undefined>()

  useEffect(() => {
    if (!account || !provider) return

    async function fetchBalance() {
      if (!account || !provider) return
      try {
        const balance = await provider.getBalance(account)
        setBalance(balance)
      } catch (error) {
        console.error('Error fetching native balance:', error)
        setBalance(undefined)
      }
    }

    fetchBalance()
  }, [account, provider])

  return balance
}
