import { Application, Texture, extras, Container } from 'pixi.js'

// Function to initialize the player's animated sprite
export function initializePlayerSprite(
  _app: Application,
  textures: Texture[][],
  playerContainer: Container,
  startTile: { x: number; y: number }
): extras.AnimatedSprite {
  const animatedSprite = new extras.AnimatedSprite(textures[0])
  animatedSprite.anchor.set(0.5, 0.5)
  animatedSprite.width = 98
  animatedSprite.height = 98
  animatedSprite.x = startTile.x
  animatedSprite.y = startTile.y
  animatedSprite.animationSpeed = 0.1
  animatedSprite.play()

  playerContainer.addChild(animatedSprite)
  return animatedSprite
}
