import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useMemo } from 'react'
import { useActiveWeb3React } from './index'
import useBlockchain from './useBlockchain'
import useTokenWETHPrice from './useTokenWETHPrice'
import getToken from '../utils/getToken'
import { Token } from '@/lib/sdk/index'

export function useHarmonyTokensWithWETHPrices(dappData: TDappData): Record<string, any> {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()

  // Harmony-specific tokens
  const bscBUSD: Token | undefined = getToken(chainId, 'bscBUSD', settings.wrappedCurrency, settings.chainTokenList)
  const bscBUSDWETHPrice = useTokenWETHPrice(bscBUSD, dappData)

  const bridgedETH: Token | undefined = getToken(chainId, '1ETH', settings.wrappedCurrency, settings.chainTokenList)
  const bridgedETHWETHPrice = useTokenWETHPrice(bridgedETH, dappData)

  const bscBNB: Token | undefined = getToken(chainId, 'bscBNB', settings.wrappedCurrency, settings.chainTokenList)
  const bscBNBWETHPrice = useTokenWETHPrice(bscBNB, dappData)

  // Return only valid tokens (filtering out undefined)
  return {
    ...(bscBUSD && { bscBUSD: { token: bscBUSD, price: bscBUSDWETHPrice } }),
    ...(bridgedETH && { bridgedETH: { token: bridgedETH, price: bridgedETHWETHPrice } }),
    ...(bscBNB && { bscBNB: { token: bscBNB, price: bscBNBWETHPrice } })
  }
}

export default function useTokensWithWETHPrices(dappData: TDappData): Record<string, any> {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const blockchain = useBlockchain()
  const overrideTokensWithPrice = useHarmonyTokensWithWETHPrices(dappData)

  const weth = chainId && settings.wrappedCurrency
  const govTokenWETHPrice = useTokenWETHPrice(dappData.governanceToken, dappData)

  const BUSDTicker = 'BUSD'
  const BUSD: Token | undefined = getToken(chainId, BUSDTicker, settings.wrappedCurrency, settings.chainTokenList)
  const BUSDWETHPrice = useTokenWETHPrice(BUSD, dappData)

  const USDCTicker = 'USDC'
  const USDC: Token | undefined = getToken(chainId, USDCTicker, settings.wrappedCurrency, settings.chainTokenList)
  const USDCWETHPrice = useTokenWETHPrice(USDC, dappData)

  const STABLE: Token | undefined = settings.stablePriceTokenToOverride
  const STABLEWETHPrice = useTokenWETHPrice(STABLE, dappData)

  return useMemo(() => {
    return {
      WETH: { token: weth, price: undefined },
      govToken: { token: dappData.governanceToken, price: govTokenWETHPrice },
      BUSD: { token: BUSD, price: BUSDWETHPrice },
      USDC: { token: USDC, price: USDCWETHPrice },
      STABLE: { token: STABLE, price: STABLEWETHPrice },
      ...overrideTokensWithPrice
    }
  }, [
    chainId,
    blockchain,
    weth,
    dappData.governanceToken,
    govTokenWETHPrice,
    BUSD,
    BUSDWETHPrice,
    USDC,
    USDCWETHPrice,
    STABLE,
    STABLEWETHPrice,
    overrideTokensWithPrice
  ])
}
