import { getTokenFromDefaultTokenList, getTokenLogoURL } from 'components/CurrencyLogo'
import { ChainId } from 'constants/settings'
import Vibrant from 'node-vibrant'
import { shade } from 'polished'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useState, useLayoutEffect } from 'react'
import uriToHttp from 'utils/uriToHttp'
import { hex } from 'wcag-contrast'
import { Token } from '@/lib/sdk/index'

async function getColorFromToken(token: Token, chainId: number): Promise<string | null> {
  if (token.chainId === ChainId.RINKEBY && token.address === '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735') {
    return Promise.resolve('#FAAB14')
  }

  const path = [chainId].includes(token.chainId)
    ? getTokenFromDefaultTokenList(token.address, token.chainId, token.symbol ?? 'null')
    : getTokenLogoURL(token.address)

  return Vibrant.from(path)
    .getPalette()
    .then(palette => {
      if (palette?.Vibrant) {
        let detectedHex = palette.Vibrant.hex
        let AAscore = hex(detectedHex, '#FFF')
        while (AAscore < 3) {
          detectedHex = shade(0.005, detectedHex)
          AAscore = hex(detectedHex, '#FFF')
        }
        return detectedHex
      }
      return null
    })
    .catch(() => null)
}

async function getColorFromUriPath(uri: string): Promise<string | null> {
  const formattedPath = uriToHttp(uri)[0]

  return Vibrant.from(formattedPath)
    .getPalette()
    .then(palette => {
      if (palette?.Vibrant) {
        return palette.Vibrant.hex
      }
      return null
    })
    .catch(() => null)
}

export function useColor(token?: Token) {
  const [color, setColor] = useState('#2172E5')
  const { theme } = useAppSettings()

  useLayoutEffect(() => {
    return () => {
      setColor(theme.tokenColor)
    }
  }, [token])

  return color
}

export function useListColor(listImageUri?: string) {
  const [color, setColor] = useState('#2172E5')

  useLayoutEffect(() => {
    let stale = false

    if (listImageUri) {
      getColorFromUriPath(listImageUri).then(color => {
        if (!stale && color !== null) {
          setColor(color)
        }
      })
    }

    return () => {
      stale = true
      setColor('#2172E5')
    }
  }, [listImageUri])

  return color
}
