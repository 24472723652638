import { TDappData } from 'constants/settings/types/TNpcs'
import { StakingRewardsInfo } from 'hooks/useStakingRewardsInfo'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React from 'react'
import styled from 'styled-components'
import { OutlineCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import AwaitingRewards from '../../components/farms/AwaitingRewards'
import PoolCard from '../../components/farms/PoolCard'
import { CardSection, ExtraDataCard, CardNoise, CardBGImage } from '../../components/farms/styled'
import Loader from '../../components/Loader'
import { RowBetween } from '../../components/Row'
import { useActiveWeb3React } from '../../hooks'
import useBaseStakingRewardsEmission from '../../hooks/useBaseStakingRewardsEmission'
import useFilterStakingInfos from '../../hooks/useFilterStakingInfos'
import { useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import { JSBI } from '@/lib/sdk/index'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

interface EarnArchivedProps {
  dappData: TDappData
  stakingInfo: StakingRewardsInfo[]
}

export default function EarnArchived({ dappData, stakingInfo }: EarnArchivedProps) {
  const { chainId, account } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const blockchainSettings = chainId ? settings.blockchainSettings : undefined
  const stakingInfos = useStakingInfo(false, dappData, stakingInfo)

  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  //const stakingInfosWithBalance = stakingInfos?.filter(s => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))

  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (stakingInfo?.length ?? 0) > 0)

  const baseEmissions = useBaseStakingRewardsEmission(dappData)
  const blocksPerDay = blockchainSettings?.blockTime ? (60 / blockchainSettings.blockTime) * 60 * 24 : 0
  const emissionsPerDay =
    baseEmissions && blockchainSettings ? baseEmissions.multiply(JSBI.BigInt(Math.round(blocksPerDay))) : undefined

  const inactiveStakingInfos = useFilterStakingInfos(stakingInfos, false)

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <ExtraDataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>{dappData.governanceToken?.symbol} Farming Pools</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit your Liquidity Provider tokens to receive {dappData.governanceToken?.symbol}, the{' '}
                  {dappData.governanceToken?.name} Protocol governance token.
                </TYPE.white>
              </RowBetween>{' '}
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </ExtraDataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Pools</TYPE.mediumHeader>
        </DataRow>

        <AwaitingRewards />

        <PoolSection>
          {account && stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : account && !stakingRewardsExist ? (
            <OutlineCard>No active pools</OutlineCard>
          ) : account && stakingInfos?.length !== 0 && !inactiveStakingInfos ? (
            <OutlineCard>No active pools</OutlineCard>
          ) : !account ? (
            <OutlineCard>Please connect your wallet to see available pools</OutlineCard>
          ) : (
            inactiveStakingInfos?.map(stakingInfo => {
              // need to sort by added liquidity here
              return <PoolCard dappData={dappData} key={stakingInfo.pid} stakingInfo={stakingInfo} isArchived={true} />
            })
          )}
        </PoolSection>

        {stakingRewardsExist && baseEmissions && (
          <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
            The base emission rate is currently <b>{baseEmissions.toSignificant(4, { groupSeparator: ',' })}</b>{' '}
            {dappData.governanceToken?.symbol} per block.
            <br />
            <b>{emissionsPerDay?.toSignificant(4, { groupSeparator: ',' })}</b> {dappData.governanceToken?.symbol} will
            be minted every day given the current emission schedule.
            <br />
            <br />
            <TYPE.small style={{ textAlign: 'center' }} fontSize={10}>
              * = The APR is calculated using a very simplified formula, it might not fully represent the exact APR
              <br />
              when factoring in the dynamic emission schedule and the locked/unlocked rewards vesting system.
            </TYPE.small>
          </TYPE.main>
        )}
      </AutoColumn>
    </PageWrapper>
  )
}
