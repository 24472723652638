import { Interface } from '@ethersproject/abi'
import { abi as TOKEN_EXCHANGE_DECIMAL_SUPP_ABI_V1 } from './contracts/token-exchange-decimal-support.json'
import { abi as TOKEN_EXCHANGE_DECIMAL_SUPP_ABI_V2 } from './contracts/token-exchange-decimal-supportV2.json'

const TOKEN_EXCHANGE_DECIMAL_SUPP_INTERFACE_V1 = new Interface(TOKEN_EXCHANGE_DECIMAL_SUPP_ABI_V1)
const TOKEN_EXCHANGE_DECIMAL_SUPP_INTERFACE_V2 = new Interface(TOKEN_EXCHANGE_DECIMAL_SUPP_ABI_V2)

export default TOKEN_EXCHANGE_DECIMAL_SUPP_INTERFACE_V2
export {
  TOKEN_EXCHANGE_DECIMAL_SUPP_ABI_V1,
  TOKEN_EXCHANGE_DECIMAL_SUPP_INTERFACE_V1,
  TOKEN_EXCHANGE_DECIMAL_SUPP_ABI_V2,
  TOKEN_EXCHANGE_DECIMAL_SUPP_INTERFACE_V2
}
