import { AutoColumn } from 'components/Column'
import DialogueModal from 'components/Dialogue/DialogueModal'
import Modal from 'components/Modal'
import TWIN_VAULT_INTERFACE from 'constants/abis/twinVault'
import { TDappData } from 'constants/settings/types/TNpcs'
import { BigNumber } from 'ethers'
import React, { useState, useEffect, useMemo } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { useMultipleContractSingleData } from 'state/multicall/hooks'
import { CustomLightSpinner } from 'theme/components'
import TwinVault from './TwinVault'
import { StatusLabel, MenuItem, TabContainer, TabButton, ContentWrapperModal, ContentWrapper } from './TwinVault.style'
import { TwinVaultProvider } from './TwinVaultProvider'
import Circle from '../../assets/svg/blue-loader.svg'
interface TwinVaultModalProps {
  dappData: TDappData
}

export default function TwinVaultModal({ dappData }: TwinVaultModalProps) {
  // hook(s)
  const currentBlock = useBlockNumber()
  // Contract vaultAddresses to check
  const vaultAddresses = useMemo(() => dappData.twinVaults?.map(vault => vault.contractAddress) ?? [], [dappData])
  // Fetch bonusEndBlock for each contract
  const callResults = useMultipleContractSingleData(vaultAddresses, TWIN_VAULT_INTERFACE, 'bonusEndBlock', [])
  const hasVaultEnded = useMemo(
    () =>
      // Determine if each vault has ended
      callResults.map(result => {
        const bonusEndBlock = result.result ? BigNumber.from(result.result[0]) : null
        return bonusEndBlock ? currentBlock && currentBlock > bonusEndBlock.toNumber() : false
      }),
    [callResults, currentBlock]
  )

  // state(s)
  const [loading, setLoading] = useState<boolean>(true)
  const [showParentModal, setShowParentModal] = useState(true)
  const [selectedTab, setSelectedTab] = useState<'live' | 'archived'>('live') // Tab state
  const [showChildModals, setShowChildModals] = useState<boolean[]>([])

  // var(s)
  const liveVaults = dappData.twinVaults?.filter((_, index) => !hasVaultEnded[index]) ?? []
  const archivedVaults = dappData.twinVaults?.filter((_, index) => hasVaultEnded[index]) ?? []

  // side-effect(s)
  // Check if loading is complete
  useEffect(() => {
    const allResultsLoaded = callResults.every(result => !result.loading)
    setLoading(!allResultsLoaded)
  }, [callResults])

  useEffect(() => {
    if (dappData?.twinVaults) {
      setShowChildModals(new Array(dappData.twinVaults.length).fill(false))
    }
  }, [dappData])

  // callback(s)
  // Handlers for modals
  const openChildModal = (index: number) => {
    setShowChildModals(prev => prev.map((show, i) => (i === index ? true : show)))
  }

  const closeChildModal = (index: number) => {
    setShowChildModals(prev => prev.map((show, i) => (i === index ? false : show)))
  }

  const closeParentModal = () => {
    setShowParentModal(false)
    setShowChildModals(new Array(showChildModals.length).fill(false)) // Close all child modals when parent closes
  }

  // return(s)
  // Loading view for token data
  if (loading) {
    return (
      <ContentWrapper gap="lg">
        <AutoColumn gap="12px" justify="center">
          <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
        </AutoColumn>
      </ContentWrapper>
    )
  }
  return (
    <>
      {/* Tab Navigation */}
      <TabContainer>
        <TabButton active={selectedTab === 'live'} onClick={() => setSelectedTab('live')}>
          Live Vaults
        </TabButton>
        <TabButton active={selectedTab === 'archived'} onClick={() => setSelectedTab('archived')}>
          Archived Vaults
        </TabButton>
      </TabContainer>

      {/* Content based on selected tab */}
      <ContentWrapperModal>
        {selectedTab === 'live' &&
          (liveVaults.length > 0 ? (
            liveVaults.map((vault, index) => (
              <div key={`${index}-${vault.contractAddress}`} onClick={() => openChildModal(index)}>
                <MenuItem isOpen={true}>{vault.twinVaultModalTitle}</MenuItem>
                <TwinVaultProvider twinVault={vault} key={index} dappData={dappData}>
                  <Modal isOpen={showChildModals[index]} onDismiss={() => closeChildModal(index)}>
                    <TwinVault onDismiss={() => closeChildModal(index)} />
                  </Modal>
                </TwinVaultProvider>
              </div>
            ))
          ) : (
            <p>No live vaults available.</p>
          ))}

        {selectedTab === 'archived' &&
          (archivedVaults.length > 0 ? (
            archivedVaults.map((vault, index) => (
              <div key={`${index}-${vault.contractAddress}`} onClick={() => openChildModal(index)}>
                <MenuItem isOpen={false}>
                  <StatusLabel>Ended</StatusLabel>
                  {vault.twinVaultModalTitle}
                </MenuItem>
                <TwinVaultProvider twinVault={vault} key={index} dappData={dappData}>
                  <Modal isOpen={showChildModals[index]} onDismiss={() => closeChildModal(index)}>
                    <TwinVault onDismiss={() => closeChildModal(index)} />
                  </Modal>
                </TwinVaultProvider>
              </div>
            ))
          ) : (
            <p>No archived vaults available.</p>
          ))}
      </ContentWrapperModal>
    </>
  )
}
