import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React from 'react'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'
import getBlockchainAdjustedCurrency from '../../utils/getBlockchainAdjustedCurrency'
import { Price } from '@/lib/sdk/index'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)
  const { settings } = useAppSettings()

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)

  const quoteCurrency = getBlockchainAdjustedCurrency(settings.blockchainSettings.currency, price?.quoteCurrency)
  const baseCurrency = getBlockchainAdjustedCurrency(settings.blockchainSettings.currency, price?.baseCurrency)

  const label = showInverted
    ? `${quoteCurrency?.symbol} per ${baseCurrency?.symbol}`
    : `${baseCurrency?.symbol} per ${quoteCurrency?.symbol}`

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
