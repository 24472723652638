import { ChainId } from 'constants/settings'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import baseCurrencies from '../../utils/baseCurrencies'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import { Currency, currencyEquals, Token } from '@/lib/sdk/index'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  background-color: ${({ theme, disable }) => disable && theme.bg3};
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  dappData
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
  dappData: TDappData
}) {
  const { settings } = useAppSettings()
  const baseCurrency = baseCurrencies(settings.blockchainSettings.currency, settings.wrappedCurrency)[0]

  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontWeight={500} fontSize={14}>
          Common bases
        </Text>
        <QuestionHelper text="These tokens are commonly paired with other tokens." />
      </AutoRow>
      <AutoRow gap="4px">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, baseCurrency)) {
              onSelect(baseCurrency)
            }
          }}
          disable={selectedCurrency === baseCurrency}
        >
          <CurrencyLogo dappData={dappData} currency={baseCurrency} style={{ marginRight: 8 }} />
          <Text fontWeight={500} fontSize={16}>
            {baseCurrencies(settings.blockchainSettings.currency, settings.wrappedCurrency)[0].symbol}
          </Text>
        </BaseWrapper>
        {(chainId ? settings.suggestedBases : []).map((token: Token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address
          return (
            <BaseWrapper
              onClick={() => !selected && onSelect(token)}
              disable={selected}
              key={token.address + Math.random()}
            >
              <CurrencyLogo dappData={dappData} currency={token} style={{ marginRight: 8 }} />
              <Text fontWeight={500} fontSize={16}>
                {token.symbol}
              </Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </AutoColumn>
  )
}
