import { DEFAULT_CURRENCY } from 'constants/settings'
import isEqual from 'lodash.isequal'
import { Currency } from '@/lib/sdk/index'

export function currencyId(currency: any, baseCurrency: Currency, nativeCurrency: Currency): string {
  if (currency) {
    const isNativeCurrencyIncluded = [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, currency))
    if (isNativeCurrencyIncluded) {
      return baseCurrency && baseCurrency.symbol ? baseCurrency.symbol : 'ETH'
    }
  }
  if (currency && currency.address) return currency.address
  throw new Error('invalid currency')
}
