import { Web3Provider } from '@ethersproject/providers'
import { ERC20_ABI } from 'constants/abis/erc20'
import { TDappData } from 'constants/settings/types/TNpcs'
import { Contract, BigNumber } from 'ethers'
import { useActiveWeb3React } from 'hooks'
import { useMasterFarmContract } from 'hooks/useContract'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import { useState, useCallback, useEffect } from 'react'
import { getProviderOrSigner } from 'utils'
import { MulticallCall, multicall } from 'utils/multicall'
import { abi as IUniswapV2PairABI } from '../constants/abis/contracts/IUniswapV2Pair.json'
import { Token } from '@/lib/sdk/index'

/**
 * Utility function to fetch token details from ERC-20 contracts using multicall results.
 * @param multicallAddress string
 * @param tokenAddresses Array of ERC-20 token addresses.
 * @param library Ethers provider.
 * @param chainId Current blockchain network ID.
 * @returns Array of Token instances with symbol, decimals, and name.
 */
async function getTokensInfo(
  multicallAddress: string,
  tokenAddresses: string[],
  library: Web3Provider | undefined,
  chainId: number
): Promise<Token[]> {
  if (tokenAddresses.length === 0 && !library) return []

  const tokenInterface = new Contract('', ERC20_ABI, getProviderOrSigner(library, undefined)).interface

  // Prepare multicall data: for each token, fetch symbol, decimals, and name
  const calls: MulticallCall[] = tokenAddresses.flatMap(address => [
    { target: address, callData: tokenInterface.encodeFunctionData('symbol', []) },
    { target: address, callData: tokenInterface.encodeFunctionData('decimals', []) },
    { target: address, callData: tokenInterface.encodeFunctionData('name', []) }
  ])

  // Execute multicall
  const response = await multicall(multicallAddress, library, calls)
  if (!response) {
    throw new Error('Multicall failed when fetching token information')
  }

  // Format of response is data is [symbol1, decimals1, name1, symbol2, decimals2, name2, ...]
  const tokenDataArray = []
  for (let i = 0; i < tokenAddresses.length; i++) {
    tokenDataArray.push({
      symbolData: response.returnData[i * 3],
      decimalsData: response.returnData[i * 3 + 1],
      nameData: response.returnData[i * 3 + 2]
    })
  }

  // Decode results and create Token instances
  const tokens: Token[] = []
  for (let i = 0; i < tokenAddresses.length; i++) {
    try {
      const symbol: string = tokenInterface.decodeFunctionResult('symbol', tokenDataArray[i].symbolData)[0]
      const decimals: number = tokenInterface.decodeFunctionResult('decimals', tokenDataArray[i].decimalsData)[0]
      const name: string = tokenInterface.decodeFunctionResult('name', tokenDataArray[i].nameData)[0]

      tokens.push(new Token(chainId, tokenAddresses[i], decimals, symbol, name))
    } catch (error) {
      console.error(`Error decoding token data for address ${tokenAddresses[i]}:`, error)
      tokens.push(new Token(chainId, tokenAddresses[i], 0, 'UNKNOWN', 'Unknown Token'))
    }
  }

  return tokens
}

export interface StakingRewardsInfo {
  pid: number
  tokens: [Token, Token]
  active: boolean
}

/**
 * Custom hook to fetch staking rewards information using Multicall3 for optimized performance.
 * @param dappData The dApp data object containing configuration details.
 * @returns An object containing stakingRewardsInfo array, loading state, error, and fetchStakingRewards callback.
 */
export function useStakingRewardsInfo(dappData: TDappData) {
  const { settings } = useAppConstant()
  const { library } = useActiveWeb3React()
  const [stakingRewardsInfo, setStakingRewardsInfo] = useState<StakingRewardsInfo[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  const multiCallAddr = settings.multiCallAddr
  const masterFarmContract = useMasterFarmContract(dappData)

  const fetchStakingRewards = useCallback(async () => {
    if (!masterFarmContract) {
      setError(new Error('MasterFarmContract is not available'))
      setLoading(false)
      return
    }

    try {
      setLoading(true)
      setError(null)

      // ---------------------- Step 1: Fetch Pool Length ---------------------- //

      const poolLengthBN: BigNumber = await masterFarmContract.poolLength()
      const length = poolLengthBN.toNumber()

      if (length <= 0) {
        throw new Error('No pools available in the MasterFarmContract')
      }

      // ---------------------- Step 2: Fetch All PoolInfos Using Multicall ---------------------- //

      const poolInfoInterface = masterFarmContract.interface
      const poolInfoCalls: MulticallCall[] = Array.from({ length }, (_, pid) => ({
        target: masterFarmContract.address,
        callData: poolInfoInterface.encodeFunctionData('poolInfo', [pid])
      }))

      const poolInfoResponse = await multicall(multiCallAddr, library, poolInfoCalls)
      if (!poolInfoResponse) {
        throw new Error('Multicall failed when fetching pool information')
      }

      const poolInfos = poolInfoResponse.returnData.map(data =>
        poolInfoInterface.decodeFunctionResult('poolInfo', data)
      )

      // ---------------------- Step 3: Extract LP Token Addresses ---------------------- //

      const lpTokenAddresses: string[] = poolInfos.map(poolInfo => poolInfo.lpToken)

      // ---------------------- Step 4: Fetch token0 and token1 Addresses Using Multicall ---------------------- //

      const pairInterface = new Contract('', IUniswapV2PairABI, getProviderOrSigner(library, undefined)).interface

      const tokenCalls: MulticallCall[] = lpTokenAddresses.flatMap(address => [
        { target: address, callData: pairInterface.encodeFunctionData('token0', []) },
        { target: address, callData: pairInterface.encodeFunctionData('token1', []) }
      ])

      const tokenResponse = await multicall(multiCallAddr, library, tokenCalls)
      if (!tokenResponse) {
        throw new Error('Multicall failed when fetching token0 and token1 information')
      }

      const tokenAddressesFetched: string[] = []

      for (let i = 0; i < tokenResponse.returnData.length; i += 2) {
        const token0 = pairInterface.decodeFunctionResult('token0', tokenResponse.returnData[i])[0]
        const token1 = pairInterface.decodeFunctionResult('token1', tokenResponse.returnData[i + 1])[0]
        tokenAddressesFetched.push(token0, token1)
      }

      // ---------------------- Step 5: Fetch ERC20 Token Infos Using Multicall ---------------------- //

      const tokens = await getTokensInfo(
        multiCallAddr,
        tokenAddressesFetched,
        library,
        settings.blockchainSettings.chainId
      )

      // ---------------------- Step 6: Map Tokens to Pools and Determine Active Status ---------------------- //

      const rewardsInfo: StakingRewardsInfo[] = poolInfos.map((poolInfo, pid) => {
        // tokens format is [PairAToken0, PairAToken1, PairBToken0, PairBToken1, ...]
        const token0 = tokens[pid * 2]
        const token1 = tokens[pid * 2 + 1]
        const allocPoint: BigNumber = poolInfo.allocPoint
        const isActive = !allocPoint.eq(0)
        return {
          pid,
          tokens: [token0, token1],
          active: isActive
        }
      })

      setStakingRewardsInfo(rewardsInfo)
      setLoading(false)
    } catch (err) {
      console.error('Error fetching staking rewards info:', err)
      setError(err as Error)
      setLoading(false)
    }
  }, [masterFarmContract, library, multiCallAddr, settings.blockchainSettings.chainId])

  useEffect(() => {
    fetchStakingRewards()
  }, [fetchStakingRewards])

  return {
    stakingRewardsInfo,
    loading,
    error,
    fetchStakingRewards
  }
}
