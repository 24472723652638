import { TSettings, EBackgroundTheme } from 'constants/settings/types/TSettings'
import { Graphics, Container, Application } from 'pixi.js'

function drawStars(starContainer: Container, numStars: number, maxWidth: number, maxHeight: number) {
  const starGraphics = new Graphics()

  // Set the fill color to white for the stars
  starGraphics.beginFill(0xffffff)

  // Draw random stars
  for (let i = 0; i < numStars; i++) {
    const x = Math.random() * maxWidth
    const y = Math.random() * maxHeight
    const size = Math.random() * 2 + 1 // Random size for variation

    // Draw a small circle (dot) at random position
    starGraphics.drawCircle(-x, y, size)
    starGraphics.drawCircle(x, -y, size)
    starGraphics.drawCircle(x, y, size)
  }

  // End the fill for the stars
  starGraphics.endFill()

  // Add the stars graphics to the star container
  starContainer.addChild(starGraphics)

  starContainer.scale.set(1, 1)
}

function drawBubblesInPixi(container: Container, numBubbles: number, maxWidth: number, maxHeight: number) {
  const bubbleGraphics = new Graphics()

  for (let i = 0; i < numBubbles; i++) {
    const x = Math.random() * maxWidth
    const y = Math.random() * maxHeight
    const size = Math.random() * 5 + 2

    bubbleGraphics.beginFill(0x88e1f2, 0.5) // Light blue with transparency
    bubbleGraphics.drawCircle(-x, y, size)
    bubbleGraphics.drawCircle(x, -y, size)
    bubbleGraphics.drawCircle(x, y, size)
    bubbleGraphics.endFill()
  }

  container.addChild(bubbleGraphics)
}

export function drawBasedOnSettings(app: Application, container: Container, settings: TSettings) {
  switch (settings.platformBackgroundTheme) {
    case EBackgroundTheme.BUBBLE:
      drawBubblesInPixi(container, 100, app.renderer.width, app.renderer.height)
      break
    case EBackgroundTheme.STAR:
      drawStars(container, 50, app.renderer.width, app.renderer.height)
      break
    default:
      console.log('Unknown drawObject type')
  }
}
