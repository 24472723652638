import { TDappData } from 'constants/settings/types/TNpcs'
import flatMap from 'lodash.flatmap'
import { useMemo } from 'react'
import { useUserSingleHopOnly } from 'state/user/hooks'
import { isTradeBetter } from 'utils/trades'
import { useActiveWeb3React } from './index'
import { useUnsupportedTokens } from './Tokens'
import { BETTER_TRADE_LESS_HOPS_THRESHOLD } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { useAppSettings } from '../providers/AppSettingsProvider/AppSettingsProvider'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { Currency, CurrencyAmount, Pair, Token, Trade } from '@/lib/sdk/index'

function useAllCommonPairs(dappData: TDappData, currencyA?: Currency, currencyB?: Currency): Pair[] {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const bases: Token[] = chainId ? settings.basesToCheckTradesAgainst : []

  const [tokenA, tokenB] = chainId
    ? [
        wrappedCurrency(currencyA, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency),
        wrappedCurrency(currencyB, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)
      ]
    : [undefined, undefined]

  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] => bases.map(otherBase => [base, otherBase])).filter(
        ([t0, t1]) => t0.address !== t1.address
      ),
    [bases]
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
        : // .filter(([tokenA, tokenB]) => {
          //   if (!chainId) return true
          //   const customBases = settings.customBases
          //   if (!customBases) return true

          //   const customBasesA: Token[] | undefined = customBases[tokenA.address]
          //   const customBasesB: Token[] | undefined = customBases[tokenB.address]

          //   if (!customBasesA && !customBasesB) return true

          //   if (customBasesA && !customBasesA.find(base => tokenB.equals(base))) return false
          //   if (customBasesB && !customBasesB.find(base => tokenA.equals(base))) return false

          //   return true
          // })
          [],
    [tokenA, tokenB, bases, basePairs, chainId]
  )

  const allPairs = usePairs(allPairCombinations, dappData)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {})
      ),
    [allPairs]
  )
}

const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
  nativeCurrency: Currency,
  dappData: TDappData,
  currencyAmountIn?: CurrencyAmount,
  currencyOut?: Currency
): Trade | null {
  const allowedPairs = useAllCommonPairs(dappData, currencyAmountIn?.currency, currencyOut)
  const { settings } = useAppSettings()
  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0 && dappData.factoryAddress && dappData.initHash) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactIn(
            allowedPairs,
            currencyAmountIn,
            currencyOut,
            {
              maxHops: 1,
              maxNumResults: 1
            },
            undefined,
            undefined,
            undefined,
            dappData.factoryAddress,
            dappData.initHash,
            nativeCurrency,
            settings.wrappedCurrency
          )[0] ?? null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade | null =
          Trade.bestTradeExactIn(
            allowedPairs,
            currencyAmountIn,
            currencyOut,
            {
              maxHops: 3,
              maxNumResults: 3
            },
            undefined,
            undefined,
            undefined,
            dappData.factoryAddress,
            dappData.initHash,
            nativeCurrency,
            settings.wrappedCurrency
          )[0] ?? null
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  }, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  nativeCurrency: Currency,
  dappData: TDappData,
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount
): Trade | null {
  const allowedPairs = useAllCommonPairs(dappData, currencyIn, currencyAmountOut?.currency)

  const [singleHopOnly] = useUserSingleHopOnly()
  const { settings } = useAppSettings()

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0 && dappData.factoryAddress && dappData.initHash) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactOut(
            allowedPairs,
            currencyIn,
            currencyAmountOut,
            {
              maxHops: 1,
              maxNumResults: 1
            },
            undefined,
            undefined,
            undefined,
            dappData.factoryAddress,
            dappData.initHash,
            nativeCurrency,
            settings.wrappedCurrency
          )[0] ?? null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(
            allowedPairs,
            currencyIn,
            currencyAmountOut,
            {
              maxHops: 3,
              maxNumResults: 3
            },
            undefined,
            undefined,
            undefined,
            dappData.factoryAddress,
            dappData.initHash,
            nativeCurrency,
            settings.wrappedCurrency
          )[0] ?? null
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }
    return null
  }, [currencyIn, currencyAmountOut, allowedPairs, singleHopOnly])
}

export function useIsTransactionUnsupported(currencyIn?: Currency, currencyOut?: Currency): boolean {
  const unsupportedToken: { [address: string]: Token } = useUnsupportedTokens()
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()

  const tokenIn = wrappedCurrency(currencyIn, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)
  const tokenOut = wrappedCurrency(currencyOut, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)

  // if unsupported list loaded & either token on list, mark as unsupported
  if (unsupportedToken) {
    if (tokenIn && Object.keys(unsupportedToken).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(unsupportedToken).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}
