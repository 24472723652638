import React, { FC } from 'react'
import styled from 'styled-components'

// Updated styles to prevent highlighting and fix alignment
const OverlayContainer = styled.div`
  position: fixed;
  bottom: 50px;
  left: 10px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  -webkit-user-select: none; /* Prevent text selection on iOS */
  -webkit-touch-callout: none; /* Disable callout on long tap on iOS */
  user-select: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`

const DPadContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 60px 60px;
  grid-template-rows: 60px 60px 60px;
  gap: 5px;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: none;
  flex-direction: row;
  position: fixed;
  bottom: 100px;
  right: 10px;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`

const Button = styled.button`
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.primary1};
  border-radius: 50%;
  border: 0.5px solid ${({ theme }) => theme.primary2};
  margin: 20px;
  font-size: 1.5rem;
  cursor: pointer;
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none; /* Remove default button styles on iOS */
  appearance: none;
  outline: none; /* Remove focus outline */
  -webkit-user-select: none; /* Prevent text selection on iOS */
  -webkit-touch-callout: none; /* Disable callout on long tap on iOS */
  user-select: none;

  &:active {
    background-color: ${({ theme }) => theme.text1};
  }
`

interface DPadProps {
  keys: { [key: string]: boolean } // Pass the key state
}

export const MobileControl: FC<DPadProps> = ({ keys }) => {
  // Helper function to handle touch start for specific keys
  const handleTouchStart = (keyCode: string) => {
    keys[keyCode] = true
  }

  // Helper function to handle touch end for specific keys
  const handleTouchEnd = (keyCode: string) => {
    keys[keyCode] = false
  }

  return (
    <>
      <OverlayContainer>
        <DPadContainer>
          <div></div>
          <Button
            data-value="KeyW"
            onTouchStart={() => handleTouchStart('KeyW')}
            onTouchEnd={() => handleTouchEnd('KeyW')}
          >
            ↑
          </Button>
          <div></div>
          <Button
            data-value="KeyA"
            onTouchStart={() => handleTouchStart('KeyA')}
            onTouchEnd={() => handleTouchEnd('KeyA')}
          >
            ←
          </Button>
          <Button
            data-value="KeyS"
            onTouchStart={() => handleTouchStart('KeyS')}
            onTouchEnd={() => handleTouchEnd('KeyS')}
          >
            ↓
          </Button>
          <Button
            data-value="KeyD"
            onTouchStart={() => handleTouchStart('KeyD')}
            onTouchEnd={() => handleTouchEnd('KeyD')}
          >
            →
          </Button>
        </DPadContainer>
      </OverlayContainer>

      <ButtonContainer>
        <Button
          data-value="KeyF"
          onTouchStart={() => handleTouchStart('KeyF')}
          onTouchEnd={() => handleTouchEnd('KeyF')}
        >
          A
        </Button>
      </ButtonContainer>
    </>
  )
}
