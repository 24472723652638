import { Web3Provider } from '@ethersproject/providers'
import { ERC20_ABI } from 'constants/abis/erc20'
import { Contract } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { getProviderOrSigner } from 'utils'
import { MulticallCall, multicall } from 'utils/multicall'
import { TokenAmount, Token, Currency, CurrencyAmount, JSBI } from '@/lib/sdk/index'

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const extractRevertReason = (error: any): string => {
  try {
    // Access the specific field where revert reason is present
    if (error?.error?.data?.message) {
      return error.error.data.message
    }
    if (error?.data?.message) {
      return error.data.message
    }
    // Check if revert reason is embedded in the message
    const message = error.message || ''
    const match = message.match(/execution reverted: (.*)/)
    return match ? match[1] : 'Unknown error occurred'
  } catch (err) {
    return 'Unknown error occurred'
  }
}

// Utility function to safely extract contract data
export const extractData = <T>(data: any, path?: string): T | null => {
  if (path) {
    return ((data?.result[path] as unknown) as T) ?? null
  }
  return ((data?.result as unknown) as T) ?? null
}

/**
 * Utility function to fetch token details from ERC-20 contracts using multicall results.
 * @param multicallAddress string
 * @param tokenAddresses Array of ERC-20 token addresses.
 * @param library Ethers provider.
 * @param chainId Current blockchain network ID.
 * @returns Array of Token instances with symbol, decimals, and name.
 */
export async function getTokensInfo(
  multicallAddress: string,
  tokenAddresses: (string | undefined)[],
  library: Web3Provider,
  chainId: number
): Promise<Token[]> {
  const formattedTokenAddresses = tokenAddresses.filter((i): i is string => i !== undefined)

  if (formattedTokenAddresses.length === 0) return []
  const tokenInterface = new Contract('', ERC20_ABI, getProviderOrSigner(library, undefined)).interface

  // Prepare multicall data: for each token, fetch symbol, decimals, and name
  const calls: MulticallCall[] = formattedTokenAddresses.flatMap(address => [
    { target: address, callData: tokenInterface.encodeFunctionData('symbol', []) },
    { target: address, callData: tokenInterface.encodeFunctionData('decimals', []) },
    { target: address, callData: tokenInterface.encodeFunctionData('name', []) }
  ])

  // Execute multicall
  const response = await multicall(multicallAddress, library, calls)
  if (!response) {
    throw new Error('Multicall failed when fetching token information')
  }

  // Format of response is data is [symbol1, decimals1, name1, symbol2, decimals2, name2, ...]
  const tokenDataArray = []
  for (let i = 0; i < formattedTokenAddresses.length; i++) {
    tokenDataArray.push({
      symbolData: response.returnData[i * 3],
      decimalsData: response.returnData[i * 3 + 1],
      nameData: response.returnData[i * 3 + 2]
    })
  }

  // Decode results and create Token instances
  const tokens: Token[] = []
  for (let i = 0; i < formattedTokenAddresses.length; i++) {
    try {
      const symbol: string = tokenInterface.decodeFunctionResult('symbol', tokenDataArray[i].symbolData)[0]
      const decimals: number = tokenInterface.decodeFunctionResult('decimals', tokenDataArray[i].decimalsData)[0]
      const name: string = tokenInterface.decodeFunctionResult('name', tokenDataArray[i].nameData)[0]

      tokens.push(new Token(chainId, formattedTokenAddresses[i], decimals, symbol, name))
    } catch (error) {
      console.error(`Error decoding token data for address ${formattedTokenAddresses[i]}:`, error)
      tokens.push(new Token(chainId, formattedTokenAddresses[i], 0, 'UNKNOWN', 'Unknown Token'))
    }
  }

  return tokens
}

/**
 * This helper function calculates the appropriate fee division based on the hardcoded stage fees in the smart contract.
 * The contract enforces a range of fees (from 0.01% to 25%), and this function determines how the collected fees are split.
 *
 * @param devFeeStage - A JSBI instance representing the percentage of fees that will be sent to the treasury wallet from the user's amount.
 * @param userFeeStage - A JSBI instance representing the percentage of the remaining amount that will be returned to the user after fees are deducted.
 *
 * @returns:JSBI -  the appropriate fee division
 */
export function stages(devFeeStage: JSBI, userFeeStage: JSBI): JSBI {
  let divisionFee = JSBI.BigInt(100)
  if (!devFeeStage || !userFeeStage) {
    return divisionFee
  }
  if (devFeeStage.toString() === '1' && userFeeStage.toString() === '9999') {
    divisionFee = JSBI.BigInt(10000)
  }
  if (devFeeStage.toString() === '25' && userFeeStage.toString() === '9975') {
    divisionFee = JSBI.BigInt(10000)
  }
  if (devFeeStage.toString() === '5' && userFeeStage.toString() === '995') {
    divisionFee = JSBI.BigInt(1000)
  }

  return divisionFee
}
