import { DEFAULT_CURRENCY } from 'constants/settings'
import isEqual from 'lodash.isequal'
import { unwrappedToken } from './wrappedCurrency'
import { Token, Currency } from '@/lib/sdk/index'

export default function determineBaseToken(
  tokenData: Record<string, any>,
  tokens: [Token, Token],
  nativeCurrency: Currency,
  wrappedCurrency: Token
): Token | undefined {
  const currency0 = unwrappedToken(tokens[0], nativeCurrency, wrappedCurrency)
  const currency1 = unwrappedToken(tokens[1], nativeCurrency, wrappedCurrency)

  //const baseToken = currency0 && DEFAULT_CURRENCIES.includes(currency0) ? token0 : token1

  let baseToken: Token | undefined = tokenData?.WETH?.token

  if (
    [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, currency0)) ||
    [nativeCurrency, DEFAULT_CURRENCY].some(item => isEqual(item, currency1))
  ) {
    baseToken = tokenData?.WETH?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.govToken?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.govToken?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.govToken?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.STABLE?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.STABLE?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.STABLE?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.BUSD?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.BUSD?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.BUSD?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.USDC?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.USDC?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.USDC?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.bscBUSD?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.bscBUSD?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.bscBUSD?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.bridgedETH?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.bridgedETH?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.bridgedETH?.token
  } else if (
    tokens[0]?.symbol?.toUpperCase() === tokenData?.bscBNB?.token?.symbol?.toUpperCase() ||
    tokens[1]?.symbol?.toUpperCase() === tokenData?.bscBNB?.token?.symbol?.toUpperCase()
  ) {
    baseToken = tokenData?.bscBNB?.token
  }

  return baseToken
}
