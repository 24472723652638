import { TransactionResponse } from '@ethersproject/providers'
import TransactionErrorModal from 'components/TransactionErrorModal/TransactionErrorModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useMasterFarmContract } from '../../hooks/useContract'
import { StakingInfo } from '../../state/stake/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TYPE, CloseIcon } from '../../theme'
import { calculateGasMargin } from '../../utils'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import { SubmittedView, LoadingView } from '../ModalViews'
import { RowBetween } from '../Row'
import Breakdown from './BreakdownClaimsAllRewards'
import { TokenAmount } from '@/lib/sdk/index'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfos: StakingInfo[]
  dappData: TDappData
}

export default function ClaimAllRewardsModal({ isOpen, onDismiss, stakingInfos, dappData }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  const [failed, setFailed] = useState<boolean>(false)

  const rewards = stakingInfos.map(({ earnedAmount }) => earnedAmount)
  const sumRewards =
    dappData.governanceToken && rewards
      ? rewards.reduce<TokenAmount>((a, b) => a.add(b), new TokenAmount(dappData.governanceToken, '0'))
      : undefined

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    setFailed(false)
    onDismiss()
  }

  const masterFarm = useMasterFarmContract(dappData)

  async function onClaimReward() {
    if (masterFarm) {
      setAttempting(true)

      const pids = stakingInfos.map(({ pid }) => pid)

      const estimatedGas = await masterFarm.estimateGas.claimRewards(pids)

      await masterFarm
        .claimRewards(pids, {
          gasLimit: calculateGasMargin(estimatedGas)
        })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claim accumulated ${dappData.governanceToken?.symbol} rewards`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          if (error?.code === -32603) {
            setFailed(true)
          }
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && !failed && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Claim</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {sumRewards?.greaterThan('0') && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {sumRewards?.toSignificant(6)}
              </TYPE.body>
              <TYPE.body>Unclaimed {dappData.governanceToken?.symbol}</TYPE.body>
              <Breakdown farmContract={masterFarm} sumRewards={sumRewards} />
            </AutoColumn>
          )}
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            The sum above includes both locked and unlocked {dappData.governanceToken?.symbol} rewards across all the
            pools you are staking in.
          </TYPE.subHeader>
          <ButtonError disabled={!!error} error={!!error} onClick={onClaimReward}>
            {error ?? 'Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && !failed && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Claiming {dappData.governanceToken?.symbol} rewards</TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && !failed && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Claimed {dappData.governanceToken?.symbol}!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
      {!attempting && !hash && failed && (
        <TransactionErrorModal errorToDisplay={''} wrappedOnDismiss={wrappedOnDismiss} />
      )}
    </Modal>
  )
}
