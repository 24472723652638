import React from 'react'
import gearPng from '../../../assets/images/menu/gear2.png'
import { IconWithModal } from '../IconWithModal/IconWithModal'
import { ControlsInstructions } from '../MovementInstructions/MovementInstructions'

const GearIcon: React.FC = () => <img src={gearPng} alt="Gear Icon" width="48" height="48" />

export const SettingIcon: React.FC = () => (
  <IconWithModal
    IconComponent={GearIcon}
    triggerKey="g"
    overlayLetter="G"
    modalTitle=""
    modalContent={<ControlsInstructions />}
  />
)
