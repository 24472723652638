import { moveWithCollision } from 'game/npcCharacters/utils/moveWithCollision'
import { Application, Container, extras } from 'pixi.js'

// Function to handle player movement on each tick
export function handlePlayerMovement(
  app: Application,
  player: extras.AnimatedSprite,
  keys: { [key: string]: boolean },
  speed: number
) {
  const isMovementRight = keys['KeyD'] || keys['ArrowRight']
  const isMovementLeft = keys['KeyA'] || keys['ArrowLeft']
  const isMovementUp = keys['KeyW'] || keys['ArrowUp']
  const isMovementDown = keys['KeyS'] || keys['ArrowDown']

  const otherContainers = app.stage.children.filter(child => child.name !== 'background') as Container[]

  const otherSprites = otherContainers.reduce((acc: extras.AnimatedSprite[], container) => {
    const animatedSprites = container.children.filter(
      (child): child is extras.AnimatedSprite => child instanceof extras.AnimatedSprite && child !== player
    )
    return acc.concat(animatedSprites)
  }, [] as extras.AnimatedSprite[])

  let xVal = player.x
  let yVal = player.y

  if (isMovementRight) {
    xVal += speed
  }

  if (isMovementLeft) {
    xVal -= speed
  }

  if (isMovementUp) {
    yVal -= speed
  }

  if (isMovementDown) {
    yVal += speed
  }

  const moveToLocation: [number, number] = [xVal, yVal]

  moveWithCollision(
    player,
    moveToLocation,
    speed,
    otherSprites.map(sprite => ({ x: sprite.x, y: sprite.y })),
    app
  )
}
