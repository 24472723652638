import { NPC } from 'constants/settings/types/TNpcs'
import { NPCAnimatedSprite } from 'game/types/NPCAnimatedSprite'
import { Application, Texture, Container, Text, Graphics } from 'pixi.js'
import { enableNPCMovement } from './utils/npcMovement'

// Function to add NPC to the game using preloaded resources.
// Returns a cleanup function to remove the NPC
export function addNPC(app: Application, npcData: NPC, container: Container, textures: Texture[][]): () => void {
  if (!textures) {
    console.error(`Error: No textures provided for NPC ${npcData.id}`)
    return () => ({})
  }

  // Map NPC directions to corresponding rows in the sprite sheet
  const directionMapping: { [key: string]: number } = {
    down: npcData.spriteRows?.down ?? 0,
    left: npcData.spriteRows?.left ?? 1,
    up: npcData.spriteRows?.up ?? 2,
    moveDown: npcData.spriteRows?.moveDown ?? 3,
    moveLeft: npcData.spriteRows?.moveLeft ?? 4,
    moveUp: npcData.spriteRows?.moveUp ?? 5
  }

  // Default to 'down' idle if no matching direction is found
  const initialRow = directionMapping[npcData.spriteRows?.default ?? 0] || 0
  const npcSprite = new NPCAnimatedSprite(textures[initialRow])

  npcSprite.anchor.set(0.5, 0.5)
  npcSprite.width = 96
  npcSprite.height = 96

  npcSprite.animationSpeed = 0.1

  npcSprite.x = npcData.startingLocation[0] || app.renderer.width / 2
  npcSprite.y = npcData.startingLocation[1] || app.renderer.height / 2

  npcSprite.play()

  npcSprite.NPCData = npcData
  container.addChild(npcSprite)

  // Handle NPC movement
  if (npcData.npcBehavior.continuousMovement) {
    enableNPCMovement(npcSprite, npcData.moveToLocation, npcData, textures, app, directionMapping)
  }

  // Handle NPC idle animation
  let idleInterval: NodeJS.Timeout | null = null

  if (npcData.idleAnimation.enabled) {
    idleInterval = setInterval(
      () => {
        if (!npcData.npcBehavior.continuousMovement) {
          npcSprite.textures = textures[initialRow]
          npcSprite.play()
        }
      },
      npcData.idleAnimation.intervalOrRandom === 'interval' ? 2000 : Math.random() * 5000
    )
  }

  // Handle NPC idle dialogues with RPG dialogue popup bubble
  let dialogueTimeout: NodeJS.Timeout | null = null
  if (npcData.idleDialogues && npcData.idleDialogues.length > 0) {
    let dialogueIndex = 0
    const dialogueContainer = new Container()
    const dialogueBackground = new Graphics()
    dialogueBackground.beginFill(0x000000, 0.7)
    dialogueBackground.drawRoundedRect(0, 0, 150, 50, 10)
    dialogueBackground.endFill()
    dialogueContainer.addChild(dialogueBackground)

    const dialogueText = new Text('', {
      fontFamily: 'Arial',
      fontSize: 16, // Reduced font size
      fill: 0xffffff,
      align: 'center',
      wordWrap: true,
      wordWrapWidth: 260 // Reduced word wrap width
    })
    dialogueText.anchor.set(0.5, 0.5)
    dialogueText.x = dialogueBackground.width / 2
    dialogueText.y = dialogueBackground.height / 2
    dialogueContainer.addChild(dialogueText)
    dialogueContainer.visible = false

    const showDialogue = () => {
      dialogueText.style.wordWrap = dialogueText.width > 300
      dialogueText.style.wordWrapWidth = dialogueText.width > 300 ? 300 : dialogueText.width
      dialogueBackground.width = dialogueText.width + 20
      app.ticker.add(() => {
        dialogueContainer.x = npcSprite.x - dialogueBackground.width / 2
        dialogueContainer.y = npcSprite.y - npcSprite.height / 2 - dialogueBackground.height - 10
      })
      dialogueBackground.height = dialogueText.height + 20
      dialogueText.x = dialogueBackground.width / 2
      dialogueText.y = dialogueBackground.height / 2
      dialogueContainer.visible = true
      dialogueContainer.x = npcSprite.x - dialogueBackground.width / 2
      dialogueContainer.y = npcSprite.y - npcSprite.height / 2 - dialogueBackground.height - 10
      setTimeout(() => {
        dialogueContainer.visible = false
      }, 3000)
    }

    const startDialogueCycle = () => {
      if (npcData.idleDialogues && npcData.idleDialogues.length > 0) {
        dialogueIndex = Math.floor(Math.random() * npcData.idleDialogues.length)
        dialogueText.text = npcData.idleDialogues[dialogueIndex]
        showDialogue()

        // Set a random delay (between 4 and 7 seconds) for the next dialogue
        dialogueTimeout = setTimeout(startDialogueCycle, 4000 + Math.random() * 3000)
      }
    }

    startDialogueCycle()

    dialogueContainer.x = npcSprite.x - dialogueBackground.width / 2
    dialogueContainer.y = npcSprite.y - npcSprite.height / 2 - dialogueBackground.height - 10
    container.addChild(dialogueContainer)
  }

  // Cleanup function to remove event listeners, ticker callback, intervals, and destroy the sprite
  return () => {
    if (idleInterval) clearInterval(idleInterval)
    if (dialogueTimeout) clearTimeout(dialogueTimeout)
    container.removeChild(npcSprite)
    npcSprite.destroy()
  }
}
