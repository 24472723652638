import { TDappData } from 'constants/settings/types/TNpcs'
import { useMasterFarmContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { JSBI, TokenAmount } from '@/lib/sdk/index'

export default function useBaseStakingRewardsEmission(dappData: TDappData): TokenAmount | undefined {
  const masterFarmContract = useMasterFarmContract(dappData)

  const result = useSingleCallResult(masterFarmContract, 'getNewRewardPerBlock', [0])
  const baseRewardsPerBlock =
    dappData.governanceToken && result && !result.loading && result.result
      ? new TokenAmount(dappData.governanceToken, JSBI.BigInt(result.result))
      : undefined

  return baseRewardsPerBlock
}
