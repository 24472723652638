import { TZones } from '../types/TZones'

export const zones: TZones = {
  tileLayout: [
    [15, 5, 5, 5, 5, 5, 5, 5, 15, 5, 8, 12, 12, 12, 12, 12, 2, 15, 5, 5, 5, 5, 5, 5, 5, 5, 15, 5],
    [5, 5, 5, 5, 5, 15, 5, 5, 5, 5, 8, 12, 12, 12, 12, 12, 2, 5, 5, 5, 15, 5, 5, 5, 5, 5, 5, 15],
    [5, 5, 5, 15, 5, 5, 5, 5, 5, 15, 8, 12, 12, 12, 17, 12, 2, 5, 5, 5, 5, 5, 15, 5, 5, 5, 5, 5],
    [5, 5, 5, 5, 5, 10, 6, 6, 6, 6, 9, 12, 12, 12, 12, 12, 3, 6, 6, 6, 6, 6, 13, 5, 15, 5, 5, 5],
    [5, 15, 5, 5, 5, 8, 12, 12, 12, 12, 12, 12, 12, 12, 17, 12, 12, 12, 12, 12, 12, 12, 2, 5, 5, 5, 15, 5],
    [5, 5, 5, 5, 5, 8, 16, 12, 17, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 17, 12, 12, 2, 5, 5, 5, 5, 5],
    [5, 5, 5, 15, 5, 8, 12, 12, 17, 12, 12, 16, 12, 12, 16, 12, 16, 12, 12, 12, 12, 12, 2, 5, 5, 5, 5, 5],
    [5, 5, 5, 5, 5, 8, 17, 17, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 3, 6, 6, 6, 6, 6],
    [6, 6, 6, 6, 6, 9, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 16, 12, 12, 12],
    [0, 12, 12, 12, 12, 12, 12, 12, 16, 12, 12, 12, 12, 12, 16, 12, 12, 17, 12, 12, 12, 12, 12, 12, 12, 12, 12, 0],
    [0, 12, 12, 18, 19, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 17, 12, 12, 12, 12, 12, 12, 0],
    [0, 12, 12, 20, 21, 12, 17, 12, 12, 12, 12, 12, 12, 12, 12, 12, 17, 12, 16, 12, 12, 12, 12, 17, 12, 12, 12, 0],
    [4, 4, 4, 4, 4, 7, 12, 12, 12, 16, 12, 12, 16, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12],
    [15, 5, 5, 5, 15, 8, 12, 12, 12, 17, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 1, 4, 4, 4, 4, 4],
    [5, 5, 5, 15, 5, 8, 16, 12, 12, 12, 16, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 2, 5, 5, 5, 5, 5],
    [5, 5, 5, 5, 5, 8, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 2, 5, 5, 5, 15, 5],
    [5, 5, 5, 5, 5, 11, 4, 4, 4, 4, 7, 12, 12, 12, 12, 12, 1, 4, 4, 4, 4, 4, 14, 5, 5, 5, 5, 15],
    [5, 5, 5, 5, 15, 5, 5, 5, 5, 5, 8, 12, 12, 16, 12, 12, 2, 5, 5, 5, 5, 15, 5, 5, 5, 5, 5, 5],
    [5, 5, 5, 15, 5, 5, 5, 5, 15, 5, 8, 12, 12, 12, 12, 12, 2, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 15],
    [5, 15, 5, 5, 5, 5, 5, 5, 5, 5, 8, 12, 12, 12, 12, 12, 2, 5, 5, 5, 15, 5, 5, 5, 5, 5, 5, 5],
    [5, 5, 5, 5, 5, 5, 5, 15, 5, 5, 8, 17, 12, 12, 12, 16, 2, 5, 5, 5, 5, 5, 5, 15, 5, 5, 5, 15],
    [5, 5, 5, 5, 5, 15, 5, 5, 5, 5, 8, 12, 12, 12, 12, 12, 2, 5, 15, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    [15, 5, 5, 5, 5, 5, 5, 5, 5, 5, 8, 12, 17, 12, 12, 12, 2, 5, 5, 5, 5, 5, 5, 5, 5, 5, 15, 5],
    [5, 5, 5, 15, 5, 5, 5, 5, 5, 15, 8, 1, 4, 4, 4, 7, 2, 15, 5, 5, 5, 5, 5, 5, 15, 5, 5, 15]
  ],
  objects: [
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 8 },
      transitional: false
    },
    {
      objRef: '2',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 8 },
      transitional: false
    },
    {
      objRef: '2',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 8 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 8 },
      transitional: false
    },
    {
      objRef: '4',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 9 },
      transitional: false
    },
    {
      objRef: '5',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 9 },
      transitional: false
    },
    {
      objRef: '5',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 9 },
      transitional: false
    },
    {
      objRef: '6',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 9 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 10 },
      transitional: false
    },
    {
      objRef: '8',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 10 },
      transitional: false
    },
    {
      objRef: '8',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 10 },
      transitional: false
    },
    {
      objRef: '9',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 10 },
      transitional: false
    },
    {
      objRef: '12',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 8 },
      transitional: false
    },
    {
      objRef: '13',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 8 },
      transitional: false
    },
    {
      objRef: '14',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 9 },
      transitional: false
    },
    {
      objRef: '15',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 9 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 12 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 12 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 13 },
      transitional: false
    },
    {
      objRef: '9',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 13 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 14 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 21, y: 14 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 15 },
      transitional: false
    },
    {
      objRef: '9',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 21, y: 15 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 7 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 16, y: 7 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 8 },
      transitional: false
    },
    {
      objRef: '9',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 16, y: 8 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 11, y: 0 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 0 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 0 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 0 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 0 },
      transitional: false
    },
    {
      objRef: '12',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 6, y: 5 },
      transitional: false
    },
    {
      objRef: '13',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 5 },
      transitional: false
    },
    {
      objRef: '14',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 6, y: 6 },
      transitional: false
    },
    {
      objRef: '15',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 6 },
      transitional: false
    }
    // {
    //   objRef: '100',
    //   objWidth: 64,
    //   objHeight: 64,
    //   startPosition: { x: 15, y: 13 },
    //   transitional: false
    // },
    // {
    //   objRef: '100',
    //   objWidth: 96,
    //   objHeight: 96,
    //   startPosition: { x: 18, y: 13 },
    //   transitional: false
    // },
    // {
    //   objRef: '100',
    //   objWidth: 128,
    //   objHeight: 128,
    //   startPosition: { x: 22, y: 13 },
    //   transitional: false
    // }
  ]
}
