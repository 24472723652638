import { AvatarImage } from 'components/AvatarImage/AvatarImage'
import { TDialogue } from 'constants/settings/types/TNpcs'
import React, { useEffect } from 'react'
import styled from 'styled-components'

// Close Icon styles (top-right of the modal)
export const CloseIcon = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primary2};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary2};
  }

  &:focus {
    color: ${({ theme }) => theme.primary2};
  }
`

const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
`

const ModalContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  width: 460px;
  max-width: 90%;
  max-height: 80%;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid ${({ theme }) => theme.primary2};
  position: relative;

  /* Hide scrollbar for Webkit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
`

const ModalWrapper = styled.div<{ hasAvatar: boolean }>`
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 8px;
  width: 460px;
  max-width: 90%;
  height: 100%;
  max-height: 90%;
  padding: 20px;
  position: relative;
  align-content: ${({ hasAvatar }) => (hasAvatar ? 'start' : 'center')};

  /* Hide scrollbar for Webkit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
`

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5em;
  color: ${({ theme }) => theme.primary2};
  text-align: center;
  width: 100%;
`

const ModalBody = styled.div`
  color: ${({ theme }) => theme.text2};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - 250px);

  /* Optional: Hide scrollbar for Webkit-based browsers */
  &::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width if needed */
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.primary2}; /* Style scrollbar thumb */
    border-radius: 4px;
  }
`

const AvatarContainer = styled.div`
  position: relative;
  z-index: 10;
  top: 60px;
  left: -20px;
  max-width: 120px;
`

export default function DialogueModal({
  isOpen,
  onClose,
  children,
  dialogue
}: {
  isOpen: boolean
  onClose: () => void
  title?: string
  children: React.ReactNode
  dialogue?: TDialogue
}) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <ModalOverlay>
      <ModalWrapper hasAvatar={!!dialogue?.avatarImageUrl}>
        {dialogue?.avatarImageUrl && (
          <AvatarContainer>
            <AvatarImage imageUrl={dialogue.avatarImageUrl} />
          </AvatarContainer>
        )}

        <ModalContainer>
          <ModalHeader>
            <ModalTitle>{dialogue?.npcName ?? ''}</ModalTitle>
            <div style={{ position: 'absolute', right: 0 }}>
              <CloseIcon onClick={onClose}>{`[Esc]/X`}</CloseIcon>
            </div>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
        </ModalContainer>
      </ModalWrapper>
    </ModalOverlay>
  )
}
