import { ButtonConfirmed, ButtonError } from 'components/Button'
import { RowBetween } from 'components/Row'
import { ApprovalState } from 'hooks/useApproveCallback'
import React from 'react'
import { JSBI, CurrencyAmount } from '@/lib/sdk/index'

export function TransactionButtons({
  onApprove,
  onStake,
  onWithdraw,
  approval,
  error,
  userDepositedTokenBalance,
  hasPoolEnded
}: {
  onApprove: () => void
  onStake: () => void
  onWithdraw: (harvest?: boolean) => void
  approval: ApprovalState
  error: string | null
  userDepositedTokenBalance: CurrencyAmount | null
  hasPoolEnded: boolean
}) {
  return (
    <>
      {!hasPoolEnded ? (
        <RowBetween>
          <ButtonConfirmed
            onClick={onApprove}
            confirmed={approval === ApprovalState.APPROVED}
            disabled={approval !== ApprovalState.NOT_APPROVED}
          >
            Approve
          </ButtonConfirmed>
          <ButtonError onClick={onStake} disabled={approval !== ApprovalState.APPROVED} error={!!error}>
            {error ?? 'Stake'}
          </ButtonError>
        </RowBetween>
      ) : (
        <RowBetween>
          <ButtonError disabled={true} error={true}>
            Vault has closed
          </ButtonError>
        </RowBetween>
      )}
      {/* Withdraw Button */}
      {userDepositedTokenBalance?.greaterThan(JSBI.BigInt(0)) && (
        <RowBetween>
          <ButtonConfirmed onClick={() => onWithdraw()}>Withdraw</ButtonConfirmed>
        </RowBetween>
      )}
    </>
  )
}
