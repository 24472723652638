import { Token, TokenAmount, Pair, JSBI } from '@/lib/sdk/index'

/**
 * Calculates the total staked amount of a base token in a staking token pair.
 *
 * @param baseToken - The base token for which the total staked amount is calculated.
 * @param stakingTokenPair - The pair of tokens involved in staking.
 * @param totalStakedAmount - The total amount of tokens staked.
 * @param totalLpTokenSupply - The total supply of LP tokens.
 * @returns The total staked amount of the base token.
 *
 * @remarks
 * If the base token does not have 18 decimals, the function returns the total amount of the other token staked.
 * Otherwise, it calculates the total amount of LP tokens staked, multiplies by the ETH value of all LP tokens,
 * and divides by the total supply of LP tokens. The value of LP shares is approximately double the value of the WETH
 * they entitle the owner to.
 */
export default function calculateTotalStakedAmount(
  baseToken: Token,
  stakingTokenPair: Pair,
  totalStakedAmount: TokenAmount,
  totalLpTokenSupply: TokenAmount
): TokenAmount {
  if (baseToken.decimals !== 18) {
    const otherToken = stakingTokenPair.token0.equals(baseToken) ? stakingTokenPair.token1 : stakingTokenPair.token0
    // note: we don't double in this case because we will double later in calculateWethAdjustedTotalStakedAmount using the scalar logic
    return new TokenAmount(otherToken, stakingTokenPair.reserveOf(otherToken).raw)
  } else {
    return new TokenAmount(
      baseToken,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(totalStakedAmount.raw, stakingTokenPair.reserveOf(baseToken).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalLpTokenSupply.raw
      )
    )
  }
}
