import DialogueModal from 'components/Dialogue/DialogueModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import React, { useState } from 'react'
import CrowdSale from './CrowdSale'

interface CrowdSaleModalProps {
  dappData: TDappData
}

export default function CrowdSaleModal({ dappData }: CrowdSaleModalProps) {
  // State to manage which modal to show
  const [showModal, setShowModal] = useState(true)

  // Close modal functions
  const closeModal = () => setShowModal(false)

  const modalShowEl = showModal ? (
    <DialogueModal isOpen={showModal} onClose={closeModal} title="Alchemist pool">
      <CrowdSale dappData={dappData} isOpen={showModal} onDismiss={closeModal} />
    </DialogueModal>
  ) : (
    <></>
  )

  return modalShowEl
}
