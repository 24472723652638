import { TSettings, EBackgroundTheme } from 'constants/settings/types/TSettings'
import getTokenWithDefault from 'utils/getTokenWithDefault'
import cardBGImageSmaller from '../../../assets/images/application/xl_uni.png'
import backgroundImg2 from '../base/assets/background.png'
import logoDark from '../base/assets/space.png'
import { ChainId, Blockchain } from '../index'
import avatarBackgroundImage from './assets/npc/avatarBackground.png'
import { Token, Currency } from '@/lib/sdk/index'
import { ChainTokenList } from '@/lib/sdk-extra/chainTokenList'

export const chainId = ChainId.BASE

const wrappedCurrency = new Token(chainId, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether')
export const spaceToken = new Token(chainId, '0x8dD806Cd8e5349A96103dc1525bb07aa0bf13dae', 18, 'SPACE', 'Space Token')
export const chainTokenList = new ChainTokenList(chainId)
export const farmAddress = '0x3713E9D0D4b6CDaA5F5AabD5c98a29877323581b'
export const factoryAddress = '0xB8364EFF247a469fe7b34956B1e5c0A9Bda6F630'
export const initHash = '0x57c454d7cd80b7071ad4cf9f163c6e63b2e29ed0f61e3c967e0e528664728ae5'
export const routerAddress = '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e'
export const gameToken = new Token(chainId, '0xFB6717742009E2cFD5a66186c939AC892c2A8654', 18, 'GAME', 'Game Token')
export const govTokenName = spaceToken.symbol

// BASE chain settings
export const BASE: TSettings = {
  platformBackgroundTheme: EBackgroundTheme.STAR,
  domain: 'space',
  platformName: 'RPG Space',
  platformLinks: [
    { label: 'Visit the Discord', url: 'https://discord.gg/nm2ayGSrZV' },
    { label: 'Check us out on X', url: 'https://x.com/defi_rpg' },
    { label: 'Telegram Group', url: 'https://t.me/DeFi_RPG' },
    { label: 'Reddit', url: 'https://www.reddit.com/r/DeFiRPG/s/H8VFbMtAcF' }
  ],
  platformImg: {
    appBgImg: backgroundImg2,
    appLogoWhite: logoDark,
    appLogoDark: logoDark,
    appBgCard: cardBGImageSmaller,
    avatarBackgroundImg: avatarBackgroundImage
  },
  webInterfaces: ['defiapps.io'],
  blockchainSettings: {
    chainId: chainId,
    blockchain: Blockchain.BASE,
    name: 'Base',
    rpcURLs: ['https://mainnet.base.org'],
    explorerURL: 'https://base.blockscout.com/',
    blockTime: 2,
    hexChainId: `0x${chainId?.toString(16)}`,
    currency: new Currency(18, 'ETH', 'Ether'),
    currencyImg: 'https://basescan.org/token/images/weth_28.png'
  },
  etherscanPrefix: 'https://base.blockscout.com',
  liquidityPair: {
    symbol: 'RPG-LP',
    name: 'RPG-LP Token'
  },
  multiCallAddr: '0xcA11bde05977b3631167028862bE2a173976CA11',
  wrappedCurrency: wrappedCurrency,
  suggestedBases: [],
  pinnedPairs: [],
  merkleDistributorAddr: undefined,
  chainTokenList: chainTokenList,
  basesToTrackLiquidityFor: [],
  basesToCheckTradesAgainst: [
    ...[wrappedCurrency],
    getTokenWithDefault(chainId, 'USDC', wrappedCurrency, spaceToken, chainTokenList),
    getTokenWithDefault(chainId, 'ETH', wrappedCurrency, spaceToken, chainTokenList),
    getTokenWithDefault(chainId, 'WETH', wrappedCurrency, spaceToken, chainTokenList),
    getTokenWithDefault(chainId, 'SPACE', wrappedCurrency, spaceToken, chainTokenList)
  ]
}
