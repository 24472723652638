import { TObjects } from 'constants/settings/types/TObjects'
import { TZones } from 'constants/settings/types/TZones'
import { Application, Container, Texture } from 'pixi.js'
import { ZObjectAnimatedSprite } from '../types/ZObjectAnimatedSprite'

type ObjectResources = {
  [objectId: string]: {
    texture: Texture
    blockable: boolean
  }
}

export const preloadObjectTextures = (objects: TObjects): ObjectResources => {
  const objectTextures: ObjectResources = {}

  Object.keys(objects.objectMapping).forEach(key => {
    const objectData = objects.objectMapping[key]
    const texture = Texture.from(objectData.imageUrl)

    objectTextures[key] = {
      texture,
      blockable: objectData.blockable ?? false
    }
  })

  return objectTextures
}

export const loadObjectsToGame = (
  _app: Application,
  zObjectContainer: Container,
  objectTextures: ObjectResources,
  zone: TZones,
  zoneObjects: TObjects
) => {
  zone.objects.forEach(obj => {
    const matchedObject = zoneObjects.objectMapping[obj.objRef] // Find the corresponding object from objectMapping

    if (matchedObject) {
      const objectData = objectTextures[obj.objRef]
      const objectSprite = new ZObjectAnimatedSprite(objectData.texture)

      objectSprite.width = obj.objWidth
      objectSprite.height = obj.objHeight

      objectSprite.anchor.set(0.5, 0.5)

      objectSprite.x = obj.startPosition.x * 32
      objectSprite.y = obj.startPosition.y * 32
      objectSprite.isBlockable = matchedObject.blockable

      objectSprite.name = `zObj-${obj.objRef}`
      // Add sprite to the container
      zObjectContainer.addChild(objectSprite)
    } else {
      console.log(`No match found for objRef: ${obj.objRef}`)
    }
  })
}
