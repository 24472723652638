import { TTwinVault } from 'constants/settings/types/TNpcs'
import { useTwinVaultContract } from 'hooks/useContract'
import { useEffect, useMemo, useState } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { stages } from '../utils'
import { JSBI } from '@/lib/sdk/index'

export function useWithdrawFee(
  vault: TTwinVault,
  account: string | null | undefined,
  currentBlock: number | undefined
) {
  const [displayUserFee, setDisplayUserFee] = useState(null)
  const [displayDevFee, setDisplayDevFee] = useState(null)

  const twinVault = useTwinVaultContract(vault)
  const calculateWithdrawFee = useSingleCallResult(
    twinVault,
    'calculateWithdrawFee',
    account && currentBlock ? [account, currentBlock] : []
  )
  const withdrawResult = calculateWithdrawFee.result
  const [userFee, devFee] = withdrawResult && Array.isArray(withdrawResult) ? withdrawResult : [null, null]

  useEffect(() => {
    if (userFee !== null && devFee !== null && userFee.toString() !== (displayUserFee ?? '').toString()) {
      setDisplayUserFee(userFee)
      setDisplayDevFee(devFee)
    }
  }, [userFee, devFee])

  // Memoize the calculation of the withdraw percentage for efficiency
  const withdrawPercentage = useMemo(() => {
    if (displayUserFee !== null && displayDevFee !== null) {
      const stageResult = stages(displayDevFee, displayUserFee)
      const stageNum = Number(stageResult.toString())
      const devFeeNum = Number(JSBI.BigInt(displayDevFee).toString())
      return (devFeeNum / stageNum) * 100
    }
    return null
  }, [displayUserFee, displayDevFee])

  return {
    userFee: displayUserFee,
    devFee: displayDevFee,
    withdrawPercentage,
    loading: calculateWithdrawFee.loading
  }
}
