// Avatar component
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React from 'react'
import styled from 'styled-components'

const AvatarContainer = styled.div<{ imageUrl?: string }>`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f0f0f0;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  border: 2px solid ${({ theme }) => theme.primary2};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  aspect-ratio: 1 / 1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 96px;
    height: 96px;
    margin-left: 8px;
  `};
`

const AImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`

export function AvatarImage({ imageUrl }: { imageUrl: string }) {
  const { settings } = useAppSettings()
  return (
    <AvatarContainer imageUrl={settings.platformImg.avatarBackgroundImg}>
      <AImage src={imageUrl} alt="NPC Avatar" />
    </AvatarContainer>
  )
}
