import { NPC } from 'constants/settings/types/TNpcs'
import { GameParams } from 'pages/GameApp'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

interface UseNpcDialogReturn {
  npcId: GameParams
  npcDialogueData: NPC | null
}

interface UseNpcDialog {
  setShowDialogue: React.Dispatch<React.SetStateAction<boolean>>
  setDialogueData: React.Dispatch<React.SetStateAction<NPC | null>>
}

export const useNpcDialogByUrlParam = ({ setShowDialogue, setDialogueData }: UseNpcDialog): UseNpcDialogReturn => {
  const { npcId } = useParams<GameParams>()
  const { npcs, settings } = useAppConstant()
  const [data, setData] = useState<NPC | null>(null)
  const history = useHistory()

  useEffect(() => {
    if (npcId) {
      const npcData = npcs.filter(npc => npc.id === npcId)
      if (npcData && npcData.length > 0) {
        setData(npcData[0])
        setDialogueData(npcData[0])
        setShowDialogue(true)
        history.push(`/${settings.domain}`)
      } else {
        console.error('404 npc not found')
      }
    }
  }, [npcId])

  return { npcId, npcDialogueData: data }
}
