import { chainId, factoryAddress, farmAddress, initHash, routerAddress, seaToken } from './settings'
import { TMenuOption } from '../types/TMenuOption'
import { EOptionType } from '../types/TNpcs'

export const menuOptions: TMenuOption[] = [
  {
    menuLabel: 'Swap',
    chainId: chainId,
    dappData: {
      type: EOptionType.SWAP,
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: seaToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://cronoscan.com/token/images/cryptocom_32.png'
    }
  },

  {
    menuLabel: 'Add Liquidity',
    chainId: chainId,
    dappData: {
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: seaToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://cronoscan.com/token/images/cryptocom_32.png',
      type: EOptionType.POOLS
    }
  },
  {
    menuLabel: 'Import Pools',
    chainId: chainId,
    dappData: {
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: seaToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://cronoscan.com/token/images/cryptocom_32.png',
      type: EOptionType.IMPORT_POOLS
    }
  },
  {
    menuLabel: 'Farms',
    chainId: chainId,
    dappData: {
      type: EOptionType.FARM,
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: seaToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://cronoscan.com/token/images/cryptocom_32.png'
    }
  },
  {
    menuLabel: 'Twin Vaults',
    chainId: chainId,
    dappData: {
      type: EOptionType.TWIN_VAULTS,
      factoryAddress: factoryAddress, // needed because this contract uses price usd look up ( see useAprHandling.ts )
      initHash: initHash, // needed because this contract uses price usd look up ( see useAprHandling.ts )
      twinVaults: [
        {
          twinVaultModalTitle: 'SEA-BILL Vault',
          contractAddress: '0x219d10c7a1e2c2ab4388da2f58bd420e6168d031'
        }
      ]
    }
  }
]
