import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useMemo } from 'react'
import { useActiveWeb3React } from '.'
import { PairState, usePairs } from '../data/Reserves'
import getToken from '../utils/getToken'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { Currency, currencyEquals, JSBI, Token, Price } from '@/lib/sdk/index'

/**
 * Returns the price in BUSD of the input currency
 * @param currency currency to compute the BUSD price of
 */
export default function useBUSDPrice(dappData: TDappData, currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const wrapped = wrappedCurrency(currency, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)
  const busdTicker = 'BUSD'
  const busd: Token | undefined = getToken(chainId, busdTicker, settings.wrappedCurrency, settings.chainTokenList)

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId && wrapped && currencyEquals(settings.wrappedCurrency, wrapped) ? undefined : currency,
        chainId ? settings.wrappedCurrency : undefined
      ],
      [busd && wrapped?.equals(busd) ? undefined : wrapped, busd],
      [chainId ? settings.wrappedCurrency : undefined, busd]
    ],
    [chainId, currency, wrapped, busd]
  )
  const [[ethPairState, ethPair], [busdPairState, busdPair], [busdEthPairState, busdEthPair]] = usePairs(
    tokenPairs,
    dappData
  )

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(settings.wrappedCurrency)) {
      if (busdPair) {
        const price = busdPair.priceOf(settings.wrappedCurrency)
        return busd ? new Price(currency, busd, price.denominator, price.numerator) : undefined
      } else {
        return undefined
      }
    }

    // handle busd
    if (busd && wrapped.equals(busd)) {
      return busd ? new Price(busd, busd, '1', '1') : undefined
    }

    const ethPairETHAmount = ethPair?.reserveOf(settings.wrappedCurrency)
    const ethPairETHBUSDValue: JSBI =
      ethPairETHAmount && busdEthPair
        ? busdEthPair.priceOf(settings.wrappedCurrency).quote(ethPairETHAmount).raw
        : JSBI.BigInt(0)

    // all other tokens
    // first try the usdc pair
    if (
      busd &&
      busdPairState === PairState.EXISTS &&
      busdPair &&
      busdPair.reserveOf(busd).greaterThan(ethPairETHBUSDValue)
    ) {
      const price = busdPair.priceOf(wrapped)
      return busd ? new Price(currency, busd, price.denominator, price.numerator) : undefined
    }
    if (ethPairState === PairState.EXISTS && ethPair && busdEthPairState === PairState.EXISTS && busdEthPair) {
      if (
        busd &&
        busdEthPair.reserveOf(busd).greaterThan('0') &&
        ethPair.reserveOf(settings.wrappedCurrency).greaterThan('0')
      ) {
        const ethUsdcPrice = busdEthPair.priceOf(busd)
        const currencyEthPrice = ethPair.priceOf(settings.wrappedCurrency)
        const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, busd, usdcPrice.denominator, usdcPrice.numerator)
      }
    }
    return undefined
  }, [chainId, busd, currency, ethPair, ethPairState, busdEthPair, busdEthPairState, busdPair, busdPairState, wrapped])
}
