import { Interface } from '@ethersproject/abi'
import { ChainId } from 'constants/settings'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.ROPSTEN]: '0x0000000000000000000000000000000000000001',
  [ChainId.RINKEBY]: '0x0000000000000000000000000000000000000001',
  [ChainId.GÖRLI]: '0x0000000000000000000000000000000000000001',
  [ChainId.KOVAN]: '0x0000000000000000000000000000000000000001',
  [ChainId.BSC_MAINNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.BSC_TESTNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.HARMONY_MAINNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.HARMONY_TESTNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.POLYGON_MAINNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.MUMBAI_TESTNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.AMOY_TESTNET]: '0x0000000000000000000000000000000000000001',
  [ChainId.BUILD_BEAR]: '0x0000000000000000000000000000000000000001'
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
