import { TBlockChainSettings } from '../constants/settings/types/TSettings'
export default function getNetworkSettings(blockchainSettings: TBlockChainSettings): Record<string, any> {
  const { hexChainId, name, currency, rpcURLs, explorerURL } = blockchainSettings
  return {
    chainId: hexChainId,
    chainName: name,
    nativeCurrency: {
      name: currency?.name,
      symbol: currency?.symbol,
      decimals: currency?.decimals
    },
    rpcUrls: rpcURLs,
    blockExplorerUrls: [explorerURL]
  }
}
