import { TThemes } from 'constants/settings/types/TThemes'

// BASE chain theme
export const CRONOS_THEME: TThemes = {
  // base
  white: '#FFFFFF',
  black: '#000000',

  // text
  text1: '#0A3D62',
  text2: '#3C6382',
  text3: '#60A3BC',
  text4: '#82CCDD',
  text5: '#B8E0E6',

  // backgrounds / greys
  bg1: '#EAF6F6',
  bg2: '#D4F1F4',
  bg3: '#B8E0E6',
  bg4: '#82CCDD',
  bg5: '#60A3BC',

  // specialty colors
  modalBG: 'rgba(10,61,98,0.3)',
  advancedBG: 'rgba(234,246,246,0.6)',

  // primary colors
  primary1: '#0A3D62',
  primary2: '#FF7F50', // Coral color
  primary3: '#60A3BC',
  primary4: '#82CCDD',
  primary5: '#B8E0E6',

  // color text
  primaryText1: '#0A3D62',

  // secondary colors
  secondary1: '#FF6F61', // Coral color
  secondary2: '#82CCDD',
  secondary3: '#EAF6F6',

  // other
  red1: '#FF6F61', // Coral color
  red2: '#E57373',
  red3: '#D32F2F',
  green1: '#388E3C',
  yellow1: '#FFEB3B',
  yellow2: '#FF9800',
  blue1: '#0A3D62',
  tokenColor: '#0A3D62',

  // Added:
  tokenButtonGradientStart: '#0A3D62',
  tokenButtonGradientEnd: '#3C6382',
  customCardGradientStart: '#60A3BC',
  customCardGradientEnd: '#0A3D62'
}
