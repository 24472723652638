import { TSettings, EBackgroundTheme } from 'constants/settings/types/TSettings'
import getTokenWithDefault from 'utils/getTokenWithDefault'
import cardBGImageSmaller from '../../../assets/images/application/xl_uni.png'
import backgroundImg2 from '../cronos/assets/background.png'
import logoDark from '../cronos/assets/game.png'
import { ChainId, Blockchain } from '../index'
import avatarBackgroundImage from './assets/npc/avatarBackground.png'
import { Token, Currency } from '@/lib/sdk/index'
import { ChainTokenList } from '@/lib/sdk-extra/chainTokenList'

export const chainId = ChainId.CRONOS
const wrappedCurrency = new Token(chainId, '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18, 'WCRO', 'Wrapped Cro')
const chainTokenList = new ChainTokenList(chainId)

export const seaToken = new Token(chainId, '0x7A3F6fFC0b5bb6f18736E4f21B3715932c2Fcf79', 18, 'SEA', 'Sea Token')
export const farmAddress = '0x56731CCBe105363f8B01589d6d9F2383B7cC11CD'
export const factoryAddress = '0xB8364EFF247a469fe7b34956B1e5c0A9Bda6F630'
export const initHash = '0x57c454d7cd80b7071ad4cf9f163c6e63b2e29ed0f61e3c967e0e528664728ae5'
export const routerAddress = '0x236D14CbE83d5f3E92f01952A2FAB25174adcb9e'
export const govTokenName = seaToken.symbol

// CRONOS chain settings
export const CRONOS: TSettings = {
  domain: 'sea',
  platformBackgroundTheme: EBackgroundTheme.BUBBLE,
  platformName: 'RPG Sea',
  platformLinks: [
    { label: 'Visit the Discord', url: 'https://discord.gg/nm2ayGSrZV' },
    { label: 'Check us out on X', url: 'https://x.com/defi_rpg' },
    { label: 'Telegram Group', url: 'https://t.me/DeFi_RPG' },
    { label: 'Reddit', url: 'https://www.reddit.com/r/DeFiRPG/s/H8VFbMtAcF' }
  ],
  platformImg: {
    appBgImg: backgroundImg2,
    appLogoWhite: logoDark,
    appLogoDark: logoDark,
    appBgCard: cardBGImageSmaller,
    avatarBackgroundImg: avatarBackgroundImage
  },
  webInterfaces: ['defiapps.io'],
  blockchainSettings: {
    chainId: chainId,
    blockchain: Blockchain.CRONOS,
    name: 'Cronos',
    rpcURLs: ['https://cronos-evm-rpc.publicnode.com'],
    explorerURL: 'https://cronoscan.com/',
    blockTime: 5.5,
    hexChainId: `0x${chainId?.toString(16)}`,
    currency: new Currency(18, 'CRO', 'Cronos'),
    currencyImg: 'https://cronoscan.com/token/images/cryptocom_32.png'
  },
  etherscanPrefix: 'https://cronoscan.com/',
  liquidityPair: {
    symbol: 'RPG-LP',
    name: 'RPG-LP Token'
  },
  multiCallAddr: '0xcA11bde05977b3631167028862bE2a173976CA11',
  wrappedCurrency: wrappedCurrency,
  suggestedBases: [],
  pinnedPairs: [],
  merkleDistributorAddr: undefined,
  chainTokenList: chainTokenList,
  basesToTrackLiquidityFor: [],
  basesToCheckTradesAgainst: [
    ...[wrappedCurrency],
    getTokenWithDefault(chainId, 'USDC', wrappedCurrency, seaToken, chainTokenList),
    getTokenWithDefault(chainId, 'WCRO', wrappedCurrency, seaToken, chainTokenList),
    getTokenWithDefault(chainId, 'SEA', wrappedCurrency, seaToken, chainTokenList)
  ]
}

// todo we need to look into these others ones and if we should add it
// suggestedBases: [
//   ...[wrappedCurrency],
//   getTokenWithDefault(chainId, 'USDC', wrappedCurrency, governanceToken),
//   getTokenWithDefault(chainId, 'WMATIC', wrappedCurrency, governanceToken)
// ],
// basesToTrackLiquidityFor: [
//   ...[wrappedCurrency],
//   getTokenWithDefault(chainId, 'USDC', wrappedCurrency, governanceToken),
//   getTokenWithDefault(chainId, 'GOVERN', wrappedCurrency, governanceToken)
// ],
// basesToCheckTradesAgainst: [
//   ...[wrappedCurrency],
//   getTokenWithDefault(chainId, 'USDC', wrappedCurrency, governanceToken),
//   getTokenWithDefault(chainId, 'WMATIC', wrappedCurrency, governanceToken)
// ],
