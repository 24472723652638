import DEFAULT_TOKEN_LIST from '@0xcaerus/default-token-list'
import { DEFAULT_CURRENCY } from 'constants/settings'
import { TDappData } from 'constants/settings/types/TNpcs'
import isEqual from 'lodash.isequal'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { TOKEN_DEFAULT_IMG } from '../../constants'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import baseCurrencies from '../../utils/baseCurrencies'
import Logo from '../Logo'
import { Token } from '@/lib/sdk/index'

export const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

export const getTokenFromDefaultTokenList = (address: string, chainId: number, symbol: string): string => {
  const defaultTokenList = DEFAULT_TOKEN_LIST.tokens.find(i => i.chainId === chainId && address === i.address)
  return defaultTokenList?.logoURI ?? TOKEN_DEFAULT_IMG
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  dappData,
  size = '24px',
  style
}: {
  currency?: any
  dappData: TDappData
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { settings } = useAppSettings()
  const srcs: string[] = useMemo(() => {
    if (currency && [settings.blockchainSettings.currency, DEFAULT_CURRENCY].some(item => isEqual(item, currency)))
      return []

    if (currency && currency.address) {
      const logoUrlLocation = [settings.blockchainSettings.chainId].includes(currency.chainId)
        ? getTokenFromDefaultTokenList(currency.address, currency.chainId, currency.symbol)
        : getTokenLogoURL(currency.address)
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, logoUrlLocation]
      }

      return [logoUrlLocation]
    }
    return []
  }, [currency, uriLocations])

  const wrappedCurrency =
    currency instanceof Token
      ? baseCurrencies(settings.blockchainSettings.currency, settings.wrappedCurrency)[1]
      : undefined
  const currentNativeCurrency = settings.blockchainSettings.currency ?? undefined

  // deep compares the objects to check if they match
  if (
    JSON.stringify(currency) === JSON.stringify(currentNativeCurrency) ||
    JSON.stringify(currency) === JSON.stringify(wrappedCurrency)
  ) {
    return (
      <StyledEthereumLogo
        src={dappData.appTokenImage ?? settings.blockchainSettings.currencyImg}
        size={size}
        style={style}
      />
    )
  } else {
    return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  }
}
