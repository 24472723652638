import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import styled, { keyframes, css } from 'styled-components'
import { TYPE, ExternalLink } from 'theme'

export const moveAnimation = keyframes`
  0% { transform: translate3d(0px, 0px, 0px); }
  50% { transform: translate3d(0px, 10px, 0px); }
  100% { transform: translate3d(0px, 0px, 0px); }
`

export const HeroBannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  background: ${({ theme }) => `linear-gradient(135deg, ${theme.bg1}, ${theme.bg3})`};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  @media (max-width: 768px) {
    height: 65vh; // Adjust the height for mobile devices
  }
`

export const Title = styled.h1`
  font-family: 'Russo One';
  font-size: 2.5em;
  margin-bottom: 0.5em;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2em; // Adjust font size for smaller screens
  }
`

interface AnimatedBackgroundProps {
  backgroundImage: string
}

export const AnimatedBackground = styled.div<AnimatedBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 120%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage}), linear-gradient(135deg, #1e3a8a, #3b82f6)`};
  background-size: cover;
  background-position: center;
  opacity: 0.6;
  animation: ${moveAnimation} 10s ease-in-out infinite;
  z-index: 1;
`

export const HeroContent = styled.div`
  z-index: 2;
  text-align: center;
  max-width: 800px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Subtitle = styled.p`
  font-size: 1.5em;
  margin-bottom: 1em;
`

export const HeroButton = styled(ExternalLink)`
  background-color: ${({ theme }) => theme.bg5};
  width: 120px;
  color: ${({ theme }) => theme.text1};
  font-weight: bold;
  padding: 0.8em 1.5em;
  opacity: 1;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s;
  text-decoration: none;
  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 0 25px ${({ theme }) => theme.primary1});
  }

  margin-top: 25px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 25px;
  `};
`

export const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`
export const TextContent = styled.div`
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 300;
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const AnimatedText = styled(TYPE.body)`
  animation: ${fadeIn} 1s ease-out;
  line-height: 25px;
`

export const ThemedAutoColumn = styled(AutoColumn)`
  background-color: ${({ theme }) => theme.bg5};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
  `};
`

export const ThemedAutoRowDexDisplay = styled(AutoRow)<{ isVisible: boolean }>`
  padding-top: 25px;
  padding-bottom: 25px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  background-color: ${({ theme }) => theme.bg5};
  animation: ${({ isVisible }) =>
    isVisible
      ? css`
          ${appearFromBottom} 2s ease-out
        `
      : 'unset'};
`

export const Divider = styled.div`
  width: 100%;
  height: 4px;
  background: repeating-linear-gradient(
    45deg,
    ${({ theme }) => theme.bg5},
    ${({ theme }) => theme.bg5} 10px,
    ${({ theme }) => theme.bg4} 10px,
    ${({ theme }) => theme.bg4} 20px
  );
  margin: 1.5em 0;
`
