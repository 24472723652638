import React from 'react'
import styled from 'styled-components'

// Close Icon styles (top-right of the modal)
export const CloseIcon = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.primary2};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary2};
  }

  &:focus {
    color: ${({ theme }) => theme.primary2};
  }
`
const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1500;
`

const ModalContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  width: 460px;
  max-width: 90%;
  max-height: 90%;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;
`

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5em;
  color: ${({ theme }) => theme.primary2};
`

const ModalBody = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text2};
`

// Reusable Modal Component
export default function MenuModal({
  isOpen,
  onClose,
  title,
  children
}: {
  isOpen: boolean
  onClose: () => void
  title?: string
  children: React.ReactNode
}) {
  if (!isOpen) return null

  return (
    <ModalOverlay aria-label="modalOverlay">
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>{title ?? ''}</ModalTitle>
          <div>
            <CloseIcon onClick={onClose}>{`[Esc]/X`}</CloseIcon>
          </div>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContainer>
    </ModalOverlay>
  )
}
