import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { TDappData, EContractVersion } from 'constants/settings/types/TNpcs'
import { ethers } from 'ethers'
import { useTokenExchangeContract } from 'hooks/useContract'
import { useState, useCallback, useEffect } from 'react'
import { extractRevertReason } from './utils'

export interface TokenExchangeContractInfo {
  exchangeRate: string | null
}

export function useTokenExchangeContractInfo(dappData: TDappData) {
  const { library } = useWeb3React<Web3Provider>()
  const tokenExchange = useTokenExchangeContract(dappData)

  const [tokenExchangeInfo, setTokenExchangeInfo] = useState<TokenExchangeContractInfo>({
    exchangeRate: null
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  // Fetch tokenExchange info from the contract
  const fetchTokenExchangeInfo = useCallback(async () => {
    if (!tokenExchange || !library) {
      setError('tokenExchange contract or library is not available')
      setLoading(false)
      return
    }

    try {
      setLoading(true)
      setError(null)
      // Fetch all tokenExchange-related information
      const [exchangeRate] = await Promise.all([tokenExchange.exchangeRate()])

      setTokenExchangeInfo({
        exchangeRate:
          dappData.tokenExchange?.contractVersion === EContractVersion.TOKEN_EXCHANGE_DECIMAL_SUPP_V1 ||
          dappData.tokenExchange?.contractVersion === EContractVersion.TOKEN_EXCHANGE_DECIMAL_SUPP_V2
            ? ethers.utils.formatEther(exchangeRate)
            : exchangeRate
      })
      setLoading(false)
    } catch (err) {
      const message = extractRevertReason(err)
      console.error('Error fetching tokenExchange info:', err)
      setError(message)
      setLoading(false)
    }
  }, [tokenExchange, library])

  useEffect(() => {
    if (tokenExchange) {
      fetchTokenExchangeInfo()
    }
  }, [tokenExchange, fetchTokenExchangeInfo])

  return {
    tokenExchangeInfo,
    loading,
    error,
    fetchTokenExchangeInfo
  }
}
