import { useActiveWeb3React } from 'hooks'
import { tryParseAmount } from './utils'
import { TokenAmount, Token, CurrencyAmount, JSBI } from '@/lib/sdk/index'

// based on typed value
export function useCrowdSaleInput(
  typedValue: string,
  depositedToken: Token,
  userTokenAmountToDeposit: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, depositedToken)

  const parsedAmount =
    parsedInput && userTokenAmountToDeposit && JSBI.lessThanOrEqual(parsedInput.raw, userTokenAmountToDeposit.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
