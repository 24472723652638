export const getSeason = (date: Date): string => {
  const month = date.getMonth()
  let season: string

  if (month >= 2 && month <= 4) {
    season = 'Spring' // March, April, May
  } else if (month >= 5 && month <= 7) {
    season = 'Summer' // June, July, August
  } else if (month >= 8 && month <= 10) {
    season = 'Fall' // September, October, November
  } else {
    season = 'Winter' // December, January, February
  }

  const day = date.getDate()
  const weekday = date.toLocaleDateString('en-US', { weekday: 'short' })

  return `${season} ${day} (${weekday})`
}
