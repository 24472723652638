import { parseUnits } from 'ethers/lib/utils'
import { TokenAmount, Token, Currency, CurrencyAmount, JSBI } from '@/lib/sdk/index'

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const extractRevertReason = (error: any): string => {
  try {
    // Access the specific field where revert reason is present
    if (error?.error?.data?.message) {
      return error.error.data.message
    }
    if (error?.data?.message) {
      return error.data.message
    }
    // Check if revert reason is embedded in the message
    const message = error.message || ''
    const match = message.match(/execution reverted: (.*)/)
    return match ? match[1] : 'Unknown error occurred'
  } catch (err) {
    return 'Unknown error occurred'
  }
}
