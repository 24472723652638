import { ZERO_GAS_ADDRESS } from '../constants/index'
import { Token } from '@/lib/sdk/index'
import { ChainTokenList } from '@/lib/sdk-extra/chainTokenList'

/**
 * Retrieves a token based on the provided symbol and chain ID, with fallbacks to wrapped and governance tokens if the
 * symbol does not directly match any known tokens on the specified chain.
 *
 * @param chainId - The blockchain network ID used to locate the token within the available tokens for that chain.
 * @param symbol - The symbol of the token to retrieve. The symbol is matched case-insensitively.
 * @param wrappedCurrency - The wrapped native currency token (e.g., WETH) for the given chain, used as a default if the symbol matches.
 * @param governanceToken - The governance token for the specified chain, used as a fallback if the token is not found and matches the governance symbol.
 *
 * @returns A `Token` object corresponding to the given symbol and chain ID. If the token is not found, a new token with
 * the symbol and a zero gas address is created and returned. If this token matches the governance symbol, the governance
 * token is returned.
 */
export default function getTokenWithDefault(
  chainId: number,
  symbol: string,
  wrappedCurrency: Token,
  governanceToken: Token,
  chainTokenList: ChainTokenList
): Token {
  symbol = symbol.toUpperCase()
  let token: Token

  if (symbol === wrappedCurrency.symbol) {
    token = wrappedCurrency
  } else {
    const retrievedToken = chainTokenList.firstBySymbol(symbol)
    token = retrievedToken ? retrievedToken : new Token(chainId, ZERO_GAS_ADDRESS, 18, symbol, symbol)
  }

  if (!token || token.address === ZERO_GAS_ADDRESS) {
    const govToken = governanceToken
    if (symbol.toUpperCase() === govToken.symbol?.toUpperCase()) {
      token = govToken
    }
  }

  return token
}
