import { NPC } from 'constants/settings/types/TNpcs'
import { addNPC } from 'game/npcCharacters/addNpc'
import { Application, Container, Texture, Rectangle } from 'pixi.js'

type NPCResources = {
  [npcId: string]: Texture[][]
}

export const preloadNPCTextures = (npcs: NPC[]): { [npcId: string]: Texture[][] } => {
  const npcTextures: { [npcId: string]: Texture[][] } = {}

  npcs.forEach(npcData => {
    const baseTexture = Texture.from(npcData.spritesheetUrl)
    const frameWidth = npcData.frameWidth
    const frameHeight = npcData.frameHeight

    const isStaticImage = !npcData.idleAnimation.enabled && !npcData.npcBehavior.continuousMovement
    const numColumns = isStaticImage ? 1 : npcData.spritesheetNumColumns
    const numRows = isStaticImage ? 1 : npcData.spritesheetNumRows

    const rows: Texture[][] = []
    for (let row = 0; row < numRows; row++) {
      const rowFrames: Texture[] = []
      for (let col = 0; col < numColumns; col++) {
        const x = col * frameWidth
        const y = row * frameHeight
        const frame = new Texture(baseTexture.baseTexture, new Rectangle(x, y, frameWidth, frameHeight))
        rowFrames.push(frame)
      }
      rows.push(rowFrames)
    }

    npcTextures[`${npcData.id}-${npcData.type}`] = rows
  })

  return npcTextures
}

export const loadNpcCharacters = (app: Application, npcs: NPC[], container: Container, npcTextures: NPCResources) => {
  if (!npcs || npcs.length === 0) {
    return []
  }

  // Collect cleanup functions for each NPC
  const cleanupFunctions: Array<() => void> = npcs.map(npcData => {
    const textures = npcTextures[`${npcData.id}-${npcData.type}`]

    if (!textures) {
      console.error(`No textures found for NPC ${npcData.id}`)
      return () => ({})
    }

    // Add the NPC to the container using preloaded textures
    return addNPC(app, npcData, container, textures)
  })

  return cleanupFunctions
}
