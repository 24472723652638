import DialogueModal from 'components/Dialogue/DialogueModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import AddLiquidity from 'pages/AddLiquidity'
import PoolFinder from 'pages/PoolFinder'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonPrimary, ButtonSecondary } from '../../components/Button'
import Card from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/farms/styled'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'
import { RowBetween, RowFixed } from '../../components/Row'
import { Dots } from '../../components/swap/styleds'
import { BIG_INT_ZERO } from '../../constants'
import { usePairs } from '../../data/Reserves'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useStakingInfo } from '../../state/stake/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { TYPE, HideSmall } from '../../theme'
import { Pair, JSBI } from '@/lib/sdk/index'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(
    76.02% 75.41% at 1.84% 0%,
    ${({ theme }) => theme.customCardGradientStart} 0%,
    ${({ theme }) => theme.customCardGradientEnd} 100%
  );
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface PoolProps {
  dappData: TDappData
}

export default function Pool({ dappData }: PoolProps) {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const { settings } = useAppSettings()

  // State to manage which modal to show
  const [showAddLiquidityModal, setShowAddLiquidityModal] = useState(false)
  const [showCreatePoolModal, setShowCreatePoolModal] = useState(false)
  const [showPoolFinderModal, setShowPoolFinderModal] = useState(false)

  // Close modal functions
  const closeAddLiquidityModal = () => setShowAddLiquidityModal(false)
  const closeCreatePoolModal = () => setShowCreatePoolModal(false)
  const closePoolFinderModal = () => setShowPoolFinderModal(false)

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  if (!dappData.factoryAddress) {
    throw new Error('Factory address not found')
  }

  if (!dappData.initHash) {
    throw new Error('Factory init hash not found')
  }

  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map(tokens => ({
        liquidityToken: toV2LiquidityToken(
          dappData.factoryAddress!,
          dappData.initHash!,
          tokens,
          settings.liquidityPair.symbol ?? 'GAME-LP',
          settings.liquidityPair.name ?? 'GAME LP'
        ),
        tokens
      })),
    [trackedTokenPairs, settings]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens),
    dappData
  )
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()
  const stakingInfo = useStakingInfo(true, dappData)
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(
    stakingInfosWithBalance?.map(stakingInfo => stakingInfo.tokens),
    dappData
  )
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(v2Pair => {
    return (
      stakingPairs
        ?.map(stakingPair => stakingPair[1])
        .filter(stakingPair => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Liquidity provider rewards</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  {`Liquidity providers encourage to deposit into the farms to earn APR's if applicable.`}
                </TYPE.white>
              </RowBetween>
              {/* {blockchain === Blockchain.ETHEREUM && (
                <ExternalLink
                  style={{ color: 'white', textDecoration: 'underline' }}
                  target="_blank"
                  href="https://uniswap.org/docs/v2/core-concepts/pools/"
                >
                  <TYPE.white fontSize={14}>Read more about providing liquidity</TYPE.white>
                </ExternalLink>
              )} */}
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>

        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow style={{ marginTop: '1rem' }} padding={'0'}>
              <HideSmall>
                <TYPE.mediumHeader style={{ marginTop: '0.5rem', justifySelf: 'flex-start' }}>
                  Your liquidity
                </TYPE.mediumHeader>
              </HideSmall>
              <ButtonRow>
                <ResponsiveButtonPrimary
                  padding="6px 8px"
                  onClick={() => setShowCreatePoolModal(true)} // Open Create Pool Modal
                >
                  Create a pair
                </ResponsiveButtonPrimary>
                <ResponsiveButtonPrimary
                  id="join-pool-button"
                  padding="6px 8px"
                  onClick={() => setShowAddLiquidityModal(true)} // Open Add Liquidity Modal
                >
                  <Text fontWeight={500} fontSize={16}>
                    Add Liquidity
                  </Text>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </TitleRow>

            {showAddLiquidityModal && (
              <DialogueModal isOpen={showAddLiquidityModal} onClose={closeAddLiquidityModal}>
                <AddLiquidity
                  dappData={dappData}
                  currencyIdA={dappData.governanceToken?.symbol ?? 'ETH'}
                  isCreate={false}
                />
              </DialogueModal>
            )}

            {showCreatePoolModal && (
              <DialogueModal isOpen={showCreatePoolModal} onClose={closeCreatePoolModal} title="Create">
                <AddLiquidity
                  dappData={dappData}
                  currencyIdA={dappData.governanceToken?.symbol ?? 'ETH'}
                  isCreate={true}
                />
              </DialogueModal>
            )}

            {/* {showPoolFinderModal && (
              // <DialogueModal isOpen={showPoolFinderModal} onClose={closePoolFinderModal} title="Import Pool">
              <PoolFinder dappData={dappData} />
              // </DialogueModal>
            )} */}

            {!account ? (
              <Card padding="40px">
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              </Card>
            ) : v2IsLoading ? (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </EmptyProposals>
            ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
              <>
                {/* {blockchain === Blockchain.ETHEREUM && (
                  <ButtonSecondary>
                    <RowBetween>
                      <ExternalLink href={'https://uniswap.info/account/' + account}>
                        Account analytics and accrued fees
                      </ExternalLink>
                      <span> ↗</span>
                    </RowBetween>
                  </ButtonSecondary>
                )} */}
                {v2PairsWithoutStakedAmount.map(v2Pair => (
                  <FullPositionCard dappData={dappData} key={v2Pair.liquidityToken.address} pair={v2Pair} />
                ))}
                {stakingPairs.map(
                  (stakingPair, i) =>
                    stakingPair[1] && ( // skip pairs that arent loaded
                      <FullPositionCard
                        dappData={dappData}
                        key={stakingInfosWithBalance[i].pid}
                        pair={stakingPair[1]}
                        stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                      />
                    )
                )}
              </>
            ) : (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  No liquidity found.
                </TYPE.body>
              </EmptyProposals>
            )}
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
