import Alert from 'components/Alert/Alert'
import { useAlert } from 'components/Alert/hook/useAlert'
import { SupportedNetwork, SUPPORTED_NETWORKS, SUPPORTED_CHAINS } from 'constants/settings'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React, { useState, useEffect, useRef } from 'react'
import {
  DropdownWrapper,
  DropdownButton,
  DropdownContent,
  DropdownItem,
  ConnectedLabel,
  TbaLabel
} from './SwitchTownNetwork.style'
import { switchNetwork } from './utils/utils'

export const SwitchTownNetwork: React.FC = () => {
  const { settings } = useAppConstant()
  const { alert, setAlert, clearAlert } = useAlert()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState<SupportedNetwork | null>(null)
  const [defaultSelectedNetworkName, setDefaultSelectedNetworkName] = useState<string>('Select Network')
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSwitchNetwork = async (network: SupportedNetwork) => {
    try {
      const { chainId, name, rpcUrl, explorerUrl, nativeCurrency } = network
      await switchNetwork(chainId, name, rpcUrl, explorerUrl, nativeCurrency)
      setSelectedNetwork(network)
    } catch (error) {
      console.error('Failed to switch network:', error)
      setAlert('Network switch failed or was canceled.', false)
    } finally {
      setIsOpen(false)
    }
  }

  // Close dropdown if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    if (settings.blockchainSettings.name) {
      setDefaultSelectedNetworkName(`${settings.blockchainSettings.name}: ${settings.platformName}`)
    }
  }, [settings])

  let selectedNetworkNameDisplay = defaultSelectedNetworkName
  if (selectedNetwork) {
    selectedNetworkNameDisplay = `${settings.blockchainSettings.name}: ${settings.platformName}`
  }

  // Sorting SUPPORTED_NETWORKS to bring the active network to the top
  const sortedNetworks = [...SUPPORTED_NETWORKS].sort((a, b) => {
    const isActiveA = settings.blockchainSettings.hexChainId === a.chainId
    const isActiveB = settings.blockchainSettings.hexChainId === b.chainId
    return isActiveA ? -1 : isActiveB ? 1 : 0
  })

  return (
    <>
      {alert && (
        <Alert
          message={alert.message}
          success={alert.success}
          link={alert.link}
          linkText={alert.linkText}
          close={clearAlert}
          autoCloseAfterMs={5000}
        />
      )}
      <DropdownWrapper ref={dropdownRef}>
        <DropdownButton onClick={handleToggleDropdown}>{selectedNetworkNameDisplay}</DropdownButton>
        <DropdownContent isOpen={isOpen}>
          <div style={{ marginBottom: '20px', color: '#FFF' }}>Travel to another town?</div>
          {sortedNetworks.map((network: SupportedNetwork) => {
            const isSupported = SUPPORTED_CHAINS.includes(parseInt(network.chainId, 16))

            return (
              <DropdownItem
                key={network.chainId}
                onClick={() => isSupported && handleSwitchNetwork(network)}
                isDisabled={!isSupported}
                isSelected={
                  isSupported &&
                  (selectedNetwork?.chainId === network.chainId ||
                    settings.blockchainSettings.hexChainId === network.chainId)
                }
                style={{
                  marginBottom: '8px' // Adds spacing between items for better readability
                }}
              >
                {isSupported ? (
                  <>
                    {`${network.name}`}
                    {(selectedNetwork?.chainId === network.chainId ||
                      settings.blockchainSettings.hexChainId === network.chainId) && (
                      <ConnectedLabel>Connected</ConnectedLabel>
                    )}
                  </>
                ) : (
                  <>
                    {`${network.name}`}
                    <TbaLabel>Coming Soon!</TbaLabel>
                  </>
                )}
              </DropdownItem>
            )
          })}
        </DropdownContent>
      </DropdownWrapper>
    </>
  )
}
