import { TransactionResponse } from '@ethersproject/providers'
import { ButtonError } from 'components/Button'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import Modal from 'components/Modal'
import { LoadingView, SubmittedView } from 'components/ModalViews'
import { RowBetween } from 'components/Row'
import TransactionErrorModal from 'components/TransactionErrorModal/TransactionErrorModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import { BigNumber } from 'ethers'
import { useCrowdSaleContract } from 'hooks/useContract'
import { useNativeBalance } from 'hooks/useNativeBalance'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import { useState, useCallback, useEffect } from 'react'
import React from 'react'
import styled from 'styled-components'
import { TYPE, CloseIcon } from 'theme'
import { useCrowdSaleInfo, CrowdSaleInfo } from './useCrowdSaleInfo'
import { useCrowdSaleInput } from './useCrowdSaleInput'
import { extractRevertReason } from './utils'
import { useActiveWeb3React } from '../../hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { calculateGasMargin } from '../../utils'
import { TokenAmount, BigintIsh } from '@/lib/sdk/index'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
interface CrowdSaleModalProps {
  isOpen: boolean
  onDismiss: () => void
  dappData: TDappData
}

export default function CrowdSale({ isOpen, onDismiss, dappData }: CrowdSaleModalProps) {
  const { account, library } = useActiveWeb3React()
  const { settings } = useAppConstant()
  const nativeBalance = useNativeBalance(account, library)
  const nativeCurrency = nativeBalance ? nativeBalance : BigNumber.from(0)
  const nativeTokenAmount = new TokenAmount(settings.wrappedCurrency, nativeCurrency?.toString() as BigintIsh)
  const [errorToDisplay, setErrorToDisplay] = useState<string>()
  const [crowdSaleInfoDisplay, setCrowdSaleInfoDisplay] = useState<CrowdSaleInfo | undefined>()
  const [displayEstimateAmount, setDisplayEstimateAmount] = useState<string>('-')
  // Track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const crowdSale = useCrowdSaleContract(dappData)
  const { parsedAmount, error } = useCrowdSaleInput(typedValue, settings.wrappedCurrency, nativeTokenAmount)
  const { crowdSaleInfo, fetchCrowdsaleInfo, error: crowdSaleInfoError } = useCrowdSaleInfo(dappData)

  useEffect(() => {
    fetchCrowdsaleInfo()
  }, [dappData])

  useEffect(() => {
    setCrowdSaleInfoDisplay(crowdSaleInfo)
  }, [crowdSaleInfo])

  useEffect(() => {
    if (crowdSaleInfoError) {
      setErrorToDisplay(crowdSaleInfoError)
    }
  }, [crowdSaleInfoError])

  useEffect(() => {
    if (parsedAmount?.greaterThan('0') && crowdSaleInfoDisplay?.rate) {
      setDisplayEstimateAmount(parsedAmount.multiply(crowdSaleInfoDisplay.rate).toSignificant(18))
    } else {
      setDisplayEstimateAmount('-')
    }
  }, [parsedAmount])

  // State for pending and submitted transaction views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const [failed, setFailed] = useState<boolean>(false)

  if (!dappData.crowdSale) {
    throw new Error('Token Exchange address not found')
  }

  const depositedToken = settings.wrappedCurrency

  async function onBuyTokens() {
    setAttempting(true)
    if (crowdSale && parsedAmount && account) {
      const formattedAmount = `0x${parsedAmount.raw.toString(16)}`
      try {
        const estimatedGas = await crowdSale.estimateGas.buyTokens(account, {
          value: formattedAmount // Send the amount in the transaction
        })
        // Execute the buyTokens function, passing the account as the beneficiary and the parsed amount as value
        const response: TransactionResponse = await crowdSale.buyTokens(account, {
          value: formattedAmount, // Send the amount in wei
          gasLimit: calculateGasMargin(estimatedGas)
        })

        addTransaction(response, {
          summary: `Bought Token`
        })
        setHash(response.hash)
      } catch (error) {
        const formattedError = extractRevertReason(error)

        setAttempting(false)
        setFailed(true)
        console.error('Bought Token Error:', error)
        setErrorToDisplay(formattedError)
      }
    }
  }

  // Wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    setFailed(false)
    onDismiss()
  }, [onDismiss])

  const crowdSaleMoreInfoEL = (
    <AutoColumn gap="12px" justify={'center'}>
      {crowdSaleInfoDisplay && displayEstimateAmount && displayEstimateAmount !== '-' && (
        <TYPE.subHeader>{`Estimate received: ${displayEstimateAmount} ${dappData.crowdSale.tokenToRecieve.symbol}`}</TYPE.subHeader>
      )}
      {crowdSaleInfoDisplay &&
        (crowdSaleInfoDisplay.hasEnded ? (
          <TYPE.subHeader>{`Sorry but the contract has been stopped`}</TYPE.subHeader>
        ) : (
          <>
            <TYPE.subHeader>{`Started at ${crowdSaleInfoDisplay.startBlock} block`}</TYPE.subHeader>
            <TYPE.subHeader>{`Ending at ${crowdSaleInfoDisplay.endBlock} block`}</TYPE.subHeader>
            <TYPE.subHeader>{`Total Collected ${crowdSaleInfoDisplay.weiRaised} ${depositedToken.symbol}`}</TYPE.subHeader>
          </>
        ))}
    </AutoColumn>
  )
  // Render the modal content
  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && !failed && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>

          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={() => ({})}
            showMaxButton={false}
            currency={settings.blockchainSettings.currency}
            pair={null}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to deposit: '}
            id={`crowdsale-input-${dappData.crowdSale?.contractAddress}`}
            dappData={dappData}
          />

          <RowBetween>
            <ButtonError
              disabled={!!error || (crowdSaleInfoDisplay?.hasEnded ?? true)}
              error={!!error && !!parsedAmount}
              onClick={onBuyTokens}
            >
              {error ?? 'Purchase'}
            </ButtonError>
          </RowBetween>
          {crowdSaleMoreInfoEL}
        </ContentWrapper>
      )}
      {attempting && !hash && !failed && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{'Buy Token(s)'}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {parsedAmount?.toSignificant(4)} {depositedToken.symbol}{' '}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && !failed && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              Bought {parsedAmount?.toSignificant(4)} {dappData.crowdSale.tokenToRecieve.symbol}{' '}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
      {!attempting && !hash && failed && (
        <TransactionErrorModal errorToDisplay={errorToDisplay ?? 'error'} wrappedOnDismiss={wrappedOnDismiss} />
      )}
    </Modal>
  )
}
