import { TObjects } from '../types/TObjects'
import obj1 from './assets/objects/1.png'
import obj10 from './assets/objects/10.png'
import obj11 from './assets/objects/11.png'
import obj12 from './assets/objects/12.png'
import obj13 from './assets/objects/13.png'
import obj14 from './assets/objects/14.png'
import obj15 from './assets/objects/15.png'
import obj16 from './assets/objects/16.png'
import obj17 from './assets/objects/17.png'
import obj18 from './assets/objects/18.png'
import obj19 from './assets/objects/19.png'
import obj2 from './assets/objects/2.png'
import obj20 from './assets/objects/20.png'
import obj21 from './assets/objects/21.png'
import obj22 from './assets/objects/22.png'
import obj23 from './assets/objects/23.png'
import obj24 from './assets/objects/24.png'
import obj25 from './assets/objects/25.png'
import obj26 from './assets/objects/26.png'
import obj27 from './assets/objects/27.png'
import obj28 from './assets/objects/28.png'
import obj29 from './assets/objects/29.png'
import obj3 from './assets/objects/3.png'
import obj4 from './assets/objects/4.png'
import obj5 from './assets/objects/5.png'
import obj6 from './assets/objects/6.png'
import obj7 from './assets/objects/7.png'
import obj8 from './assets/objects/8.png'
import obj9 from './assets/objects/9.png'

export const objects: TObjects = {
  objectMapping: {
    '1': {
      imageUrl: obj1,
      blockable: true
    },
    '2': {
      imageUrl: obj2,
      blockable: true
    },
    '3': {
      imageUrl: obj3,
      blockable: true
    },
    '4': {
      imageUrl: obj4,
      blockable: true
    },
    '5': {
      imageUrl: obj5,
      blockable: true
    },
    '6': {
      imageUrl: obj6,
      blockable: true
    },
    '7': {
      imageUrl: obj7,
      blockable: true
    },
    '8': {
      imageUrl: obj8,
      blockable: true
    },
    '9': {
      imageUrl: obj9,
      blockable: true
    },
    '10': {
      imageUrl: obj10,
      blockable: true
    },
    '11': {
      imageUrl: obj11,
      blockable: true
    },
    '12': {
      imageUrl: obj12,
      blockable: true
    },
    '13': {
      imageUrl: obj13,
      blockable: true
    },
    '14': {
      imageUrl: obj14,
      blockable: true
    },
    '15': {
      imageUrl: obj15,
      blockable: true
    },
    '16': {
      imageUrl: obj16,
      blockable: true
    },
    '17': {
      imageUrl: obj17,
      blockable: true
    },
    '18': {
      imageUrl: obj18,
      blockable: true
    },
    '19': {
      imageUrl: obj19,
      blockable: true
    },
    '20': {
      imageUrl: obj20,
      blockable: true
    },
    '21': {
      imageUrl: obj21,
      blockable: true
    },
    '22': {
      imageUrl: obj22,
      blockable: true
    },
    '23': {
      imageUrl: obj23,
      blockable: true
    },
    '24': {
      imageUrl: obj24,
      blockable: true
    },
    '25': {
      imageUrl: obj25,
      blockable: true
    },
    '26': {
      imageUrl: obj26,
      blockable: true
    },
    '27': {
      imageUrl: obj27,
      blockable: true
    },
    '28': {
      imageUrl: obj28,
      blockable: true
    },
    '29': {
      imageUrl: obj29,
      blockable: true
    }
  }
}
