import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'

/**
 * Generates keyframes for sprite row animation, cycling through frames in a single row.
 * @param frameWidth - Width of each frame in the sprite row.
 * @param numColumns - Number of frames in the row.
 * @returns Keyframes for the animation, moving horizontally across the frames.
 */
const spriteRowAnimation = (frameWidth: number, numColumns: number) => keyframes`
  0% { background-position-x: 0; }
  100% { background-position-x: ${-frameWidth * (numColumns - 1)}px; }
`

const SlideShowContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  margin: 0 auto;
`

const SlideWrapper = styled.div<{ hasContent: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasContent }) => (hasContent ? '0.5fr 1fr' : '1fr')};
  align-items: center;
  justify-items: center;
  gap: 16px;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`

/**
 * Styled component for a slide using a sprite sheet.
 * @param frameWidth - Width of each frame in the sprite sheet.
 * @param frameHeight - Height of each frame in the sprite sheet.
 * @param numColumns - Number of frames in a single row.
 * @param spriteRow - Row index to animate.
 * @param duration - Duration of one full animation cycle in seconds.
 * @param useSpriteSheet - Boolean flag indicating if a sprite sheet is used.
 * @param src - URL of the sprite sheet image.
 */
const Slide = styled.div<{
  frameWidth: number
  frameHeight: number
  numColumns: number
  spriteRow: number
  duration: number
  useSpriteSheet: boolean
  src: string
}>`
  /* Each frame's fixed width and height */
  width: 150px;
  height: 150px;

  /* Background image for the full spritesheet */
  background-image: url(${props => props.src});
  background-size: 720px auto;
  background-position: 0 -0px;
  background-position: 0 ${({ spriteRow }) => `-${spriteRow * 720}px`}; // Position to the specified row (background-size * spriteRow)
  /* Animation to cycle through frames horizontally */
  ${({ useSpriteSheet, frameWidth, numColumns, duration }) =>
    useSpriteSheet &&
    css`
      animation: ${spriteRowAnimation(frameWidth, numColumns)} ${duration}s steps(${numColumns}) infinite;
    `}
`

/**
 * Styled component for a slide without sprite sheet animation.
 * @param src - URL of the static image.
 */
const SlideNotSprite = styled.div<{
  src: string
}>`
  /* Background image for the full spritesheet */
  background-image: url(${props => props.src});
`

const SlideContent = styled.div`
  padding: 20px;
  color: #333;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  @media (max-width: 768px) {
    text-align: center;
  }
`
/**
 * Styled component for navigation buttons (left and right).
 * @param position - Position of the button, either 'left' or 'right'.
 */
const NavButton = styled.button<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  ${({ position }) => position}: 10px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  &:focus {
    outline: 2px solid #fff;
  }
`

/**
 * Interface representing the properties of a single slide.
 * @param imageUrl - URL of the image or sprite sheet.
 * @param useSpriteSheet - Boolean flag indicating if the slide uses a sprite sheet.
 * @param frameWidth - Width of each frame in the sprite sheet.
 * @param frameHeight - Height of each frame in the sprite sheet.
 * @param numColumns - Number of frames in the row.
 * @param duration - Duration of the animation cycle in seconds.
 * @param spriteRow - Row in the sprite sheet to animate.
 * @param content - Additional content displayed with the slide.
 */
export interface SlideProps {
  imageUrl: string
  useSpriteSheet?: boolean
  frameWidth?: number
  frameHeight?: number
  numColumns?: number
  duration?: number
  spriteRow?: number
  content?: React.ReactNode
}

interface SlideShowProps {
  slides: SlideProps[]
}

/**
 * SlideShow component displays an animated slideshow of images or sprite animations.
 * @param slides - Array of slide properties for each slide in the slideshow.
 * @returns The rendered slideshow component with navigation controls.
 */
export const SlideShow: React.FC<SlideShowProps> = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0)

  const nextSlide = () => {
    setSlideIndex(prevIndex => (prevIndex + 1) % slides.length)
  }

  const prevSlide = () => {
    setSlideIndex(prevIndex => (prevIndex - 1 + slides.length) % slides.length)
  }

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        nextSlide()
      } else if (event.key === 'ArrowLeft') {
        prevSlide()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <SlideShowContainer aria-live="polite">
      {slides.map((slide, index) => (
        <SlideWrapper
          key={index}
          hasContent={!!slide.content}
          style={{ display: index === slideIndex ? 'grid' : 'none' }}
        >
          {slide.useSpriteSheet &&
          slide.frameHeight &&
          slide.frameWidth &&
          slide.numColumns &&
          slide.duration &&
          slide.spriteRow !== undefined ? (
            <Slide
              src={slide.imageUrl}
              frameWidth={slide.frameWidth}
              frameHeight={slide.frameHeight}
              numColumns={slide.numColumns}
              duration={slide.duration}
              spriteRow={slide.spriteRow}
              useSpriteSheet={slide.useSpriteSheet}
            />
          ) : (
            <SlideNotSprite src={slide.imageUrl} />
          )}
          {slide.content && <SlideContent>{slide.content}</SlideContent>}
        </SlideWrapper>
      ))}

      <NavButton onClick={prevSlide} position="left" aria-label="Previous slide">
        ‹
      </NavButton>
      <NavButton onClick={nextSlide} position="right" aria-label="Next slide">
        ›
      </NavButton>
    </SlideShowContainer>
  )
}
