import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface TypewriterProps {
  text: string
  speed?: number
  onComplete?: () => void
}

const TextWriterContainer = styled.div`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.text1};
  font-size: 1.1em;
  line-height: 1.2em;
`

const Typewriter: React.FC<TypewriterProps> = ({ text, speed = 50, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [isTypingComplete, setIsTypingComplete] = useState(false)

  // Reset the typewriter state when the text changes
  useEffect(() => {
    setDisplayedText('')
    setIndex(0)
    setIsTypingComplete(false)
  }, [text]) // This ensures the typewriter restarts when new text is passed

  // Typewriter effect logic
  useEffect(() => {
    if (index < text.length && !isTypingComplete) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(prev => prev + text.charAt(index))
        setIndex(index + 1)
      }, speed)

      return () => clearTimeout(timeoutId)
    } else if (index === text.length) {
      setIsTypingComplete(true)
      if (onComplete) onComplete()
    }
    return
  }, [index, text, speed, isTypingComplete, onComplete])

  // Function to render the full text instantly
  const skipTyping = () => {
    setDisplayedText(text)
    setIsTypingComplete(true)
    if (onComplete) onComplete()
  }

  // Listen for Space key to skip typing
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.key == ' ' || e.code == 'Space' || e.keyCode == 32) && !isTypingComplete) {
        skipTyping()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isTypingComplete, text, onComplete])

  return (
    <TextWriterContainer>
      <span>{displayedText}</span>
      {!isTypingComplete && <span className="cursor">|</span>}
    </TextWriterContainer>
  )
}

export default Typewriter
