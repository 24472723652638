import { Interface, FunctionFragment } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addMulticallListeners,
  Call,
  removeMulticallListeners,
  parseCallKey,
  toCallKey,
  ListenerOptions
} from './actions'
import { useActiveWeb3React } from '../../hooks'
import { useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'

export interface Result extends ReadonlyArray<any> {
  readonly [key: string]: any
}

type MethodArg = string | number | BigNumber
type MethodArgs = Array<MethodArg | MethodArg[]>

type OptionalMethodInputs = Array<MethodArg | MethodArg[] | undefined> | undefined

function isMethodArg(x: unknown): x is MethodArg {
  return ['string', 'number'].indexOf(typeof x) !== -1
}

function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
  return (
    x === undefined ||
    (Array.isArray(x) && x.every(xi => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))))
  )
}

interface CallResult {
  readonly valid: boolean
  readonly data: string | undefined
  readonly blockNumber: number | undefined
}

const INVALID_RESULT: CallResult = {
  valid: false,
  blockNumber: undefined,
  data: undefined
}

// use this options object
export const NEVER_RELOAD: ListenerOptions = {
  blocksPerFetch: Infinity
}

// the lowest level call for subscribing to contract data
function useCallsData(calls: (Call | undefined)[], options?: ListenerOptions): CallResult[] {
  const { chainId } = useActiveWeb3React()
  const callResults = useSelector<AppState, AppState['multicall']['callResults']>(state => state.multicall.callResults)
  const dispatch = useDispatch<AppDispatch>()

  const serializedCallKeys: string = useMemo(
    () =>
      JSON.stringify(
        calls
          ?.filter((c): c is Call => Boolean(c))
          ?.map(toCallKey)
          ?.sort() ?? []
      ),
    [calls]
  )

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    const callKeys: string[] = JSON.parse(serializedCallKeys)
    if (!chainId || callKeys.length === 0) return undefined
    const calls = callKeys.map(key => parseCallKey(key))

    dispatch(
      addMulticallListeners({
        chainId,
        calls,
        options
      })
    )

    return () => {
      dispatch(
        removeMulticallListeners({
          chainId,
          calls,
          options
        })
      )
    }
  }, [chainId, dispatch, options, serializedCallKeys])

  return useMemo(
    () =>
      calls.map<CallResult>(call => {
        if (!chainId || !call) return INVALID_RESULT

        const result = callResults[chainId]?.[toCallKey(call)]
        let data
        if (result?.data && result?.data !== '0x') {
          data = result.data
        }

        return { valid: true, data, blockNumber: result?.blockNumber }
      }),
    [callResults, calls, chainId]
  )
}

export interface CallState {
  readonly valid: boolean
  // the result, or undefined if loading or errored/no data
  readonly result: Result | undefined
  // true if the result has never been fetched
  readonly loading: boolean
  // true if the result is not for the latest block
  readonly syncing: boolean
  // true if the call was made and is synced, but the return data is invalid
  readonly error: boolean
}

const INVALID_CALL_STATE: CallState = {
  valid: false,
  result: undefined,
  loading: false,
  syncing: false,
  error: false
}
const LOADING_CALL_STATE: CallState = {
  valid: true,
  result: undefined,
  loading: true,
  syncing: true,
  error: false
}

function toCallState(
  callResult: CallResult | undefined,
  contractInterface: Interface | undefined,
  fragment: FunctionFragment | undefined,
  latestBlockNumber: number | undefined
): CallState {
  if (!callResult) return INVALID_CALL_STATE
  const { valid, data, blockNumber } = callResult
  if (!valid) return INVALID_CALL_STATE
  if (valid && !blockNumber) return LOADING_CALL_STATE
  if (!contractInterface || !fragment || !latestBlockNumber) return LOADING_CALL_STATE
  const success = data && data.length > 2
  const syncing = (blockNumber ?? 0) < latestBlockNumber
  let result: Result | undefined = undefined
  if (success && data) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, data)
    } catch (error) {
      console.debug('Result data parsing failed', fragment, data)
      return {
        valid: true,
        loading: false,
        error: true,
        syncing,
        result
      }
    }
  }
  return {
    valid: true,
    loading: false,
    syncing,
    result: result,
    error: !success
  }
}

export function useSingleContractMultipleData(
  contract: Contract | null | undefined,
  methodName: string,
  callInputs: OptionalMethodInputs[],
  options?: ListenerOptions
): CallState[] {
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

  const calls = useMemo(
    () =>
      contract && fragment && isValidMethodArgs(callInputs)
        ? callInputs.map<Call>(inputs => {
            return {
              address: contract.address,
              callData: contract.interface.encodeFunctionData(fragment, inputs)
            }
          })
        : [],
    [callInputs, contract, fragment]
  )
  const results = useCallsData(calls, options)

  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return results.map(result => toCallState(result, contract?.interface, fragment, latestBlockNumber))
  }, [fragment, contract, results, latestBlockNumber])
}

export function useMultipleContractSingleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: OptionalMethodInputs,
  options?: ListenerOptions
): CallState[] {
  const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName])
  const callData: string | undefined = useMemo(
    () =>
      fragment && isValidMethodArgs(callInputs)
        ? contractInterface.encodeFunctionData(fragment, callInputs)
        : undefined,
    [callInputs, contractInterface, fragment]
  )

  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses.map<Call | undefined>(address => {
            return address && callData
              ? {
                  address,
                  callData
                }
              : undefined
          })
        : [],
    [addresses, callData, fragment]
  )

  const results = useCallsData(calls, options)

  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return results.map(result => toCallState(result, contractInterface, fragment, latestBlockNumber))
  }, [fragment, results, contractInterface, latestBlockNumber])
}

export function useSingleCallResult(
  contract: Contract | null | undefined,
  methodName: string,
  inputs?: OptionalMethodInputs,
  options?: ListenerOptions
): CallState {
  const fragment = useMemo(() => {
    const func = contract?.interface?.getFunction(methodName)
    // tip multi call is throwing errors Uncaught Error: types/values length mismatch (count={"types":1,"values":0}, value={"types":...) then uncomment all the [a] and figure out what the issue is.
    // console.debug(`Fragment for ${methodName}:`, func)
    return func
  }, [contract, methodName])

  const calls = useMemo<Call[]>(() => {
    if (contract && fragment && isValidMethodArgs(inputs)) {
      try {
        const callData = contract.interface.encodeFunctionData(fragment, inputs)
        // console.debug(`Call data for ${methodName} with inputs`, inputs, ':', callData)
        return [
          {
            address: contract.address,
            callData: callData
          }
        ]
      } catch (error) {
        console.error(`Error encoding call data for ${methodName}:`, error)
        return []
      }
    } else {
      return []
    }
  }, [contract, fragment, inputs])

  const result = useCallsData(calls, options)[0]
  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return toCallState(result, contract?.interface, fragment, latestBlockNumber)
  }, [result, contract, fragment, latestBlockNumber])
}

/**
 * A custom hook that retrieves data from multiple methods on a single contract.
 *
 * @param {string | undefined} address - The Ethereum address of the contract from which to fetch the data. If undefined, the hook does nothing.
 * @param {Interface} contractInterface - The ABI interface of the contract, used to encode and decode the function data.
 * @param {string[]} methodNames - An array of method names (functions) to call on the contract.
 * @param {OptionalMethodInputs[]} [callInputs] - An optional array of inputs for each method call. Each element in the array corresponds to the inputs for the method at the same index in `methodNames`.
 * @param {ListenerOptions} [options] - Optional listener options, such as polling settings, for the contract calls.
 *
 * @returns {CallState[]} An array of `CallState` objects, one for each method call, representing the status and result of each call (success, error, loading).
 *
 * @example
 * // Example usage:
 * const contractData = useMultipleMethodsSingleContractData(
 *   '0xContractAddress',
 *   contractInterface,
 *   ['balanceOf', 'totalSupply', 'symbol'],
 *   [['0xAddress1'], [], []],
 *   options
 * );
 *
 * // The returned array will contain the results of `balanceOf`, `totalSupply`, and `symbol` for the specified contract.
 */
export function useMultipleMethodsSingleContractData(
  address: string | undefined,
  contractInterface: Interface,
  methodNames: string[],
  callInputs?: OptionalMethodInputs[],
  options?: ListenerOptions
): CallState[] {
  // Get all the function fragments (method definitions), with error handling
  const fragments = useMemo(() => {
    return methodNames.map(name => {
      try {
        // Try to get the function fragment for the method name
        return contractInterface.getFunction(name)
      } catch (error) {
        // Log an error if the method name doesn't exist
        console.error(`Method "${name}" not found in the contract interface.`, error)
        return undefined // Return undefined for methods that do not exist
      }
    })
  }, [contractInterface, methodNames])

  // Encode call data for each method using its respective inputs
  const callData = useMemo(
    () =>
      fragments.map((fragment, index) => {
        try {
          return fragment && isValidMethodArgs(callInputs?.[index])
            ? contractInterface.encodeFunctionData(fragment, callInputs?.[index])
            : undefined
        } catch (error) {
          console.error(`Error encoding call data for method ${methodNames[index]}:`, error)
          return undefined
        }
      }),
    [callInputs, contractInterface, fragments, methodNames]
  )

  // Create a single array of calls (one contract, multiple call data)
  const calls = useMemo(
    () =>
      address && callData
        ? callData.map<Call | undefined>(data => (data ? { address, callData: data } : undefined))
        : [],
    [address, callData]
  )

  // Execute the calls using useCallsData hook
  const results = useCallsData(calls, options)

  // Get the latest block number for up-to-date info
  const latestBlockNumber = useBlockNumber()

  // Process the results into CallState objects with error handling
  return useMemo(() => {
    return results.map((result, index) => {
      // Return a default invalid state if fragment or result is missing
      return fragments[index]
        ? toCallState(result, contractInterface, fragments[index], latestBlockNumber)
        : INVALID_CALL_STATE
    })
  }, [fragments, results, contractInterface, latestBlockNumber])
}
