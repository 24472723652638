import { TransactionResponse } from '@ethersproject/providers'
import { TTwinVault } from 'constants/settings/types/TNpcs'
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback'
import { useTwinVaultContract } from 'hooks/useContract'
import { useState, useCallback } from 'react'
import { useTransactionAdder, useHasPendingTransactions } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils'
import { TuseTwinVaultState } from './useTwinVaultState'

export type TuseTransactionhandling = {
  onApprove: () => Promise<void>
  onStakeToken: () => Promise<void>
  onWithdrawOrHarvestToken: (harvestTokens?: boolean | undefined) => Promise<void>
  attempting: boolean
  hash: string | undefined
  failed: boolean
  errorToDisplay: string | undefined
  isPending: boolean
  setFailed: React.Dispatch<React.SetStateAction<boolean>>
}
export function useTransactionHandling(
  twinVaultState: TuseTwinVaultState,
  twinVault: TTwinVault
): TuseTransactionhandling {
  const [attempting, setAttempting] = useState(false)
  const [hash, setHash] = useState<string | undefined>()
  const [failed, setFailed] = useState(false)
  const [errorToDisplay, setErrorToDisplay] = useState<string | undefined>()

  const vault = useTwinVaultContract(twinVault)
  const addTransaction = useTransactionAdder()

  const isPending = useHasPendingTransactions()

  // Approval callback
  const [approval, approveCallback] = useApproveCallback(twinVaultState.state.parsedAmount, twinVault.contractAddress)

  // Stake Token Callback
  const onStakeTokenCallback = useCallback(async () => {
    setAttempting(true)
    const { tokenToStake, parsedAmount, account } = twinVaultState.state

    if (vault && parsedAmount && account) {
      if (approval === ApprovalState.APPROVED) {
        try {
          const formattedAmount = `0x${parsedAmount.raw.toString(16)}`
          const estimatedGas = await vault.estimateGas.deposit(formattedAmount)
          const response: TransactionResponse = await vault.deposit(formattedAmount, {
            from: account,
            gasLimit: calculateGasMargin(estimatedGas)
          })
          addTransaction(response, { summary: `Stake ${tokenToStake?.symbol}` })
          setHash(response.hash)
        } catch (error) {
          // if (error?.code === 4001) {
          //   throw new Error('Transaction rejected.')
          // }
          setFailed(true)
          setErrorToDisplay('Failed to stake token')
          setAttempting(false)
        }
      }
    }
    twinVaultState.setTypedValue('')
    setAttempting(false)
  }, [vault, approval, twinVaultState.state, addTransaction])

  // Withdraw/Harvest Token Callback
  const onWithdrawOrHarvestTokenCallback = useCallback(
    async (harvestTokens?: boolean) => {
      setAttempting(true)
      const { twinVaultInfo, account } = twinVaultState.state
      const userAmount = twinVaultInfo?.userInfo?.amount?.raw.toString()

      if (vault && harvestTokens && account) {
        try {
          const estimatedGas = await vault.estimateGas.withdraw('0')
          const res: TransactionResponse = await vault.withdraw('0', {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          addTransaction(res, { summary: 'Harvest Reward(s)' })
          setHash(res.hash)
        } catch (error) {
          setFailed(true)
          setErrorToDisplay('Failed to estimate gas for withdraw()')
        }
      }
      if (vault && userAmount && account && !harvestTokens) {
        try {
          const estimatedGas = await vault.estimateGas.withdraw(userAmount)
          const response: TransactionResponse = await vault.withdraw(userAmount, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          addTransaction(response, { summary: 'Withdraw Staked Token' })
          setHash(response.hash)
        } catch (error) {
          setFailed(true)
          setErrorToDisplay('Failed to withdraw token')
        }
      }
      setAttempting(false)
    },
    [vault, twinVaultState.state, addTransaction]
  )

  return {
    onApprove: approveCallback,
    onStakeToken: onStakeTokenCallback,
    onWithdrawOrHarvestToken: onWithdrawOrHarvestTokenCallback,
    attempting,
    hash,
    failed,
    errorToDisplay,
    isPending,
    setFailed
  }
}
