import { SwitchTownNetwork } from 'components/SwitchNetwork/SwitchTownNetwork'
import { MouseoverTooltip } from 'components/Tooltip'
import { BlockChainPollIntervalSettings } from 'constants/settings'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React from 'react'
import { Box } from 'rebass'
import { useBlockNumber } from 'state/application/hooks'
import {
  FloatingMenuContainer,
  FloatingMenuInner,
  DateTimeText,
  BlockNumberText,
  SeasonContainer,
  SeasonNetworkContainer,
  TownNetwork
} from './Menu.style'
import { getSeason } from './util'
import { MapIcon } from '../MapIcon/MapIcon'
import { SettingIcon } from '../SettingsMenuIcon/SettingIcon'
// import SoundEnableIcon from '../SoundMenuIcon/SoundIcon'

export const Menu: React.FC = () => {
  const blocknumber = useBlockNumber()
  const currentDate = new Date()
  const currentSeason = getSeason(currentDate)
  const { settings } = useAppConstant()

  return (
    <FloatingMenuContainer>
      <FloatingMenuInner>
        <MapIcon />
        <SettingIcon />
        {/* <SoundEnableIcon /> */}
        <SeasonNetworkContainer>
          <SeasonContainer>
            <Box mb={2}>
              <DateTimeText>{currentSeason}</DateTimeText>
            </Box>
            <MouseoverTooltip
              text={`Block number is updated ~ ${Math.round(
                BlockChainPollIntervalSettings[settings.blockchainSettings.chainId] / 1000
              )} seconds based on the latest mined block. Please note that due to network conditions and window activity, there may be a slight delay in updates.`}
            >
              <BlockNumberText>{blocknumber}</BlockNumberText>
            </MouseoverTooltip>
          </SeasonContainer>
          <TownNetwork>
            <SwitchTownNetwork />
          </TownNetwork>
        </SeasonNetworkContainer>
      </FloatingMenuInner>
    </FloatingMenuContainer>
  )
}
