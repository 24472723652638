import { useActiveWeb3React } from 'hooks'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useCallback, useState } from 'react'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getTokenFromDefaultTokenList } from './../components/CurrencyLogo/index'
import { Currency, Token } from '@/lib/sdk/index'

export default function useAddTokenToMetamask(
  currencyToAdd: Currency | undefined
): { addToken: () => void; success: boolean | undefined } {
  const { library, chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()

  const token: Token | undefined = wrappedCurrency(
    currencyToAdd,
    chainId,
    settings.blockchainSettings.currency,
    settings.wrappedCurrency
  )

  const [success, setSuccess] = useState<boolean | undefined>()

  const addToken = useCallback(() => {
    if (library && library.provider.isMetaMask && library.provider.request && token) {
      library.provider
        .request({
          method: 'wallet_watchAsset',
          params: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore // need this for incorrect ethers provider type
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: getTokenFromDefaultTokenList(token.address, token.chainId, token.symbol ?? 'null')
            }
          }
        })
        .then(success => {
          setSuccess(success)
        })
        .catch(() => setSuccess(false))
    } else {
      setSuccess(false)
    }
  }, [library, token])

  return { addToken, success }
}
