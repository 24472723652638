import { Contract } from '@ethersproject/contracts'
import { BigNumber } from 'ethers'

/**
 * This function fetches the current lockup percentage dynamically based on the schedule.
 * @param farmContract - The contract instance for the farm contract
 * @returns {Promise<number>} - The lockup percentage as a number
 */
export const getCurrentLockUpPercentage = async (farmContract: Contract): Promise<number> => {
  try {
    // Fetch the current block number
    const currentBlock = await farmContract.provider.getBlockNumber()

    // Call the smart contract to get the lockup percentage for the current block
    const lockupPercentage: BigNumber = await farmContract.getLockUpPercentage(currentBlock)

    // Convert lockup percentage to a normal number (percentage out of 100)
    const lockupPercent = lockupPercentage.toNumber()

    return lockupPercent // Return the lockup percentage
  } catch (error) {
    console.error('Error fetching lockup percentage:', error)
    return 999999 // Fallback to 0% lockup in case of an error
  }
}
