import { TDappData } from 'constants/settings/types/TNpcs'
import { useMemo } from 'react'
import useTotalTVL from './useTotalTVL'
import useVaultTokensTVL from './useVaultTVL'
import { StakingInfo } from '../state/stake/hooks'

export default function useTotalCombinedTVL(stakingInfos: StakingInfo[], dappData: TDappData): Record<string, any> {
  const totalStakingPoolTVL = useTotalTVL(stakingInfos)
  const totalVaultTokensTVL = useVaultTokensTVL(dappData)

  return useMemo(() => {
    return {
      stakingPoolTVL: totalStakingPoolTVL ? totalStakingPoolTVL : undefined,
      totalVaultTokensTVL: totalVaultTokensTVL ? totalVaultTokensTVL : undefined,
      totalCombinedTVL:
        totalStakingPoolTVL && totalVaultTokensTVL ? totalStakingPoolTVL.add(totalVaultTokensTVL) : undefined
    }
  }, [stakingInfos, totalStakingPoolTVL, totalVaultTokensTVL])
}
