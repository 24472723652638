import { Texture, Rectangle } from 'pixi.js'

// Function to load player textures from sprite sheet
export function loadPlayerTextures(
  resources: any,
  frameWidth: number,
  frameHeight: number,
  numColumns: number,
  numRows: number
): Texture[][] {
  const baseTexture = resources.playerSheet.texture
  const rows: Texture[][] = []

  for (let row = 0; row < numRows; row++) {
    const rowFrames: Texture[] = []
    for (let col = 0; col < numColumns; col++) {
      const x = col * frameWidth
      const y = row * frameHeight
      const frame = new Texture(baseTexture.baseTexture, new Rectangle(x, y, frameWidth, frameHeight))
      rowFrames.push(frame)
    }
    rows.push(rowFrames)
  }

  return rows
}
