import React, { useContext } from 'react'
import { AlertCircle, CheckCircle, X } from 'react-feather'
import { animated, useSpring } from 'react-spring'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { ExternalLink } from '../../theme/components'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

const AlertWrapper = styled(animated.div)`
    background-color: ${({ theme }) => theme.bg1};
    position: absolute;
    bottom: 100%;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1000;
    width: 100%;
    cursor: pointer;
    max-width: 500px;
    text-wrap: wrap;
}
`

const CloseIcon = styled(X)`
  cursor: pointer;
  color: ${({ theme }) => theme.text2};
`

export default function Alert({
  message,
  success,
  link,
  linkText,
  close,
  autoCloseAfterMs
}: {
  message: string
  success?: boolean
  link?: string
  linkText?: string
  close: () => void
  autoCloseAfterMs?: number
}) {
  const theme = useContext(ThemeContext)

  // Spring animation to handle fading out
  const fadeStyles = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 300 }, // Fade-in duration
    onRest: () => {
      if (autoCloseAfterMs) {
        setTimeout(() => {
          close() // Auto close the alert
        }, autoCloseAfterMs)
      }
    }
  })

  return (
    <AlertWrapper style={fadeStyles} onClick={close}>
      {/* Icon depending on success or failure */}
      {success ? <CheckCircle color={theme.green1} size={24} /> : <AlertCircle color={theme.red1} size={24} />}

      <AutoColumn gap="8px">
        {/* Message section */}
        <TYPE.body fontWeight={500}>{message}</TYPE.body>

        {/* Optional link section */}
        {link && linkText && <ExternalLink href={link}>{linkText}</ExternalLink>}
      </AutoColumn>

      {/* Close Icon */}
      <CloseIcon size={20} onClick={close} />
    </AlertWrapper>
  )
}
