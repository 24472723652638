import { animated } from 'react-spring'
import styled from 'styled-components'

export const MenuWrapper = styled.div<{ menuType: 'landing' | 'game' }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: ${({ menuType, theme }) => (menuType === 'landing' ? theme.bg1 : 'transparent')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    background-color: transparent;
    box-shadow: none;
  `};
  z-index: 500;
  box-shadow: ${({ menuType }) => (menuType === 'landing' ? '0 2px 5px rgba(0, 0, 0, 0.1)' : 'none')};
  height: 35px;
`

export const NavWalletLink = styled.div<{ menuType: 'landing' | 'game' }>`
  max-width: 300px;
  position: fixed;
  right: 25px;
  display: ${({ menuType }) => (menuType === 'landing' ? 'block' : 'none')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

export const NavMobileWalletLink = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
    width: 100vw;
  `};
`
export const ScrollableNavContainer = styled.div`
  flex: 1;
  overflow-x: auto;
  display: flex;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const NavLinks = styled.ul<{ menuType: 'landing' | 'game' }>`
  list-style: none;
  text-decoration: none;
  display: ${({ menuType }) => (menuType === 'landing' ? 'flex' : 'none')};
  gap: 1rem;
  white-space: nowrap;
  margin-bottom: -10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

export const NavLinkLi = styled.li`
  list-style: none;
  text-decoration: none;
  gap: 1rem;
  white-space: nowrap;
  margin-bottom: 20px;
`

export const NavLink = styled.a`
  list-style: none;
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  padding: 0.5rem 1rem;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

export const HamburgerIconContainer = styled.div`
  padding: 10px;
  cursor: pointer;
  position: fixed;
  top: 1.5rem;
  left: 1rem;
`

export const HamburgerIcon = styled.div<{ menuType: 'landing' | 'game' }>`
  width: 30px;
  height: 3px;
  background-color: ${({ theme }) => theme.text1};
  transition: all 0.3s ease;
  position: relative;
  display: ${({ menuType }) => (menuType === 'landing' ? 'none' : 'block')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;  /* Always show hamburger on mobile */
  `};
  &:before,
  &:after {
    content: '';
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.text1};
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
  }

  &:before {
    top: -10px;
  }

  &:after {
    top: 10px;
  }

  &.open {
    background-color: transparent;
    &:before {
      transform: rotate(45deg);
      top: 0;
    }
    &:after {
      transform: rotate(-45deg);
      top: 0;
    }
  }
`

export const MobileMenu = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: ${({ theme }) => theme.bg1};
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow-y: auto;
  z-index: 2000;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 90%;
  `};
`

export const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  color: ${({ theme }) => theme.text1};
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 1rem;
`
