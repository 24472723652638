import { AutoColumn } from 'components/Column'
import React from 'react'
import styled from 'styled-components'
import { SlideShow, SlideProps } from './SlideShow'
import { AnimatedText, Title } from '../LandingPage.styles'

export const TextContent = styled.div`
  text-align: justify;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 300;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: center;
  `};
`

interface PartnerSpotlightProps {
  dataSlides: SlideProps[]
}

export const PartnerSpotlight: React.FC<PartnerSpotlightProps> = ({ dataSlides }) => {
  return (
    <AutoColumn justify="center" gap="lg">
      <TextContent>
        <SlideShow slides={dataSlides} />
        <Title> Powering Your Journey Across the Chain</Title>
        <AnimatedText style={{ paddingBottom: '25px', paddingTop: '25px', maxWidth: '800px', margin: '0 auto' }}>
          Meet the Allies. In the vast realms of DeFi RPG, you don’t journey alone. As we push the boundaries of
          decentralized gaming, our partners become the cornerstone of innovation, community, and adventure. From the
          cosmic depths of BASE to the untamed waters of CRONOS, each partner stands as a unique pillar in our world.
        </AnimatedText>
        <AnimatedText style={{ paddingBottom: '25px', paddingTop: '25px', maxWidth: '800px', margin: '0 auto' }}>
          {`Our world is alive with unique characters, each representing different communities and projects in the DeFi
          ecosystem. On the left, explore our gallery of NPCs (Non-Player Characters), each custom-designed to reflect
          the partnerships and personalities that make DeFi RPG so vibrant. Your Bridge to Other Communities These NPCs
          are more than characters—they're official liaisons for their communities, bringing the flavor of each project
          directly into our universe. With customizable dialogue, animations, and tailored interactions, our NPCs go
          beyond traditional roles, making DeFi RPG an immersive experience for everyone. Features at Your Fingertips:
          Seamless Purchases: If an NPC’s project is listed on our DEX, you can buy tokens directly through their
          dialogue—just click, confirm, and you’re set. External Links: Visit project websites, social media, or
          community spaces, all from within the game. Evolving Dialogues: Our NPCs are continuously updated, providing
          relevant info and unique interactions that deepen your engagement with the DeFi world. Stay tuned as we add
          new NPCs and features, building a world where DeFi and gaming collide in ways never seen before!`}
        </AnimatedText>
      </TextContent>
    </AutoColumn>
  )
}
