import { Web3Provider } from '@ethersproject/providers'
import { BlockChainPollIntervalSettings, ChainId } from 'constants/settings'

export default function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, 'any')

  // Set a default polling interval initially
  library.pollingInterval = 15000

  // Update the polling interval asynchronously based on the chainId
  library.getNetwork().then(network => {
    library.pollingInterval = BlockChainPollIntervalSettings[network.chainId as ChainId]
  })

  return library
}
