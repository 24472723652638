// public/cronos/zones.ts
export const zones = {
  tileLayout: [
    [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 0, 0, 0, 0, 0],
    [5, 5, 5, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 1, 1, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
    [5, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  ],
  objects: [
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 3, y: 2 },
      transitional: false
    },

    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 2 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 2, y: 2 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 3, y: 1 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 4, y: 1 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 5, y: 1 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 0, y: 2 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 0, y: 3 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 0, y: 7 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 0, y: 8 },
      transitional: false
    },
    {
      objRef: '19',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 6, y: 1 },
      transitional: false
    },

    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 6, y: 2 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 2 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 2 },
      transitional: false
    },
    {
      objRef: '19',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 2 },
      transitional: false
    },
    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 3 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 12 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 13 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 14 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 15 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 16 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 17 },
      transitional: false
    },
    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 18 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 18 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 18 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 10, y: 18 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 11, y: 18 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 8 },
      transitional: false
    },
    {
      objRef: '18',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 9 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 9 },
      transitional: false
    },
    {
      objRef: '22',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 9 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 9 },
      transitional: false
    },
    {
      objRef: '17',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 0, y: 9 },
      transitional: false
    },

    {
      objRef: '9',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 0 },
      transitional: false
    },
    {
      objRef: '12',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 0 },
      transitional: false
    },
    {
      objRef: '14',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 1 },
      transitional: false
    },
    {
      objRef: '13',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 1 },
      transitional: false
    },
    {
      objRef: '10',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 10, y: 1 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 11, y: 3 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 3 },
      transitional: false
    },
    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 3 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 2 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 10, y: 3 },
      transitional: false
    },
    {
      objRef: '19',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 2 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 3 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 3 },
      transitional: false
    },
    {
      objRef: '19',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 16, y: 3 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 10, y: 7 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 11, y: 7 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 7 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 7 },
      transitional: false
    },
    {
      objRef: '17',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 8 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 8 },
      transitional: false
    },
    {
      objRef: '17',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 9 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 9 },
      transitional: false
    },
    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 16, y: 4 },
      transitional: false
    },
    {
      objRef: '4',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 1 },
      transitional: false
    },
    {
      objRef: '5',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 0 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 17, y: 4 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 18, y: 4 },
      transitional: false
    },
    {
      objRef: '23',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 19, y: 4 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 4 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 3 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 21, y: 3 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 21, y: 2 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 22, y: 2 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 22, y: 1 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 10 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 11 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 12 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 13 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 14 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 15 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 16 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 17 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 23, y: 1 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 23, y: 0 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 24, y: 0 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 10 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 11 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 12 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 13 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 13 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 14 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 15 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 15 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 16 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 18 },
      transitional: false
    },
    {
      objRef: '16',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 16 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 17 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 18 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 19 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 20 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 21 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 22 },
      transitional: false
    },
    {
      objRef: '25',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 1, y: 23 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 10, y: 16 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 14 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 12 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 2 },
      transitional: false
    },
    {
      objRef: '1',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 5 },
      transitional: false
    },
    {
      objRef: '2',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 25, y: 0 },
      transitional: false
    },
    {
      objRef: '2',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 3 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 21 },
      transitional: false
    },
    {
      objRef: '8',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 20 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 25, y: 22 },
      transitional: false
    },
    {
      objRef: '8',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 25, y: 21 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 26, y: 23 },
      transitional: false
    },
    {
      objRef: '8',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 26, y: 22 },
      transitional: false
    },
    {
      objRef: '7',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 22 },
      transitional: false
    },
    {
      objRef: '8',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 21 },
      transitional: false
    },
    {
      objRef: '29',
      objWidth: 32,
      objHeight: 64,
      startPosition: { x: 27, y: 0.025 },
      transitional: false
    },
    {
      objRef: '15',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 26, y: 0 },
      transitional: false
    },
    {
      objRef: '15',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 4 },
      transitional: false
    },
    {
      objRef: '15',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 11, y: 8 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 3, y: 22 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 4, y: 22 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 5, y: 22 },
      transitional: false
    },
    {
      objRef: '3',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 6, y: 22 },
      transitional: false
    },

    // Objects with objRef '4'
    {
      objRef: '4',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 3, y: 23 },
      transitional: false
    },
    {
      objRef: '4',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 4, y: 23 },
      transitional: false
    },
    {
      objRef: '4',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 5, y: 23 },
      transitional: false
    },
    {
      objRef: '4',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 6, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 8, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 10, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 11, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 12, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 13, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 14, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 15, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 16, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 17, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 18, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 19, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 21, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 22, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 23, y: 23 },
      transitional: false
    },
    {
      objRef: '27',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 24, y: 23 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 11 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 10 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 19 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 18 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 17 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 16 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 15 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 14 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 13 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 12 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 11 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 10 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 9 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 8 },
      transitional: false
    },
    {
      objRef: '21',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 7 },
      transitional: false
    },
    {
      objRef: '22',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 9, y: 7 },
      transitional: false
    },
    {
      objRef: '22',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 7, y: 23 },
      transitional: false
    },
    {
      objRef: '22',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 6 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 25, y: 23 },
      transitional: false
    },
    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 27, y: 20 },
      transitional: false
    },
    {
      objRef: '28',
      objWidth: 80,
      objHeight: 80,
      startPosition: { x: 4.5, y: 1 },
      transitional: false
    },
    {
      objRef: '22',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 19, y: 14 },
      transitional: false
    },
    {
      objRef: '26',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 14 },
      transitional: false
    },
    {
      objRef: '24',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 20, y: 15 },
      transitional: false
    },
    {
      objRef: '20',
      objWidth: 32,
      objHeight: 32,
      startPosition: { x: 19, y: 15 },
      transitional: false
    }
  ]
}
