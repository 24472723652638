import TWIN_VAULT_INTERFACE from 'constants/abis/twinVault'
import { TTwinVault } from 'constants/settings/types/TNpcs'
import { useActiveWeb3React } from 'hooks'
import { useTwinVaultContract } from 'hooks/useContract'
import isEqual from 'lodash.isequal'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useMultipleMethodsSingleContractData } from 'state/multicall/hooks'
import { extractData } from '../utils'
import { JSBI, CurrencyAmount } from '@/lib/sdk/index'

export interface TwinVaultUserInfo {
  amount: CurrencyAmount | null
  rewardDebt: CurrencyAmount | null
  rewardTwoDebt: CurrencyAmount | null
  rewardDebtAtBlock: number | null
  lastWithdrawBlock: number | null
  firstDepositBlock: number | null
  blockdelta: number | null
  lastDepositBlock: number | null
}
export interface TwinVaultPoolInfo {
  lpToken: string | null
  allocPoint: string | null
  lastRewardBlock: number | null
  accRewardTokenPerShare: string | null
  accRewardTokenTwoPerShare: string | null
}

export interface TwinVaultInfo {
  bonusEndBlock: string | null
  startBlock: string | null
  poolInfo: TwinVaultPoolInfo | null
  pendingReward: CurrencyAmount | null
  pendingRewardTwo: CurrencyAmount | null
  totalStakeTokenBalance: CurrencyAmount | null
  rewardToken?: string | undefined
  rewardTokenTwo?: string | undefined
  stakeToken?: string | undefined
  userInfo: TwinVaultUserInfo | null
  rewardPerBlock: CurrencyAmount | null
  rewardTwoPerBlock: CurrencyAmount | null
}

// Utility functions
const extractUserInfo = (data: any): TwinVaultUserInfo | null => {
  if (!data) return null
  const result = data.result
  return {
    amount: result?.amount ? CurrencyAmount.ether(JSBI.BigInt(result.amount)) : null,
    rewardDebt: result?.rewardDebt ? CurrencyAmount.ether(JSBI.BigInt(result.rewardDebt)) : null,
    rewardTwoDebt: result?.rewardTwoDebt ? CurrencyAmount.ether(JSBI.BigInt(result.rewardTwoDebt)) : null,
    rewardDebtAtBlock: result?.rewardDebtAtBlock || null,
    lastWithdrawBlock: result?.lastWithdrawBlock || null,
    firstDepositBlock: result?.firstDepositBlock || null,
    blockdelta: result?.blockdelta || null,
    lastDepositBlock: result?.lastDepositBlock || null
  }
}

const extractPoolInfo = (data: any): TwinVaultPoolInfo => ({
  lpToken: data?.lpToken || null,
  allocPoint: data?.allocPoint || null,
  lastRewardBlock: data?.lastRewardBlock || null,
  accRewardTokenPerShare: data?.accRewardTokenPerShare || null,
  accRewardTokenTwoPerShare: data?.accRewardTokenTwoPerShare || null
})

export function useTwinVaultInfo(twinVault: TTwinVault, currentBlock: number | undefined) {
  const { library, account } = useActiveWeb3React()
  const vault = useTwinVaultContract(twinVault)

  const [twinVaultInfo, setTwinVaultInfo] = useState<TwinVaultInfo>({
    bonusEndBlock: null,
    startBlock: null,
    poolInfo: null,
    pendingReward: null,
    pendingRewardTwo: null,
    totalStakeTokenBalance: null,
    rewardToken: undefined,
    rewardTokenTwo: undefined,
    stakeToken: undefined,
    userInfo: null,
    rewardPerBlock: null,
    rewardTwoPerBlock: null
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const methodParams = useMemo(
    () => ({
      bonusEndBlock: [],
      startBlock: [],
      pendingReward: account ? [account] : [],
      pendingRewardTwo: account ? [account] : [],
      totalStakeTokenBalance: [],
      poolInfo: [],
      rewardToken: [],
      rewardTokenTwo: [],
      stakeToken: [],
      userInfo: account ? [account] : [],
      rewardPerBlock: [],
      rewardTwoPerBlock: []
    }),
    [account]
  )

  const contractData = useMultipleMethodsSingleContractData(
    vault?.address,
    TWIN_VAULT_INTERFACE,
    Object.keys(methodParams),
    Object.values(methodParams)
  )

  const updateTwinVaultInfo = useCallback((newData: TwinVaultInfo) => {
    setTwinVaultInfo(prevData => (isEqual(prevData, newData) ? prevData : newData))
  }, [])

  const fetchTwinVaultInfo = useCallback(async () => {
    if (contractData && vault && library) {
      try {
        setLoading(true)
        setError(null)

        // Extract individual data points
        const bonusEndBlock = extractData<string>(contractData[0])
        const startBlock = extractData<string>(contractData[1])
        const pendingReward = extractData<string>(contractData[2])
        const pendingRewardTwo = extractData<string>(contractData[3])
        const totalStakeTokenBalance = extractData<string>(contractData[4])
        const poolInfo = extractPoolInfo(contractData[5])
        const rewardToken = extractData<string>(contractData[6])
        const rewardTokenTwo = extractData<string>(contractData[7])
        const stakeToken = extractData<string>(contractData[8])
        const userInfo = extractUserInfo(contractData[9])
        const rewardPerBlock = extractData<string>(contractData[10])
        const rewardTwoPerBlock = extractData<string>(contractData[11])

        const newTwinVaultInfo: TwinVaultInfo = {
          bonusEndBlock: bonusEndBlock ? bonusEndBlock.toString() : null,
          startBlock: startBlock ? startBlock.toString() : null,
          pendingReward: pendingReward ? CurrencyAmount.ether(JSBI.BigInt(pendingReward)) : null,
          pendingRewardTwo: pendingRewardTwo ? CurrencyAmount.ether(JSBI.BigInt(pendingRewardTwo)) : null,
          totalStakeTokenBalance: totalStakeTokenBalance
            ? CurrencyAmount.ether(JSBI.BigInt(totalStakeTokenBalance))
            : null,
          poolInfo: poolInfo,
          rewardToken: rewardToken || undefined,
          rewardTokenTwo: rewardTokenTwo || undefined,
          stakeToken: stakeToken || undefined,
          userInfo: userInfo,
          rewardPerBlock: rewardPerBlock ? CurrencyAmount.ether(JSBI.BigInt(rewardPerBlock)) : null,
          rewardTwoPerBlock: rewardTwoPerBlock ? CurrencyAmount.ether(JSBI.BigInt(rewardTwoPerBlock)) : null
        }

        updateTwinVaultInfo(newTwinVaultInfo)
        setLoading(false)
      } catch (initialError) {
        console.warn('Initial call failed:', initialError ?? 'Unknown error')
        setLoading(false) // Ensure loading is set to false
      }
    } else {
      setLoading(false)
      setError('Contract data is not available')
    }
  }, [contractData, vault, library, currentBlock, updateTwinVaultInfo])

  useEffect(() => {
    const fetchData = async () => {
      await fetchTwinVaultInfo()
    }

    if (vault?.address) {
      fetchData()
    } else {
      setError('No twin vault address')
    }
  }, [vault?.address, fetchTwinVaultInfo])

  return {
    twinVaultInfo,
    loading,
    error
  }
}
