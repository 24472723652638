import { useActiveWeb3React } from 'hooks'
import { AppSettingsClient } from 'interfaces/AppSettingsClient'

export const useAppConstant = () => {
  const { chainId } = useActiveWeb3React()
  const appSettingsClient = new AppSettingsClient(Number(chainId?.toFixed()), false)
  const { settings, theme, menuOptions, npcs, tiles, player, zones, zoneObjects } = appSettingsClient.getSettings()

  return {
    settings,
    theme,
    menuOptions,
    npcs,
    chainId,
    tiles,
    player,
    zones,
    zoneObjects,
    getAllSettings: appSettingsClient.getAllSettings
  }
}
