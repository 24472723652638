import { extras, Texture } from 'pixi.js'
import { EDirection } from '../addPlayer'
import { updateIdleAnimation } from './updateIdleAnimation'

// Function to update the player's movement based on key inputs
export function updateMovement(
  animatedSprite: extras.AnimatedSprite,
  keys: { [key: string]: boolean },
  lastDirection: EDirection,
  rows: Texture[][],
  speed: number
): EDirection {
  const currentWidth = animatedSprite.width
  const currentHeight = animatedSprite.height
  let isMoving = false

  const isMovementRight = keys['KeyD'] || keys['ArrowRight']
  const isMovementLeft = keys['KeyA'] || keys['ArrowLeft']
  const isMovementUp = keys['KeyW'] || keys['ArrowUp']
  const isMovementDown = keys['KeyS'] || keys['ArrowDown']

  const movementRightRow = rows[6]
  const movementLeftRow = rows[5]
  const movementUpRow = rows[7]
  const movementDownRow = rows[4]

  if (isMovementRight) {
    if (lastDirection !== EDirection.RIGHT || animatedSprite.textures !== movementRightRow) {
      animatedSprite.textures = movementRightRow
      animatedSprite.play()
    }
    lastDirection = EDirection.RIGHT
    isMoving = true
  } else if (isMovementLeft) {
    if (lastDirection !== EDirection.LEFT || animatedSprite.textures !== movementLeftRow) {
      animatedSprite.textures = movementLeftRow
      animatedSprite.play()
    }
    lastDirection = EDirection.LEFT
    isMoving = true
  } else if (isMovementUp) {
    if (lastDirection !== EDirection.UP || animatedSprite.textures !== movementUpRow) {
      animatedSprite.textures = movementUpRow
      animatedSprite.play()
    }
    lastDirection = EDirection.UP
    isMoving = true
  } else if (isMovementDown) {
    if (lastDirection !== EDirection.DOWN || animatedSprite.textures !== movementDownRow) {
      animatedSprite.textures = movementDownRow
      animatedSprite.play()
    }
    lastDirection = EDirection.DOWN
    isMoving = true
  }

  if (!isMoving) {
    updateIdleAnimation(animatedSprite, lastDirection, rows)
  }

  animatedSprite.width = currentWidth
  animatedSprite.height = currentHeight

  return lastDirection
}
