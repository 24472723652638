import { ButtonConfirmed } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { TTwinVault } from 'constants/settings/types/TNpcs'
import React, { useState, useEffect } from 'react'
import { TYPE } from 'theme'
import { useWithdrawFee } from '../hooks/useWithdrawFee'
import { CurrencyAmount, Token, Fraction } from '@/lib/sdk/index'

export type TRewardDisplay = {
  rewardBalance: CurrencyAmount | null
  rewardBalanceTwo: CurrencyAmount | null
  tokenRewardOne: Token | undefined
  tokenRewardTwo: Token | undefined
  tokenStaked: Token | undefined
  stakedAmountDeposited: CurrencyAmount | null
  apr: Fraction | undefined
  totalPoolDeposited: CurrencyAmount | null
  isClosed: boolean
  onHarvest: (harvest?: boolean) => void
  twinVaultContract: TTwinVault
  currentBlock: number | undefined
  account: string | null | undefined
}
export function RewardDisplay({
  rewardBalance,
  rewardBalanceTwo,
  tokenRewardOne,
  tokenRewardTwo,
  tokenStaked,
  stakedAmountDeposited,
  totalPoolDeposited,
  isClosed,
  onHarvest,
  apr,
  currentBlock,
  account,
  twinVaultContract
}: TRewardDisplay) {
  const { withdrawPercentage, loading } = useWithdrawFee(twinVaultContract, account, currentBlock)

  // const vault = useTwinVaultContract(twinVaultContract)
  // const { account } = useActiveWeb3React()
  // const currentBlock = useBlockNumber()
  // const calculateWithdrawFee = useSingleCallResult(
  //   vault,
  //   'calculateWithdrawFee',
  //   account && currentBlock ? [account, currentBlock] : []
  // )
  // const withdrawResult = calculateWithdrawFee.result
  // const [userFee, devFee] = withdrawResult && Array.isArray(withdrawResult) ? withdrawResult : [null, null]
  // const stageResult = stages(devFee, userFee)
  // const stageNum = Number(stageResult.toString())
  // const devFeeNum = devFee ? Number(JSBI.BigInt(devFee).toString()) : 0
  // const withdrawCalcResult = (devFeeNum / stageNum) * 100

  const [displayRewardBalanceOne, setDisplayRewardBalanceOne] = useState<CurrencyAmount>()
  const [displayRewardBalanceTwo, setDisplayRewardBalanceTwo] = useState<CurrencyAmount>()

  useEffect(() => {
    if (rewardBalance?.greaterThan('0')) {
      setDisplayRewardBalanceOne(rewardBalance)
    }
  }, [rewardBalance])

  useEffect(() => {
    if (rewardBalanceTwo?.greaterThan('0')) {
      setDisplayRewardBalanceTwo(rewardBalanceTwo)
    }
  }, [rewardBalanceTwo])

  if (
    !tokenRewardTwo &&
    !tokenRewardOne &&
    !rewardBalance &&
    !rewardBalanceTwo &&
    !displayRewardBalanceOne &&
    !displayRewardBalanceTwo
  ) {
    return <></>
  }
  return (
    <AutoColumn gap={'md'}>
      <RowBetween>
        <TYPE.body>Rewards:</TYPE.body>
        <TYPE.body>
          {`${displayRewardBalanceOne?.toSignificant(6) ?? '-'} ${tokenRewardOne?.symbol} | 
        ${displayRewardBalanceTwo?.toSignificant(6) ?? '-'} ${tokenRewardTwo?.symbol}`}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Withdraw Fee:</TYPE.body>
        <TYPE.body>{isClosed ? '0%' : !loading ? `${withdrawPercentage?.toFixed(2)}%` : 'Calculating...'}</TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Your Staked:</TYPE.body>
        <TYPE.body>
          {stakedAmountDeposited?.toSignificant(6) ?? '-'} {tokenStaked?.symbol}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Your Pool Weight:</TYPE.body>
        <TYPE.body>
          {stakedAmountDeposited && totalPoolDeposited && totalPoolDeposited.greaterThan('0')
            ? `${stakedAmountDeposited
                .divide(totalPoolDeposited)
                .multiply('100')
                .toSignificant(6)}%`
            : 'Calculating...'}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Vault Total Staked:</TYPE.body>
        <TYPE.body>
          {totalPoolDeposited?.toSignificant(6) ?? '-'} {tokenStaked?.symbol}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Vault APR:</TYPE.body>
        <TYPE.body>
          {apr && apr.greaterThan('0')
            ? `${apr.multiply('100').toSignificant(6, { groupSeparator: ',' })}%`
            : 'To be determined'}
        </TYPE.body>
      </RowBetween>
      {/* Claim/Harvest Button */}
      {(displayRewardBalanceOne || displayRewardBalanceTwo) && (
        <RowBetween>
          <ButtonConfirmed onClick={() => onHarvest(true)}>Harvest</ButtonConfirmed>
        </RowBetween>
      )}
    </AutoColumn>
  )
}
