import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { LoadingView, SubmittedView } from 'components/ModalViews'
import { RowBetween } from 'components/Row'
import TransactionErrorModal from 'components/TransactionErrorModal/TransactionErrorModal'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React, { useCallback, useState, useMemo } from 'react'
import { AlertCircle } from 'react-feather'
import { useBlockNumber } from 'state/application/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { TYPE, CustomLightSpinner, CloseIcon } from 'theme'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { RewardDisplay } from './components/RewardDisplay'
import { TransactionButtons } from './components/TransactionButtons'
import { useAprHandling, TuseAprHandling } from './hooks/useAprHandling'
import { ContentWrapper } from './TwinVault.style'
import { useTwinVaultContext } from './TwinVaultProvider'
import Circle from '../../assets/svg/blue-loader.svg'
import { CurrencyAmount } from '@/lib/sdk/index'

export default function TwinVault({ onDismiss }: { onDismiss: () => void }) {
  const currentBlock = useBlockNumber()
  const { settings } = useAppConstant()
  const { twinVaultState, twinVault, transactionHandling, dappData } = useTwinVaultContext()
  const {
    onApprove,
    onStakeToken,
    onWithdrawOrHarvestToken,
    isPending,
    hash,
    attempting,
    failed,
    errorToDisplay,
    setFailed
  } = transactionHandling
  const [transactionModal, setTransactionModal] = useState<boolean>(false)
  const userTotalStakeTokenBalance = useTokenBalance(
    twinVaultState.state.account ?? undefined,
    twinVaultState.state.tokenToStake
  )
  const apr = useAprHandling({
    dappData: dappData,
    tokenToStake: twinVaultState.state.tokenToStake,
    tokenRewardOne: twinVaultState.state.tokenRewardOne,
    tokenRewardTwo: twinVaultState.state.tokenRewardTwo,
    rewardPerBlock: twinVaultState.state.twinVaultInfo.rewardPerBlock,
    rewardTwoPerBlock: twinVaultState.state.twinVaultInfo.rewardTwoPerBlock,
    totalStakeTokenBalance: twinVaultState.state.twinVaultInfo.totalStakeTokenBalance
  } as TuseAprHandling)

  const aprMemo = useMemo(() => apr, [apr])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    settings.blockchainSettings.currency,
    userTotalStakeTokenBalance
  )
  const atMaxAmountInput = Boolean(maxAmountInput && twinVaultState.state.parsedAmount?.equalTo(maxAmountInput))
  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      twinVaultState.handleInputChange(maxAmountInput.toExact())
    }
  }, [maxAmountInput, twinVaultState])

  const onCloseTransactionModal = () => {
    setTransactionModal(false)
    setFailed(false)
  }

  const handleStake = useCallback(() => {
    onStakeToken()
    setTransactionModal(true)
  }, [onStakeToken])

  const handleWithdrawAndHarvest = useCallback(
    (onlyHarvest?: boolean) => {
      onWithdrawOrHarvestToken(onlyHarvest)
      setTransactionModal(true)
    },
    [onWithdrawOrHarvestToken]
  )

  // Loading view for token data
  if (twinVaultState.state.loading || !twinVaultState.state.tokenToStake) {
    return (
      <ContentWrapper gap="lg">
        <AutoColumn gap="12px" justify="center">
          <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
        </AutoColumn>
      </ContentWrapper>
    )
  }

  // Error handling if token data or contract is missing
  if (!twinVault && !twinVaultState.state.loading) {
    return <div>Something is wrong. Please contact support.</div>
  }

  const hasPoolEnded = !!(
    twinVaultState.state.twinVaultInfo &&
    currentBlock &&
    Number(twinVaultState.state.twinVaultInfo.bonusEndBlock) <= currentBlock
  )

  return (
    <>
      {!attempting && !failed && !isPending && (
        <ContentWrapper gap="lg">
          {/* Modal header */}
          <RowBetween>
            <TYPE.mediumHeader>Stake {twinVaultState.state.tokenToStake.symbol}</TYPE.mediumHeader>
            <div>
              <CloseIcon onClick={onDismiss} onTouchStart={onDismiss} />
            </div>
          </RowBetween>
          <RowBetween>
            <TYPE.body>
              <AlertCircle size={16} /> When you stake additional tokens or withdraw, your rewards will automatically be
              claimed.
            </TYPE.body>
          </RowBetween>

          {/* Currency Input Panel */}
          <CurrencyInputPanel
            value={twinVaultState.state.typedValue}
            onUserInput={twinVaultState.handleInputChange}
            currency={twinVaultState.state.tokenToStake}
            pair={null}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={'Available to stake: '}
            id={`twinVault-input-${twinVault?.contractAddress}`}
            dappData={dappData}
            onMax={handleMaxInput}
            showMaxButton={!atMaxAmountInput}
          />

          {/* Transaction Buttons */}
          <TransactionButtons
            onApprove={onApprove}
            onStake={handleStake}
            onWithdraw={handleWithdrawAndHarvest}
            approval={twinVaultState.state.approval}
            error={twinVaultState.state.error}
            userDepositedTokenBalance={twinVaultState.state.userDepositedTokenBalance}
            hasPoolEnded={hasPoolEnded}
          />

          {/* Reward Display */}
          <RewardDisplay
            rewardBalance={twinVaultState.state.rewardBalance}
            rewardBalanceTwo={twinVaultState.state.rewardBalanceTwo}
            tokenRewardOne={twinVaultState.state.tokenRewardOne}
            tokenRewardTwo={twinVaultState.state.tokenRewardTwo}
            tokenStaked={twinVaultState.state.tokenToStake}
            stakedAmountDeposited={twinVaultState.state.stakeAmount}
            totalPoolDeposited={twinVaultState.state.twinVaultInfo.totalStakeTokenBalance}
            isClosed={hasPoolEnded}
            apr={aprMemo}
            onHarvest={handleWithdrawAndHarvest}
            twinVaultContract={twinVault}
            account={twinVaultState.state.account}
            currentBlock={currentBlock}
          />
        </ContentWrapper>
      )}
      {/* Pending Transaction View */}
      {isPending && (
        <LoadingView onDismiss={onCloseTransactionModal}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Pending Transaction...</TYPE.largeHeader>
          </AutoColumn>
        </LoadingView>
      )}

      {/* Attempting Stake Transaction */}
      {attempting && !hash && !failed && !isPending && transactionModal && (
        <LoadingView onDismiss={onCloseTransactionModal}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Attempting transaction(s)...</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {twinVaultState.state.parsedAmount?.toSignificant(4)} {twinVaultState.state.tokenToStake.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}

      {/* Submitted Transaction View */}
      {attempting && hash && !failed && !isPending && transactionModal && (
        <SubmittedView onDismiss={onCloseTransactionModal} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Submitting Transaction</TYPE.largeHeader>
          </AutoColumn>
        </SubmittedView>
      )}

      {/* Error Transaction View */}
      {!attempting && !hash && failed && !isPending && transactionModal && (
        <TransactionErrorModal errorToDisplay={errorToDisplay ?? 'error'} wrappedOnDismiss={onCloseTransactionModal} />
      )}
    </>
  )
}
