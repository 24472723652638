import DialogueModal from 'components/Dialogue/DialogueModal'
import GOVERNANCE_TOKEN_INTERFACE from 'constants/abis/governanceToken'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useGovTokenSupply } from 'data/TotalSupply'
import { useStakingRewardsInfo } from 'hooks/useStakingRewardsInfo'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { useState, useEffect } from 'react'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components'
import EarnArchived from './Archived'
import { ButtonPrimary, CustomButtonWhite } from '../../components/Button'
import { OutlineCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CombinedTVL from '../../components/CombinedTVL'
import AwaitingRewards from '../../components/farms/AwaitingRewards'
import ClaimAllRewardsModal from '../../components/farms/ClaimAllRewardsModal'
import PoolCard from '../../components/farms/PoolCard'
import { CardSection, ExtraDataCard, CardNoise, CardBGImage } from '../../components/farms/styled'
import Loader from '../../components/Loader'
import { AutoRow, RowBetween } from '../../components/Row'
import { useActiveWeb3React } from '../../hooks'
import useBaseStakingRewardsEmission from '../../hooks/useBaseStakingRewardsEmission'
import useCalculateStakingInfoMembers from '../../hooks/useCalculateStakingInfoMembers'
import useFilterStakingInfos from '../../hooks/useFilterStakingInfos'
import useTotalCombinedTVL from '../../hooks/useTotalCombinedTVL'
import { StakingInfo, useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import { JSBI, TokenAmount } from '@/lib/sdk/index'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  border-radius: 20px;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr); /* 2-wide grid */
  gap: 15px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`
interface FarmsProps {
  dappData: TDappData
}

export default function Farms({ dappData }: FarmsProps) {
  const [stakingInfos, setStakingInfos] = useState<StakingInfo[]>([])
  const { chainId, account } = useActiveWeb3React()
  const { stakingRewardsInfo, fetchStakingRewards } = useStakingRewardsInfo(dappData)

  // Automatically fetch the staking rewards information when the component mounts
  useEffect(() => {
    fetchStakingRewards()
  }, [fetchStakingRewards])

  const { settings } = useAppSettings()
  const blockchainSettings = chainId ? settings.blockchainSettings : undefined
  const activePoolsOnly = true
  const pulledStakeInfos = useStakingInfo(undefined, dappData, stakingRewardsInfo)

  const govTokenLockedBalance: TokenAmount | undefined = useTokenBalance(
    account ?? undefined,
    dappData.governanceToken,
    'lockOf',
    GOVERNANCE_TOKEN_INTERFACE
  )

  const totalLockedSupply: TokenAmount | undefined = useGovTokenSupply('lockedSupply', dappData)

  useEffect(() => {
    if (pulledStakeInfos && pulledStakeInfos.length > 0) {
      // Check if pulledStakeInfos is different from the current stakingInfos
      const areInfosDifferent = JSON.stringify(pulledStakeInfos) !== JSON.stringify(stakingInfos)

      if (areInfosDifferent) {
        setStakingInfos(pulledStakeInfos)
      }
    }
  }, [pulledStakeInfos, stakingInfos])

  const [showClaimRewardsModal, setShowClaimRewardsModal] = useState(false)
  const [showArchivedPoolsModal, setShowArchivedPoolsModal] = useState(false)

  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (stakingRewardsInfo?.length ?? 0) > 0)

  const baseEmissions = useBaseStakingRewardsEmission(dappData)
  const blocksPerDay = blockchainSettings?.blockTime ? (60 / blockchainSettings.blockTime) * 60 * 24 : 0
  const emissionsPerDay =
    baseEmissions && blockchainSettings ? baseEmissions.multiply(JSBI.BigInt(Math.round(blocksPerDay))) : undefined

  const activeStakingInfos = useFilterStakingInfos(stakingInfos, activePoolsOnly)
  const inactiveStakingInfos = useFilterStakingInfos(stakingInfos, false)
  const stakingInfoStats = useCalculateStakingInfoMembers(chainId, stakingRewardsInfo)

  const hasArchivedStakingPools =
    (stakingInfoStats?.inactive && stakingInfoStats?.inactive > 0) || inactiveStakingInfos?.length > 0

  const stakingInfosWithRewards = useFilterStakingInfos(activeStakingInfos, true, true)

  const TVLs = useTotalCombinedTVL(activeStakingInfos, dappData)

  if (showArchivedPoolsModal) {
    return (
      <DialogueModal isOpen={showArchivedPoolsModal} onClose={() => setShowArchivedPoolsModal(false)}>
        <EarnArchived dappData={dappData} stakingInfo={stakingRewardsInfo} />
      </DialogueModal>
    )
  }
  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <ExtraDataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>{dappData.governanceToken?.symbol} Farming Pools</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>Your locked rewards:</TYPE.white>
                <TYPE.white fontSize={14}>
                  {govTokenLockedBalance?.greaterThan('0')
                    ? govTokenLockedBalance?.toFixed(6, { groupSeparator: ',' })
                    : '0.00'}{' '}
                  {dappData.governanceToken?.symbol}
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>Total locked supply:</TYPE.white>
                <TYPE.white fontSize={14}>
                  {totalLockedSupply?.toFixed(2, { groupSeparator: ',' }) ?? '-'} {dappData.governanceToken?.symbol}
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit Liquidity Pool tokens to earn {dappData.governanceToken?.symbol}
                </TYPE.white>
              </RowBetween>{' '}
              {stakingInfosWithRewards?.length > 0 && (
                <AutoRow justify="center">
                  <CustomButtonWhite
                    padding="8px"
                    borderRadius="8px"
                    width="7em"
                    onClick={() => setShowClaimRewardsModal(true)}
                  >
                    Claim all ({stakingInfosWithRewards.length})
                  </CustomButtonWhite>
                </AutoRow>
              )}
              {hasArchivedStakingPools && (
                <RowBetween>
                  <ButtonPrimary padding="8px" borderRadius="8px" onClick={() => setShowArchivedPoolsModal(true)}>
                    Go to Archived Pools
                  </ButtonPrimary>
                </RowBetween>
              )}
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </ExtraDataCard>
      </TopSection>

      <ClaimAllRewardsModal
        isOpen={showClaimRewardsModal}
        onDismiss={() => setShowClaimRewardsModal(false)}
        stakingInfos={stakingInfosWithRewards}
        dappData={dappData}
      />

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Farms</TYPE.mediumHeader>
          {TVLs?.stakingPoolTVL?.greaterThan('0') && (
            <TYPE.black style={{ marginTop: '0.5rem' }}>
              <CombinedTVL dappData={dappData} />
            </TYPE.black>
          )}
        </DataRow>

        <AwaitingRewards />

        <PoolSection>
          {account && stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : account && !stakingRewardsExist ? (
            <OutlineCard>No active pools</OutlineCard>
          ) : account && stakingInfos?.length !== 0 && !activeStakingInfos ? (
            <OutlineCard>No active pools</OutlineCard>
          ) : !account ? (
            <OutlineCard>Please connect your wallet to see available pools</OutlineCard>
          ) : (
            activeStakingInfos?.map((stakingInfo, index) => {
              return (
                <PoolCard dappData={dappData} key={index} stakingInfo={stakingInfo} isArchived={stakingInfo.active} />
              )
            })
          )}
        </PoolSection>

        {stakingRewardsExist && baseEmissions && (
          <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
            The base emission rate is currently <b>{baseEmissions.toSignificant(4, { groupSeparator: ',' })}</b>{' '}
            {dappData.governanceToken?.symbol} per block.
            <br />
            <b>{emissionsPerDay?.toSignificant(4, { groupSeparator: ',' })}</b> {dappData.governanceToken?.symbol} will
            be minted every day given the current emission schedule.
            <br />
            <br />
            <TYPE.small style={{ textAlign: 'center' }} fontSize={10}>
              * = The APR is calculated using a very simplified formula, it might not fully represent the exact APR
              <br />
              when factoring in the dynamic emission schedule and the locked/unlocked rewards vesting system.
            </TYPE.small>
          </TYPE.main>
        )}
      </AutoColumn>
    </PageWrapper>
  )
}
