import { GlobalMenu } from 'components/GlobalMenu/GlobalMenu'
import { GlobalMenuProvider } from 'components/GlobalMenu/GlobalMenuProvider'
import { Game } from 'game/game'
import { HeroBanner } from 'game/pages/LandingPage/LandingPage'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { Suspense, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

// todo uncomment out the font and fix the css
const GlobalStyle = createGlobalStyle`
  // @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

  /* Global scrollbar styling */
  ::-webkit-scrollbar {
    width: 2px; 
  }

  ::-webkit-scrollbar-track {
    background: #1a1a1a; 
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #444;
  }

  /* Firefox scrollbar customization */
  body {
    scrollbar-width: thin;
    scrollbar-color: #333 #1a1a1a; /* Thumb and track colors */
    margin: 0px;
  }
`

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
`

export interface GameParams {
  npcId?: string
}

export default function GameApp() {
  const { settings } = useAppSettings()
  const platformName = settings.platformName

  useEffect(() => {
    document.title = platformName
  }, [platformName])

  return (
    <GlobalMenuProvider>
      <GlobalStyle />
      <Suspense fallback={null}>
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <BodyWrapper>
            <GlobalMenu menuType="game" />

            <Web3ReactManager>
              <Switch>
                {/* Redirect "/" to "/<settings.domain>" */}
                <Route exact path="/">
                  <HeroBanner />
                </Route>

                {/* Game routes */}
                <Route exact path={`/${settings.domain}`} component={Game} />
                <Route exact path={`/${settings.domain}/:npcId`} component={Game} />

                {/* Catch-all route for 404 and redirect to "/sea" */}
                <Route path="*">
                  <Redirect to={`/${settings.domain}`} />
                </Route>
              </Switch>
            </Web3ReactManager>
          </BodyWrapper>
        </AppWrapper>
      </Suspense>
    </GlobalMenuProvider>
  )
}
