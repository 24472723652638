import { RowBetween } from 'components/Row'
import React from 'react'
import styled from 'styled-components'
import { TYPE, CloseIcon } from 'theme'

const ContentWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.primary2};
  padding: 16px;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.primary2};
`

interface TransactionErrorModalProps {
  errorToDisplay: string
  wrappedOnDismiss: () => void
}

const TransactionErrorModal: React.FC<TransactionErrorModalProps> = ({ errorToDisplay, wrappedOnDismiss }) => {
  return (
    <ContentWrapper>
      <RowBetween style={{ marginBottom: '20px' }}>
        <TYPE.mediumHeader>Error!</TYPE.mediumHeader>
        <CloseIcon onClick={wrappedOnDismiss} />
      </RowBetween>
      <TYPE.subHeader style={{ textAlign: 'center' }}>
        Your transaction couldn&apos;t be submitted.
        <br />
        <br />
        {errorToDisplay}
        <br />
        <br />
        You may have to increase your Gas Price (GWEI) settings!
      </TYPE.subHeader>
    </ContentWrapper>
  )
}

export default TransactionErrorModal
