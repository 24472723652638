import { extras, Texture } from 'pixi.js'
import { EDirection } from '../addPlayer'

// Function to update player's idle animation based on last direction
export function updateIdleAnimation(
  animatedSprite: extras.AnimatedSprite,
  lastDirection: EDirection,
  rows: Texture[][]
) {
  let idleTextures

  // Assign the appropriate idle textures based on the last direction
  if (lastDirection === EDirection.LEFT) {
    idleTextures = rows[1]
  } else if (lastDirection === EDirection.RIGHT) {
    idleTextures = rows[2]
  } else if (lastDirection === EDirection.UP) {
    idleTextures = rows[3]
  } else if (lastDirection === EDirection.DOWN) {
    idleTextures = rows[0]
  }

  // Only update the textures if they have changed to avoid restarting the animation repeatedly
  if (animatedSprite.textures !== idleTextures) {
    if (idleTextures) {
      animatedSprite.textures = idleTextures
    }
    animatedSprite.loop = true // Ensure idle animation loops
    animatedSprite.play()
  }
}
