import { JSBI } from '@/lib/sdk/index'

/**
 * Calculates the estimated number of blockchain blocks produced in a year based on the average block time.
 *
 * @param blockTime - The average time (in seconds) it takes to produce a single block. If `undefined`, the function assumes no blocks are produced and returns `0`.
 * @returns A `JSBI` BigInt representing the total number of blocks expected in one year. Returns `0` if `blockTime` is `undefined`.
 *
 * @example
 * ```typescript
 * import JSBI from 'jsbi';
 *
 * const averageBlockTime = 13.2; // seconds
 * const blocksPerYear = getBlocksPerYear(averageBlockTime);
 * console.log(blocksPerYear.toString()); // Outputs the number of blocks per year based on a 13.2-second block time
 * ```
 *
 * @remarks
 * - The calculation assumes there are:
 *   - 60 seconds in a minute
 *   - 60 minutes in an hour
 *   - 24 hours in a day
 *   - 365 days in a year
 * - The function uses the `JSBI` library to handle large integer calculations, ensuring precision for very large numbers of blocks.
 * - If `blockTime` is not provided (`undefined`), the function safely returns `0` to indicate no blocks are expected.
 *
 * @throws {TypeError} Throws a `TypeError` if `blockTime` is provided but is not a positive number.
 */
export default function getBlocksPerYear(blockTime: number | undefined): JSBI {
  // Handle undefined blockTime by setting a fallback value or throw an error if desired
  if (blockTime === undefined || blockTime <= 0) {
    return JSBI.BigInt(0)
  }

  // Calculate blocks using floating point arithmetic to handle decimal block times
  const blocksPerMinute = 60 / blockTime // E.g., 60 / 5.5
  const blocksPerHour = blocksPerMinute * 60
  const blocksPerDay = blocksPerHour * 24
  const blocksPerYear = blocksPerDay * 365

  // Convert the final result to JSBI for compatibility
  return JSBI.BigInt(Math.floor(blocksPerYear)) // Rounding down to avoid fractional blocks
}
