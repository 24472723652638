import { menuOptions as BASE_MENU_OPTIONS } from 'constants/settings/base/menuOptions'
import { npcs as BASE_NPCS } from 'constants/settings/base/npcs'
import { objects as BASE_OBJECTS } from 'constants/settings/base/objects'
import { player as BASE_PLAYER } from 'constants/settings/base/player'
import { BASE } from 'constants/settings/base/settings'
import { BASE_THEME } from 'constants/settings/base/theme'
import { tiles as BASE_TILES } from 'constants/settings/base/tiles'
import { zones as BASE_ZONE } from 'constants/settings/base/zones'
import { menuOptions as CRONOS_MENU_OPTIONS } from 'constants/settings/cronos/menuOptions'
import { npcs as CRONOS_NPCS } from 'constants/settings/cronos/npcs'
import { objects as CRONOS_OBJECTS } from 'constants/settings/cronos/objects'
import { player as CRONOS_PLAYER } from 'constants/settings/cronos/player'
import { CRONOS } from 'constants/settings/cronos/settings'
import { CRONOS_THEME } from 'constants/settings/cronos/theme'
import { tiles as CRONOS_TILES } from 'constants/settings/cronos/tiles'
import { zones as CRONOS_ZONES } from 'constants/settings/cronos/zones'
import { TMenuOption } from 'constants/settings/types/TMenuOption'
import { NPC } from 'constants/settings/types/TNpcs'
import { TObjects } from 'constants/settings/types/TObjects'
import { TSettings } from 'constants/settings/types/TSettings'
import { TThemes } from 'constants/settings/types/TThemes'
import { TTiles } from 'constants/settings/types/TTiles'
import { TZones } from 'constants/settings/types/TZones'

export type TAppSettingsClient = {
  settings: TSettings
  theme: TThemes
  menuOptions: TMenuOption[]
  npcs: NPC[]
  tiles: TTiles
  player: any
  zones: TZones
  zoneObjects: TObjects
}

export class AppSettingsClient {
  private settings: TSettings = CRONOS
  private theme: TThemes = CRONOS_THEME
  private menuOptions: TMenuOption[] = CRONOS_MENU_OPTIONS
  private npcs: NPC[] = CRONOS_NPCS
  private tiles: TTiles = CRONOS_TILES
  private player: any = CRONOS_PLAYER
  private zones: TZones = CRONOS_ZONES
  private zoneObjects: TObjects = CRONOS_OBJECTS

  private chainSettingsMap: { [key: number]: TAppSettingsClient } = {
    8453: {
      settings: BASE,
      theme: BASE_THEME,
      menuOptions: BASE_MENU_OPTIONS,
      npcs: BASE_NPCS,
      tiles: BASE_TILES,
      player: BASE_PLAYER,
      zones: BASE_ZONE,
      zoneObjects: BASE_OBJECTS
    },
    25: {
      settings: CRONOS,
      theme: CRONOS_THEME,
      menuOptions: CRONOS_MENU_OPTIONS,
      npcs: CRONOS_NPCS,
      tiles: CRONOS_TILES,
      player: CRONOS_PLAYER,
      zones: CRONOS_ZONES,
      zoneObjects: CRONOS_OBJECTS
    }
  }

  constructor(chainId: number, onChain: boolean) {
    if (onChain) {
      // get off of the chain
    } else {
      this.loadLocalChainSettings(chainId)
    }
  }

  private loadLocalChainSettings = (chainId: number) => {
    const chainSettings = this.chainSettingsMap[chainId]
    if (chainSettings) {
      this.settings = chainSettings.settings
      this.theme = chainSettings.theme
      this.menuOptions = chainSettings.menuOptions
      this.npcs = chainSettings.npcs
      this.tiles = chainSettings.tiles
      this.player = chainSettings.player
      this.zones = chainSettings.zones
      this.zoneObjects = chainSettings.zoneObjects
    } else {
      console.error('Passed an unrecognized chain')
    }
  }

  public getSettings = () => {
    return {
      settings: this.settings,
      theme: this.theme,
      menuOptions: this.menuOptions,
      npcs: this.npcs,
      tiles: this.tiles,
      player: this.player,
      zones: this.zones,
      zoneObjects: this.zoneObjects
    }
  }

  public getAllSettings = (): { [key: number]: TAppSettingsClient } => {
    return this.chainSettingsMap
  }
}
