import React from 'react'
import { MenuContainer, MenuButton } from './ExternalLinks.styles'

// Social links type
type SocialLink = {
  label: string
  url: string
}

// Props for ExternalLinks component
type ExternalLinksProps = {
  links: SocialLink[]
}

// Wrapper for the menu buttons
export const ExternalLinks: React.FC<ExternalLinksProps> = ({ links }) => {
  return (
    <MenuContainer>
      {links.map((link, index) => (
        <MenuButton key={index} href={link.url} target="_blank" rel="noopener noreferrer">
          {link.label}
        </MenuButton>
      ))}
    </MenuContainer>
  )
}
