// HeroBannerContent.tsx
import React from 'react'
import { Link } from 'react-router-dom'
import { UniTokenAnimated as AnimatedSpin } from '../../../../theme'
import { HeroBannerWrapper, AnimatedBackground, HeroContent, Title, Subtitle, HeroButton } from '../LandingPage.styles'

interface HeroBannerContentProps {
  backgroundImage: string
  logoSrc: string
  platformName: string
  domain: string
}

export const HeroBannerContent: React.FC<HeroBannerContentProps> = ({
  backgroundImage,
  logoSrc,
  platformName,
  domain
}) => {
  return (
    <HeroBannerWrapper>
      <AnimatedBackground backgroundImage={backgroundImage} />
      <HeroContent>
        <AnimatedSpin width="100px" src={logoSrc} />
        <Title>Welcome to {platformName}</Title>
        <Subtitle>Your journey begins here</Subtitle>
        <HeroButton as={Link} to={`${domain}`}>
          Play
        </HeroButton>
      </HeroContent>
    </HeroBannerWrapper>
  )
}
