import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { TDappData } from 'constants/settings/types/TNpcs'
import { ethers } from 'ethers'
import { useCrowdSaleContract } from 'hooks/useContract'
import { useState, useCallback, useEffect } from 'react'
import { extractRevertReason } from './utils'

export interface CrowdSaleInfo {
  endBlock: string | null
  startBlock: string | null
  rate: string | null
  weiRaised: string | null
  hasEnded: boolean | null
}

export function useCrowdSaleInfo(dappData: TDappData) {
  const { library } = useWeb3React<Web3Provider>()
  const crowdSale = useCrowdSaleContract(dappData)

  const [crowdSaleInfo, setCrowdSaleInfo] = useState<CrowdSaleInfo>({
    endBlock: null,
    startBlock: null,
    rate: null,
    weiRaised: null,
    hasEnded: null
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  // Fetch crowdsale info from the contract
  const fetchCrowdsaleInfo = useCallback(async () => {
    if (!crowdSale || !library) {
      setError('CrowdSale contract or library is not available')
      setLoading(false)
      return
    }

    try {
      setLoading(true)
      setError(null)
      // Fetch all crowdsale-related information
      const [endBlock, startBlock, rate, weiRaised, hasEnded] = await Promise.all([
        crowdSale.END_BLOCK(),
        crowdSale.START_BLOCK(),
        crowdSale.rate(),
        crowdSale.weiRaised(),
        crowdSale.hasEnded()
      ])

      setCrowdSaleInfo({
        endBlock: endBlock.toString(),
        startBlock: startBlock.toString(),
        rate: rate.toString(),
        weiRaised: ethers.utils.formatEther(weiRaised),
        hasEnded
      })
      setLoading(false)
    } catch (err) {
      const message = extractRevertReason(err)
      console.error('Error fetching crowdsale info:', err)
      setError(message)
      setLoading(false)
    }
  }, [crowdSale, library])

  useEffect(() => {
    if (crowdSale) {
      fetchCrowdsaleInfo()
    }
  }, [crowdSale, fetchCrowdsaleInfo])

  return {
    crowdSaleInfo,
    loading,
    error,
    fetchCrowdsaleInfo
  }
}
