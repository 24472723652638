import React from 'react'
import styled from 'styled-components'
import { AnimatedText, Title } from '../LandingPage.styles'
import { TwoColumnLayout } from './TwoColumnLayout'
import { Token } from '@/lib/sdk/index'

export const TextContent = styled.div`
  text-align: justify;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 300;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align: left;
  `};
`

interface GovernanceTokenSpotlightProps {
  wrappedCurrency: Token | undefined
  governanceToken: Token | undefined
  logoSrc: string
  platformName: string
  domain: string
}

export const GovernanceTokenSpotlight: React.FC<GovernanceTokenSpotlightProps> = ({
  wrappedCurrency,
  governanceToken,
  logoSrc,
  platformName,
  domain
}) => {
  return (
    <TwoColumnLayout
      leftContent={<img src={logoSrc} alt={`${platformName} Logo`} style={{ width: '100px' }} />}
      rightContent={
        <TextContent>
          <Title>Embark on {platformName} Adventure</Title>
          <AnimatedText>
            {`${governanceToken?.symbol ??
              'Token'} is the core of DeFi RPG, empowering governance, liquidity farming, and custom RPG
            creation across our settlements. As a versatile asset, ${governanceToken?.symbol ??
              'Token'} allows users to participate in
            governance decisions, add liquidity, and earn through high-APR farms. It drives a thriving
            ecosystem where community-driven worlds and RPG adventures come to life. With support for pairing with
            assets like  ${wrappedCurrency?.symbol ?? 'native'} and partner tokens, ${governanceToken?.symbol ??
              'Token'} provides creators and players
            with unique opportunities to expand their reach and shape the future of DeFi RPG.`}
          </AnimatedText>
        </TextContent>
      }
    />
  )
}
