import { TThemes } from 'constants/settings/types/TThemes'

export const BASE_THEME: TThemes = {
  white: '#FFFFFF',
  black: '#000000',
  text1: '#FFFFFF',
  text2: '#FFFFFF',
  text3: '#4F4853',
  text4: '#B2B4BD',
  text5: '#E2E3E7',
  bg1: '#4E4752',
  bg2: '#4E4753',
  bg3: '#211E30',
  bg4: '#211E30',
  bg5: 'rgba(237, 119, 54, 0.7)',

  modalBG: 'rgba(78,71,82,0.3)',
  advancedBG: 'rgba(248,249,250,0.6)',

  primary1: '#4F4853',
  primary2: '#ED7736',
  primary3: '#FFC300',
  primary4: '#DAF7A6',
  primary5: '#211E30',
  primaryText1: '#4F4853',
  secondary1: 'rgba(0, 0, 0, 0)', // transparent
  secondary2: 'rgba(0, 0, 0, 0)', // transparent
  secondary3: '#4E4754',
  red1: '#FF6347',
  red2: '#E53935',
  red3: '#B71C1C',
  green1: '#388E3C',
  yellow1: '#FFEB3B',
  yellow2: '#FF9800',
  blue1: '#2196F3',
  tokenColor: '#4E4755',
  tokenButtonGradientStart: '#66BB6A',
  tokenButtonGradientEnd: '#2E7D32',
  customCardGradientStart: '#4F4853',
  customCardGradientEnd: '#211E30'
}
