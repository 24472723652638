import { AutoColumn } from 'components/Column'
import { GlobalMenu } from 'components/GlobalMenu/GlobalMenu'
import { useGlobalMenu } from 'components/GlobalMenu/GlobalMenuProvider'
import { TMenuOption } from 'constants/settings/types/TMenuOption'
import { NPC, EOptionType } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { TYPE } from 'theme'
import { ExploreSection } from './components/ExploreSection'
import { GovernanceTokenSpotlight } from './components/GovernanceTokenSpotlight'
import { HeroBannerContent } from './components/HeroBannerContent'
import { PartnerSpotlight } from './components/PartnerSpotlight'
import { ScrollArrowButton } from './components/ScrollArrowButton'
import { SlideProps } from './components/SlideShow'
import { ContentWrapper, Divider, ThemedAutoColumn, Title } from './LandingPage.styles'
import { createSingleFrame } from './utils/createSingleFrame'

export const HeroBanner: React.FC = () => {
  // hook(s)
  const { settings, getAllSettings, npcs, menuOptions } = useAppSettings()
  const { handleMenuClick } = useGlobalMenu()
  const { ref: themedAutoRowDexDisplayRef, inView: themedAutoRowDexDisplayInView } = useInView()

  // callback(s)
  // Helper function to find a specific menu option by type
  const findMenuOption = (type: EOptionType): TMenuOption | undefined => {
    return menuOptions.find(option => option.dappData.type === type)
  }

  // Click handler to open a specific menu option
  const handleSpriteButtonClick = (optionType: EOptionType) => {
    const menuOption = findMenuOption(optionType)
    if (menuOption) {
      handleMenuClick(menuOption)
    }
  }

  // slide show var(s)
  const allChainSettings = getAllSettings()
  const allChainNpcs = Object.values(allChainSettings)
    .map(item => item.npcs)
    .flatMap(chainNpcs => chainNpcs)

  // Filter for partners and remove duplicates based on a unique property like `npc.id`
  const allChainPartnerNpcs: NPC[] = allChainNpcs
    .filter(npc => npc.isPartner)
    .filter(
      (npc, index, self) => index === self.findIndex(otherNpc => otherNpc.id === npc.id) // Replace `id` with a unique identifier
    )

  const dataSlides: SlideProps[] = allChainPartnerNpcs.map(partnerNpc => {
    return {
      useSpriteSheet: true,
      imageUrl: partnerNpc.spritesheetUrl,
      frameWidth: partnerNpc.frameWidth,
      frameHeight: partnerNpc.frameHeight,
      numColumns: partnerNpc.spritesheetNumColumns,
      duration: 1,
      spriteRow: 0,
      content: (
        <AutoColumn gap="md">
          <TYPE.largeHeader color={'text2'}>{partnerNpc.dialogue?.npcName ?? 'Merchant'}</TYPE.largeHeader>
          <TYPE.body>
            {partnerNpc.dialogue?.text && partnerNpc.dialogue.text.length
              ? partnerNpc.dialogue.text[Math.floor(Math.random() * partnerNpc.dialogue.text.length)]
              : ''}
          </TYPE.body>
        </AutoColumn>
      )
    }
  })

  // single frame var(s)
  const singleTraderFrame = createSingleFrame(
    npcs[1],
    'Meet the Trader, your trusty guide to the bustling marketplaces of DeFi RPG. Swap tokens and explore liquidity pools just as you would barter in a classic RPG, all while harnessing the power of blockchain technology.',
    { spriteRow: 3, buttonLabel: 'Swap', onButtonClick: () => handleSpriteButtonClick(EOptionType.SWAP) }
  )
  const singleFarmerFrame = createSingleFrame(
    npcs[2],
    'Ready to grow your assets? Visit the Farmer NPC to stake your liquidity pool (LP) tokens and reap rewards. Plant your digital seeds and watch your earnings flourish as you contribute to the ecosystem.',
    { spriteRow: 6, buttonLabel: 'Visit Our Farms', onButtonClick: () => handleSpriteButtonClick(EOptionType.FARM) }
  )
  const singleCowboyFrame = createSingleFrame(
    npcs[4],
    'Stay updated and connected with the Space Cowboy, your bridge to the wider DeFi RPG community. Access social media channels, get the latest news, and discover partnership opportunities — all through one charismatic NPC.'
  )
  return (
    <ScrollArrowButton>
      <ContentWrapper gap="lg">
        <GlobalMenu menuType="landing" />
        <HeroBannerContent
          backgroundImage={settings.platformImg.appBgImg}
          logoSrc={settings.platformImg.appLogoDark}
          platformName={settings.platformName}
          domain={settings.domain}
        />
        <Divider />
        <GovernanceTokenSpotlight
          wrappedCurrency={settings.wrappedCurrency}
          governanceToken={npcs[2]?.dialogue?.options[0].dappData.governanceToken ?? undefined}
          logoSrc={settings.platformImg.appLogoDark}
          platformName={settings.platformName}
          domain={settings.domain}
        />
        <ThemedAutoColumn justify="center">
          <Title>Explore the DeFi RPG World</Title>
        </ThemedAutoColumn>
        <PartnerSpotlight dataSlides={dataSlides} />
        <ExploreSection
          themedAutoRowDexDisplayRef={themedAutoRowDexDisplayRef}
          themedAutoRowDexDisplayInView={themedAutoRowDexDisplayInView}
          singleTraderFrame={singleTraderFrame}
          singleFarmerFrame={singleFarmerFrame}
          singleCowboyFrame={singleCowboyFrame}
        />
      </ContentWrapper>
    </ScrollArrowButton>
  )
}
