import React from 'react'
import styled, { keyframes, css } from 'styled-components'

// Keyframes for sprite animation
const spriteRowAnimation = (frameWidth: number, numColumns: number) => keyframes`
  0% { background-position-x: 0; }
  100% { background-position-x: ${-frameWidth * (numColumns - 1)}px; }
`

const SpriteFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 800px;
  flex-flow: nowrap;
  text-align: left;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-flow: wrap;
    text-align: center;
    justify-content: center;
  `};
`

const ImageWrapper = styled.div`
  margin-bottom: 20px;
`

const Sprite = styled.div<{
  frameWidth: number
  frameHeight: number
  numColumns: number
  spriteRow: number
  duration: number
  useSpriteSheet: boolean
  src: string
}>`
  width: 175px;
  height: 150px;
  background-image: url(${props => props.src});
  background-size: 720px auto;
  background-position: 0 ${({ spriteRow }) => `-${spriteRow * 720}px`};

  ${({ useSpriteSheet, frameWidth, numColumns, duration }) =>
    useSpriteSheet &&
    css`
      animation: ${spriteRowAnimation(frameWidth, numColumns)} ${duration}s steps(${numColumns}) infinite;
    `}
`

const StaticImage = styled.div<{ src: string }>`
  width: 150px;
  height: 150px;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
`

const Content = styled.div`
  padding: 20px;
  color: #333;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
`

const ActionButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.primary1};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary2};
  }
`

export interface SpriteFrameProps {
  imageUrl: string
  useSpriteSheet?: boolean
  frameWidth?: number
  frameHeight?: number
  numColumns?: number
  duration?: number
  spriteRow?: number
  content?: React.ReactNode
  buttonLabel?: string
  onButtonClick?: () => void
}

export const SpriteFrame: React.FC<SpriteFrameProps> = ({
  imageUrl,
  useSpriteSheet = false,
  frameWidth = 150,
  frameHeight = 150,
  numColumns = 1,
  duration = 1,
  spriteRow = 0,
  content,
  buttonLabel,
  onButtonClick
}) => {
  return (
    <SpriteFrameContainer>
      <ImageWrapper>
        {useSpriteSheet ? (
          <Sprite
            src={imageUrl}
            frameWidth={frameWidth}
            frameHeight={frameHeight}
            numColumns={numColumns}
            duration={duration}
            spriteRow={spriteRow}
            useSpriteSheet={useSpriteSheet}
          />
        ) : (
          <StaticImage src={imageUrl} />
        )}
      </ImageWrapper>
      {content && (
        <Content>
          {content}
          {buttonLabel && onButtonClick && <ActionButton onClick={onButtonClick}>{buttonLabel}</ActionButton>}
        </Content>
      )}
    </SpriteFrameContainer>
  )
}
