import styled, { keyframes, css } from 'styled-components'

export const clickAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`

export const keyPressAnimation = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`

export const bounceDown = keyframes`
  0% { transform: translateY(-100%); }
  60% { transform: translateY(1px); }
  80% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`

export const Container = styled.div<{ isClicked: boolean }>`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  animation: ${props =>
      props.isClicked
        ? css`
            ${clickAnimation}
          `
        : 'none'}
    0.3s ease-in-out;
  z-index: 1200;
`

export const OverlayBox = styled.div<{ keyPressed: boolean }>`
  position: absolute;
  bottom: -20px;
  width: 20px;
  height: 20px;
  background-color: #e1e1e1;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  animation: ${({ keyPressed }) =>
    keyPressed ? css`${bounceDown} 0.6s ease-out, ${keyPressAnimation} 0.3s ease-in-out` : 'none'};
`

export const Letter = styled.span`
  font-size: 8px;
  font-weight: bold;
  color: #000;
`

export const DialogueOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

export const DialogueBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2d2d3a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001;

  /* Use min-width and max-width to make the modal responsive */
  min-width: 300px;
  max-width: 80%;

  /* Ensure text doesn't overflow and the modal adjusts with content */
  word-wrap: break-word;
  text-align: center;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

// Close Icon styles (top-right of the modal)
export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary1};
  }
`

export const CloseButton = styled.button`
  padding: 10px 20px;
  background-color: #4a4a4a; /* Dark grey */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;

  &:hover {
    background-color: #2e2e2e; /* Darker grey */
  }
`
