import { NPC } from 'constants/settings/types/TNpcs'
import { addPlayer } from 'game/playerCharacter/addPlayer'
import { Application, Container } from 'pixi.js'

export const loadPlayerCharacter = (
  app: Application,
  player: any,
  playerContainer: Container,
  setShowDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogueData: React.Dispatch<React.SetStateAction<NPC | null>>,
  startTile: { x: number; y: number },
  mobileKeys: { [key: string]: boolean }
) => {
  if (!player || !player.characterImagePath || player.characterImagePath === '') {
    return
  }
  return addPlayer(
    app,
    playerContainer,
    player.characterImagePath,
    setShowDialogue,
    setDialogueData,
    startTile,
    mobileKeys
  )
}
