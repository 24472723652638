export async function switchNetwork(
  chainId: string,
  chainName: string,
  rpcUrl: string,
  explorerUrl: string,
  nativeCurrency: { name: string; symbol: string; decimals: number }
) {
  const provider = (window as any).ethereum

  if (!provider) {
    console.error('MetaMask or Web3 provider not found')
    throw `MetaMask or Web3 provider not found`
  }

  try {
    // Try switching to the network
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }]
    })
  } catch (switchError) {
    // If the network isn't added to the user's wallet, prompt them to add it
    if ((switchError as any).code === 4902) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId,
              chainName,
              rpcUrls: [rpcUrl],
              blockExplorerUrls: [explorerUrl],
              nativeCurrency
            }
          ]
        })
      } catch (addError) {
        console.error('Failed to add network:', addError)
        throw `Failed to add network:, ${addError}`
      }
    } else {
      console.error('Failed to switch network:', switchError)
      throw `Failed to switch network:, ${switchError}`
    }
  }
}
