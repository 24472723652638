import { DEFAULT_CURRENCY, DEFAULT_WETH_CURRENCY } from 'constants/settings'
import { Currency } from '@/lib/sdk/index'

export default function baseCurrencies(nativeCurrency: Currency, wrappedCurrency: Currency): Currency[] {
  const currencies: Currency[] = []

  if (nativeCurrency && wrappedCurrency) {
    currencies.push(nativeCurrency)
    currencies.push(wrappedCurrency)
  } else {
    currencies.push(DEFAULT_CURRENCY)
    currencies.push(DEFAULT_WETH_CURRENCY)
  }

  return currencies
}
