import React from 'react'
import { AnimatedText, ThemedAutoRowDexDisplay } from '../LandingPage.styles'
import { SpriteFrame, SpriteFrameProps } from './SpriteFrame'

interface ExploreSectionProps {
  themedAutoRowDexDisplayRef: React.Ref<HTMLDivElement>
  themedAutoRowDexDisplayInView: boolean
  singleTraderFrame: SpriteFrameProps
  singleFarmerFrame: SpriteFrameProps
  singleCowboyFrame: SpriteFrameProps
}

export const ExploreSection: React.FC<ExploreSectionProps> = ({
  themedAutoRowDexDisplayRef,
  themedAutoRowDexDisplayInView,
  singleTraderFrame,
  singleFarmerFrame,
  singleCowboyFrame
}) => {
  return (
    <>
      <ThemedAutoRowDexDisplay
        justify="center"
        gap="lg"
        ref={themedAutoRowDexDisplayRef}
        isVisible={themedAutoRowDexDisplayInView}
      >
        <AnimatedText style={{ paddingBottom: '25px', paddingTop: '25px' }}>
          <SpriteFrame {...singleTraderFrame} />
        </AnimatedText>
        <SpriteFrame {...singleFarmerFrame} />
        <SpriteFrame {...singleCowboyFrame} />
      </ThemedAutoRowDexDisplay>
    </>
  )
}
