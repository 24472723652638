import { Web3Provider } from '@ethersproject/providers'
import { getContract } from 'utils'

const MULTICALL3_ABI = [
  {
    inputs: [
      {
        components: [
          { name: 'target', type: 'address' },
          { name: 'callData', type: 'bytes' }
        ],
        name: 'calls',
        type: 'tuple[]'
      }
    ],
    name: 'aggregate',
    outputs: [
      { name: 'blockNumber', type: 'uint256' },
      { name: 'returnData', type: 'bytes[]' }
    ],
    stateMutability: 'view',
    type: 'function'
  }
]

// Interface for Multicall3 response
export interface MulticallResponse {
  blockNumber: number
  returnData: string[]
}

// Interface for individual multicall
export interface MulticallCall {
  target: string
  callData: string
}

// Multicall3 utility function
export async function multicall(
  multicallAddress: string,
  provider: Web3Provider | undefined,
  calls: MulticallCall[]
): Promise<MulticallResponse | null> {
  if (!multicallAddress || !MULTICALL3_ABI || !provider) return null
  try {
    const multiCall = getContract(multicallAddress, MULTICALL3_ABI, provider, undefined)
    const response = await multiCall.aggregate(calls)
    return {
      blockNumber: response.blockNumber.toNumber(),
      returnData: response.returnData
    }
  } catch (error) {
    console.error('Failed to get contract', error)
    return null
  }
}
