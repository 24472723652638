import { chainId, factoryAddress, initHash, farmAddress, spaceToken, routerAddress } from './settings'
import { TMenuOption } from '../types/TMenuOption'
import { EOptionType } from '../types/TNpcs'

export const menuOptions: TMenuOption[] = [
  {
    menuLabel: 'Swap',
    chainId: chainId,
    dappData: {
      type: EOptionType.SWAP,
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: spaceToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://basescan.org/token/images/weth_28.png'
    }
  },

  {
    menuLabel: 'Add Liquidity',
    chainId: chainId,
    dappData: {
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: spaceToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://basescan.org/token/images/weth_28.png',
      type: EOptionType.POOLS
    }
  },
  {
    menuLabel: 'Import Pools',
    chainId: chainId,
    dappData: {
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: spaceToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://basescan.org/token/images/weth_28.png',
      type: EOptionType.IMPORT_POOLS
    }
  },
  {
    menuLabel: 'Farms',
    chainId: chainId,
    dappData: {
      type: EOptionType.FARM,
      farmAddress: farmAddress,
      factoryAddress: factoryAddress,
      initHash: initHash,
      governanceToken: spaceToken,
      routerAddress: routerAddress,
      appTokenImage: 'https://basescan.org/token/images/weth_28.png'
    }
  }
]
