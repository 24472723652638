import { Token, Currency } from '@/lib/sdk/index'

// needed to extend blockchain
export enum Blockchain {
  ETHEREUM = 1,
  BINANCE_SMART_CHAIN = 2,
  HARMONY = 3,
  MATIC = 4,
  BUILD = 5,
  BASE = 6,
  CRONOS = 7
}

// needed to extend blockchain
export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  POLYGON_MAINNET = 137,
  MUMBAI_TESTNET = 80001,
  AMOY_TESTNET = 80002,
  HARMONY_MAINNET = 1666600000,
  HARMONY_TESTNET = 1666700000,
  BUILD_BEAR = 19496,
  BASE = 8453,
  CRONOS = 25
}

// needed to extend blockchain
// setting intervals that gets passed into src/utils/getLibrary this is use to set how many times we should poll from a connection in MS
export const BlockChainPollIntervalSettings: { [chainId: number]: number } = {
  1: 15000,
  3: 15000,
  4: 15000,
  5: 15000,
  42: 15000,
  56: 1500,
  97: 1500,
  137: 500,
  80001: 500,
  80002: 500,
  1666600000: 500,
  1666700000: 500,
  19496: 500,
  8453: 500,
  25: 1500
}

// leave this alone. this is defaulted to ether for a reason and that is because we convert native currency to ETH currency ( see CurrencyAmount entity )
export const DEFAULT_CURRENCY = new Currency(18, 'ETH', 'Ether')

// should change to whatever our home base platform is going to be
export const DEFAULT_WETH_CURRENCY = new Currency(18, 'WETH', 'Wrapped Ether')
export const DEFAULT_WETH_TOKEN = new Token(
  8453,
  '0x4200000000000000000000000000000000000006',
  18,
  'WETH',
  'Wrapped Ether'
)

// needed to extend blockchain
export const SUPPORTED_CHAINS = [ChainId.BASE, ChainId.CRONOS]
// export const SUPPORTED_CHAINS = [ChainId.BASE]

// needed to extend blockchain
export const SUPPORTED_NETWORKS: SupportedNetwork[] = [
  {
    chainId: `0x${ChainId.CRONOS?.toString(16)}`,
    name: 'Cronos',
    rpcUrl: 'https://cronos-evm-rpc.publicnode.com',
    explorerUrl: 'https://cronoscan.com/',
    nativeCurrency: {
      name: 'Cronos',
      symbol: 'CRO',
      decimals: 18
    }
  },
  {
    chainId: `0x${ChainId.BASE?.toString(16)}`,
    name: 'Base',
    rpcUrl: 'https://base.publicnode.com/',
    explorerUrl: 'https://basescan.org/',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    }
  }
]

// Define the NativeCurrency type
interface NativeCurrency {
  name: string
  symbol: string
  decimals: number
}

// Define the SupportedNetwork type
export interface SupportedNetwork {
  chainId: string
  name: string
  rpcUrl: string
  explorerUrl: string
  nativeCurrency: NativeCurrency
}
