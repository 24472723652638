import CrowdSaleModal from 'components/CrowdSale/CrowdSaleModal'
import TokenExchangeModal from 'components/TokenExchange/TokenExchangeModal'
import { TMenuOption } from 'constants/settings/types/TMenuOption'
import { EOptionType } from 'constants/settings/types/TNpcs'
import Farms from 'pages/Farms'
import Pool from 'pages/Pool'
import PoolFinder from 'pages/PoolFinder'
import Swap from 'pages/Swap'
import React, { useState, createContext, useContext } from 'react'
import MenuModal from './MenuModal'

interface GlobalMenuContextType {
  handleMenuClick: (item: TMenuOption) => void
}

const GlobalMenuContext = createContext<GlobalMenuContextType | undefined>(undefined)

// Custom hook to access GlobalMenu
export const useGlobalMenu = () => {
  const context = useContext(GlobalMenuContext)
  if (!context) throw new Error('useGlobalMenu must be used within GlobalMenuProvider')
  return context
}

// The main provider
export const GlobalMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState<React.ReactNode>(null)
  const onClose = () => setActiveComponent(null)

  const handleMenuClick = (item: TMenuOption) => {
    switch (item.dappData.type) {
      case EOptionType.FARM:
        setActiveComponent(<Farms dappData={item.dappData} />)
        break
      case EOptionType.SWAP:
        setActiveComponent(<Swap dappData={item.dappData} />)
        break
      case EOptionType.POOLS:
        setActiveComponent(<Pool dappData={item.dappData} />)
        break
      case EOptionType.IMPORT_POOLS:
        setActiveComponent(<PoolFinder dappData={item.dappData} />)
        break
      case EOptionType.TOKEN_EXCHANGE:
        setActiveComponent(<TokenExchangeModal dappData={item.dappData} />)
        break
      case EOptionType.CROWDSALE:
        setActiveComponent(<CrowdSaleModal dappData={item.dappData} />)
        break
      case EOptionType.EXTERNAL_LINK:
        window.open(item.dappData.externalLinkUrl || '', '_blank')
        break
      default:
        setActiveComponent(null)
    }
  }

  return (
    <GlobalMenuContext.Provider value={{ handleMenuClick }}>
      {children}
      <MenuModal isOpen={!!activeComponent} onClose={onClose}>
        {activeComponent}
      </MenuModal>
    </GlobalMenuContext.Provider>
  )
}
