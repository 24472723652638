import React from 'react'
import styled from 'styled-components'

interface TwoColumnLayoutProps {
  leftContent: React.ReactNode
  rightContent: React.ReactNode
  gap?: string
  alignItems?: string
}

export const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({
  leftContent,
  rightContent,
  gap = '2rem',
  alignItems = 'center'
}) => {
  return (
    <Container gap={gap} alignItems={alignItems}>
      <Column>{leftContent}</Column>
      <Column>{rightContent}</Column>
    </Container>
  )
}

const Container = styled.div<{ gap: string; alignItems: string }>`
  max-width: 990px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    justify-items: center;
  `};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`
