import { TDappData } from 'constants/settings/types/TNpcs'
import { useMemo } from 'react'
import useBUSDPrice from './useBUSDPrice'
import useVaultTokensToken from './useVaultToken'
import { GOVERNANCE_TOKEN_INTERFACE } from '../constants/abis/governanceToken'
import { useTokenBalance } from '../state/wallet/hooks'
import { TokenAmount, Fraction } from '@/lib/sdk/index'

export default function useVaultTokensTVL(dappData: TDappData): Fraction | undefined {
  const govTokenBusdPrice = useBUSDPrice(dappData, dappData.governanceToken)
  const vaultTokens = useVaultTokensToken()
  const vaultTokensGovTokenBalance: TokenAmount | undefined = useTokenBalance(
    vaultTokens && vaultTokens.address,
    dappData.governanceToken,
    'balanceOf',
    GOVERNANCE_TOKEN_INTERFACE
  )

  return useMemo(() => {
    return govTokenBusdPrice ? vaultTokensGovTokenBalance?.multiply(govTokenBusdPrice?.raw) : undefined
  }, [dappData.governanceToken, govTokenBusdPrice, vaultTokens, vaultTokensGovTokenBalance])
}
