import { Container, Application } from 'pixi.js'

export const cleanup = (app: Application | null, containers?: Container[]) => {
  if (app) {
    console.log('Started cleaning up PixiJS instance')
    // Destroy loaders, tickers, and other objects
    app.loader.reset() // Reset the loader
    PIXI.utils.clearTextureCache()
    app.ticker.stop() // Stop the ticker

    // Destroy and remove containers from the stage
    if (containers && containers.length > 0) {
      containers.map((container: Container) => {
        app.stage.removeChild(container)
      })
    } else {
      app.stage.removeChildren()
    }

    // Destroy the PixiJS renderer and view
    app.destroy(true, { children: true, texture: true, baseTexture: true })

    // Clean up the HTML DOM container for PixiJS
    const pixiContainer = document.getElementById('pixi-container')
    if (pixiContainer) {
      while (pixiContainer.firstChild) {
        pixiContainer.removeChild(pixiContainer.firstChild) // Clean up the container
      }
    }

    console.log('Finished cleaning up PixiJS instance')
  }
  return null
}
