import { Box, Text } from 'rebass'
import styled from 'styled-components'

export const FloatingMenuContainer = styled(Box)`
  position: absolute;
  right: 10px;
  top: 20px;
`

export const FloatingMenuInner = styled(Box)`
  display: flex;
  flex-direction: row;
`

// Add a fun gradient background
export const SeasonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.3);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
`

export const SeasonNetworkContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const TownNetwork = styled('div')`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  width: 100%;
  margin-top: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`

// Make the date and time text more "game-like" by bolding and adjusting the font
export const DateTimeText = styled(Text)`
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`

export const BlockNumberText = styled(Text)`
  font-size: 1.2rem;
  font-family: 'Comic Sans MS', 'Arial', sans-serif; // A playful, game-style font
  color: #4e342e;
  margin-bottom: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  &::before {
    content: '⦿';
    font-size: 1.5rem;
    color: #ff5722;
    margin-right: 8px;
  }
`
