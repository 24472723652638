import { utils } from 'ethers'
import { Price, Fraction, TokenAmount, JSBI, CurrencyAmount } from '@/lib/sdk/index'

export default function calculateApr(
  govTokenWethPrice: Price | undefined,
  baseBlockRewards: TokenAmount,
  blocksPerYear: JSBI,
  poolShare: Fraction,
  valueOfTotalStakedAmountInPairCurrency: TokenAmount | Fraction
): Fraction | undefined {
  const multiplied = govTokenWethPrice?.raw
    .multiply(baseBlockRewards.raw)
    .multiply(blocksPerYear.toString())
    .multiply(poolShare)

  let apr: Fraction | undefined
  if (multiplied && valueOfTotalStakedAmountInPairCurrency.greaterThan('0')) {
    if (valueOfTotalStakedAmountInPairCurrency instanceof TokenAmount) {
      apr = multiplied.divide(valueOfTotalStakedAmountInPairCurrency?.raw)
    } else {
      // Somehow a Fraction/Fraction division has to be further divided by 1 ETH to get the correct number?
      apr = multiplied.divide(valueOfTotalStakedAmountInPairCurrency).divide(utils.parseEther('1').toString())
    }
    return apr
  }

  return new Fraction(JSBI.BigInt(0), JSBI.BigInt(1))
}

/**
 * Calculate the APR value as a Fraction
 * @param stakingTokenRewardPoolValue FRACTION of the staking token (as a Price object)
 * @param rewardPoolValueTotal FRACTION of the reward token (as a Price object)
 * @param totalStaked Total amount of staking tokens in the pool (as TokenAmount or Fraction)
 * @param tokenPerBlock Amount of reward tokens allocated per block (as TokenAmount)
 * @returns APR value as a Fraction, or undefined if calculation cannot be performed
 */
// export function getTwinVaultApr(
//   stakingTokenRewardPoolValue: Fraction,
//   rewardPoolValueTotal: Fraction,
//   totalStaked: TokenAmount | Fraction | CurrencyAmount,
//   tokenPerBlock: Fraction
// ): Fraction {
//   // Sanity check
//   console.log('strpv', stakingTokenRewardPoolValue.toFixed(18))
//   console.log('rpvt', rewardPoolValueTotal.toFixed(18))
//   console.log('tpb', tokenPerBlock.toFixed(18)) // does not match excel
//   // Calculate the total reward price per year
//   const totalRewardPricePerYear = rewardPoolValueTotal.multiply(tokenPerBlock)
//   let totalStakingTokenInMarket: Fraction

//   // Calculate the total staking token market value
//   if (totalStaked instanceof TokenAmount) {
//     totalStakingTokenInMarket = rewardPoolValueTotal.multiply(totalStaked.raw)
//   } else if (totalStaked instanceof CurrencyAmount) {
//     totalStakingTokenInMarket = rewardPoolValueTotal.multiply(totalStaked.raw)
//   } else {
//     // Multiply the fractions manually since multiply might not accept Fraction as parameter
//     totalStakingTokenInMarket = new Fraction(
//       JSBI.multiply(stakingTokenRewardPoolValue.numerator, totalStaked.numerator),
//       JSBI.multiply(stakingTokenRewardPoolValue.denominator, totalStaked.denominator)
//     ).multiply(JSBI.BigInt(2))
//   }

//   // Check if total staking value is greater than zero
//   if (JSBI.greaterThan(totalStakingTokenInMarket.numerator, JSBI.BigInt(0))) {
//     // APR = (Total Reward Price Per Year / Total Staking Token Market Value) * 100
//     const apr = totalRewardPricePerYear.divide(totalStakingTokenInMarket).multiply(JSBI.BigInt(100))
//     return apr
//   }

//   return new Fraction(JSBI.BigInt(0), JSBI.BigInt(1))
// }

export function calculateTwinVaultApr(
  rewardTokenOneWethPrice: Price,
  rewardTokenTwoWethPrice: Price,
  baseBlockRewardsOne: CurrencyAmount,
  baseBlockRewardsTwo: CurrencyAmount,
  blocksPerYear: JSBI,
  valueOfTotalStakedAmountInPairCurrency: TokenAmount | Fraction
): Fraction | undefined {
  const rewardTokenOneYearlyReward = rewardTokenOneWethPrice?.raw
    .multiply(baseBlockRewardsOne.raw)
    .multiply(blocksPerYear.toString())

  const rewardTokenOneMultiplied = parseFloat(rewardTokenOneYearlyReward.toFixed(6)) / 0.0821916565890302

  const rewardTokenTwoYearlyReward = rewardTokenTwoWethPrice?.raw
    .multiply(baseBlockRewardsTwo.raw)
    .multiply(blocksPerYear.toString())

  const rewardTokenTwoMultiplied = parseFloat(rewardTokenTwoYearlyReward.toFixed(6)) / 0.0821916565890302
  const multiplied = rewardTokenOneMultiplied + rewardTokenTwoMultiplied
  const multipliedAsFraction = new Fraction(JSBI.BigInt(multiplied * 1e6), JSBI.BigInt(1e6))

  let apr: Fraction | undefined
  if (multiplied && valueOfTotalStakedAmountInPairCurrency.greaterThan('0')) {
    if (valueOfTotalStakedAmountInPairCurrency instanceof TokenAmount) {
      apr = multipliedAsFraction.divide(valueOfTotalStakedAmountInPairCurrency?.raw)
    } else {
      // Somehow a Fraction/Fraction division has to be further divided by 1 ETH to get the correct number?
      apr = multipliedAsFraction.divide(valueOfTotalStakedAmountInPairCurrency).divide(utils.parseEther('1').toString())
    }
    return apr
  }

  return new Fraction(JSBI.BigInt(0), JSBI.BigInt(1))
}
