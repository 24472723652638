import { ChainId } from 'constants/settings'
import { TMenuOption } from 'constants/settings/types/TMenuOption'
import { NPC } from 'constants/settings/types/TNpcs'
import { TObjects } from 'constants/settings/types/TObjects'
import { TThemes } from 'constants/settings/types/TThemes'
import { TTiles } from 'constants/settings/types/TTiles'
import { TZones } from 'constants/settings/types/TZones'
import { TAppSettingsClient } from 'interfaces/AppSettingsClient'
import React, { useContext } from 'react'
import { useAppConstant } from './hooks/useAppConstant'
import { TSettings } from '../../constants/settings/types/TSettings'

// Define the context type
type AppSettingsContextType = {
  settings: TSettings
  theme: TThemes
  npcs: NPC[]
  chainId: ChainId | undefined
  tiles: TTiles
  zones: TZones
  player: any //todo need to defined object
  zoneObjects: TObjects
  menuOptions: TMenuOption[]
  getAllSettings: () => {
    [key: number]: TAppSettingsClient
  }
}

const AppSettingsContext = React.createContext<AppSettingsContextType | undefined>(undefined)

export const AppSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    settings,
    theme,
    npcs,
    chainId,
    tiles,
    player,
    zones,
    zoneObjects,
    menuOptions,
    getAllSettings
  } = useAppConstant()

  return (
    <AppSettingsContext.Provider
      value={{ settings, theme, npcs, chainId, tiles, player, zones, zoneObjects, menuOptions, getAllSettings }}
    >
      {children}
    </AppSettingsContext.Provider>
  )
}

export const useAppSettings = (): AppSettingsContextType => {
  const context = useContext(AppSettingsContext)
  if (!context) {
    throw new Error('useAppSettings must be used within a AppSettingsProvider')
  }
  return context
}
