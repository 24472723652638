import { BigNumber } from '@ethersproject/bignumber'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useTokenContract, useGovTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { Token, TokenAmount } from '@/lib/sdk/index'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export function useGovTokenSupply(method = 'totalSupply', dappData: TDappData): TokenAmount | undefined {
  const contract = useGovTokenContract(dappData)
  const value: BigNumber = useSingleCallResult(contract, method)?.result?.[0]
  return dappData.governanceToken && value ? new TokenAmount(dappData.governanceToken, value.toString()) : undefined
}
