import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import { useMemo } from 'react'
import { useActiveWeb3React } from '.'
import { PairState, usePairs } from '../data/Reserves'
import getToken from '../utils/getToken'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { Currency, currencyEquals, JSBI, Token, Price } from '@/lib/sdk/index'

/**
 * Returns the price in Stable of the input currency
 * @param currency currency to compute the Stable price of
 */
export default function useStablePrice(dappData: TDappData, currency?: Currency): Price | undefined {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const wrapped = wrappedCurrency(currency, chainId, settings.blockchainSettings.currency, settings.wrappedCurrency)
  const stable: Token | undefined = settings.stablePriceTokenToOverride

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [
        chainId && wrapped && currencyEquals(settings.wrappedCurrency, wrapped) ? undefined : currency,
        chainId ? settings.wrappedCurrency : undefined
      ],
      [stable && wrapped?.equals(stable) ? undefined : wrapped, stable],
      [chainId ? settings.wrappedCurrency : undefined, stable]
    ],
    [chainId, currency, wrapped, stable]
  )
  const [[ethPairState, ethPair], [stablePairState, stablePair], [stableEthPairState, stableEthPair]] = usePairs(
    tokenPairs,
    dappData
  )

  return useMemo(() => {
    if (!currency || !wrapped || !chainId) {
      return undefined
    }
    // handle weth/eth
    if (wrapped.equals(settings.wrappedCurrency)) {
      if (stablePair && stable) {
        const price = stablePair.priceOf(settings.wrappedCurrency)
        return stable ? new Price(currency, stable, price.denominator, price.numerator) : undefined
      } else {
        return undefined
      }
    }

    // handle stable
    if (stable && wrapped.equals(stable)) {
      return stable ? new Price(stable, stable, '1', '1') : undefined
    }

    const ethPairETHAmount = ethPair?.reserveOf(settings.wrappedCurrency)
    const ethPairETHStableValue: JSBI =
      ethPairETHAmount && stableEthPair
        ? stableEthPair.priceOf(settings.wrappedCurrency).quote(ethPairETHAmount).raw
        : JSBI.BigInt(0)

    // all other tokens
    // first try the stable pair
    if (
      stable &&
      stablePairState === PairState.EXISTS &&
      stablePair &&
      stablePair.reserveOf(stable).greaterThan(ethPairETHStableValue)
    ) {
      const price = stablePair.priceOf(wrapped)
      return stable ? new Price(currency, stable, price.denominator, price.numerator) : undefined
    }
    if (
      stable &&
      ethPairState === PairState.EXISTS &&
      ethPair &&
      stableEthPairState === PairState.EXISTS &&
      stableEthPair
    ) {
      if (
        stableEthPair.reserveOf(stable).greaterThan('0') &&
        ethPair.reserveOf(settings.wrappedCurrency).greaterThan('0')
      ) {
        const ethStablePrice = stableEthPair.priceOf(stable)
        const currencyEthPrice = ethPair.priceOf(settings.wrappedCurrency)
        const stablePrice = ethStablePrice.multiply(currencyEthPrice).invert()
        return new Price(currency, stable, stablePrice.denominator, stablePrice.numerator)
      }
    }
    return undefined
  }, [
    chainId,
    stable,
    currency,
    ethPair,
    ethPairState,
    stableEthPair,
    stableEthPairState,
    stablePair,
    stablePairState,
    wrapped
  ])
}
