import DialogueModal from 'components/Dialogue/DialogueModal'
import { TDappData } from 'constants/settings/types/TNpcs'
import useStablePrice from 'hooks/useStablePrice'
import Manage from 'pages/Farms/Manage'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React, { useState } from 'react'
import styled from 'styled-components'
import baseCurrencies from 'utils/baseCurrencies'
import useUSDCPrice from 'utils/useUSDCPrice'
import { Break, CardNoise, CardBGImage } from './styled'
import useBUSDPrice from '../../hooks/useBUSDPrice'
import { useColor } from '../../hooks/useColor'
import { StakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { JSBI } from '@/lib/sdk/index'

const StatContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  text-align: center;
`

const StatContainerTop = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin: 1rem;
  text-align: center;
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) => `${theme.bg5} `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align everything */
  padding: 1rem;
  text-align: center;
  z-index: 1;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    gap: 12px;
  `};

  /* Ensure there's enough space between the elements */
  .token-text {
    margin-bottom: 8px;
  }

  button {
    width: 100%; /* Make the button take full width if needed */
  }
`

const BottomSection = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    gap: 8px;
  `};
`

export default function PoolCard({
  stakingInfo,
  isArchived,
  dappData
}: {
  stakingInfo: StakingInfo
  isArchived: boolean
  dappData: TDappData
}) {
  const [showModal, setShowModal] = useState(false) // State to manage modal visibility

  const govTokenPriceBusd = useBUSDPrice(dappData, dappData.governanceToken)
  const govTokenPriceUsdc = useUSDCPrice(dappData, dappData.governanceToken)
  const govTokenPriceStable = useStablePrice(dappData, dappData.governanceToken)
  const govTokenPrice = govTokenPriceStable ?? govTokenPriceBusd ?? govTokenPriceUsdc

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))
  const poolSharePercentage = stakingInfo.poolShare.multiply(JSBI.BigInt(100))

  const { settings } = useAppSettings()
  const baseCurrency = baseCurrencies(settings.blockchainSettings.currency, settings.wrappedCurrency)[0]

  // get the color of the token
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]
  const currency0 = unwrappedToken(token0, settings.blockchainSettings.currency, settings.wrappedCurrency)
  const currency1 = unwrappedToken(token1, settings.blockchainSettings.currency, settings.wrappedCurrency)
  const backgroundColor = useColor(stakingInfo?.baseToken)

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />
      <TopSection>
        <DoubleCurrencyLogo dappData={dappData} currency0={currency0} currency1={currency1} size={24} />
        <TYPE.white fontWeight={600} fontSize={24} className="token-text">
          {currency0.symbol}-{currency1.symbol}
        </TYPE.white>

        <ButtonPrimary padding="8px" borderRadius="8px" onClick={openModal}>
          {isStaking || isArchived ? 'Manage' : 'Deposit'}
        </ButtonPrimary>
      </TopSection>
      <StatContainer>
        <RowBetween>
          <TYPE.white> APR*</TYPE.white>
          <TYPE.white fontWeight={500}>
            <b>
              {stakingInfo.apr && stakingInfo.apr.greaterThan('0')
                ? `${stakingInfo.apr.multiply('100').toSignificant(4, { groupSeparator: ',' })}%`
                : 'To be determined'}
            </b>
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Total deposited </TYPE.white>
          <TYPE.white fontWeight={500}>
            <b>
              {stakingInfo && stakingInfo.valueOfTotalStakedAmountInUsd
                ? `$${stakingInfo.valueOfTotalStakedAmountInUsd.toSignificant(6, {
                    groupSeparator: ','
                  })}`
                : '-'}
            </b>
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Pool reward allocation </TYPE.white>
          <TYPE.white>{poolSharePercentage ? `${poolSharePercentage.toSignificant(4)}%` : '-'}</TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white> Emission rate </TYPE.white>
          <TYPE.white>
            {stakingInfo
              ? stakingInfo.active
                ? `${stakingInfo.poolRewardsPerBlock.toSignificant(4, {
                    groupSeparator: ','
                  })} 
                ${dappData.governanceToken?.symbol} / block`
                : `0 ${dappData.governanceToken?.symbol} / block`
              : '-'}
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white>Lockup Schedule</TYPE.white>
          <TYPE.white>
            {stakingInfo
              ? stakingInfo.active && stakingInfo.lockupSchedulePercentage !== 999999
                ? `${stakingInfo.lockupSchedulePercentage}%`
                : `-%`
              : '-%'}
          </TYPE.white>
        </RowBetween>
      </StatContainer>
      {isStaking && (
        <>
          <Break />
          <StatContainerTop>
            <RowBetween>
              <TYPE.white>Unlocked Rewards </TYPE.white>
              <TYPE.white>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.unlockedEarnedAmount.toSignificant(4, {
                        groupSeparator: ','
                      })} ${dappData.governanceToken?.symbol} / $${
                        govTokenPrice
                          ? stakingInfo.unlockedEarnedAmount
                              .multiply(govTokenPrice?.raw)
                              .multiply(govTokenPrice?.scalar)
                              .toSignificant(2, { groupSeparator: ',' })
                          : '0'
                      }`
                    : `0 ${dappData.governanceToken?.symbol}`
                  : '-'}
              </TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white>Locked Rewards </TYPE.white>
              <TYPE.white>
                {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.lockedEarnedAmount.toSignificant(4, {
                        groupSeparator: ','
                      })} ${dappData.governanceToken?.symbol} / $${
                        govTokenPrice
                          ? stakingInfo.lockedEarnedAmount
                              .multiply(govTokenPrice?.raw)
                              .multiply(govTokenPrice?.scalar)
                              .toSignificant(2, { groupSeparator: ',' })
                          : '0'
                      }`
                    : `0 ${dappData.governanceToken?.symbol}`
                  : '-'}
              </TYPE.white>
            </RowBetween>
          </StatContainerTop>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.white color={'white'} fontWeight={500}>
              Total Rewards{' '}
            </TYPE.white>

            <TYPE.white style={{ textAlign: 'center', paddingLeft: '5px' }} color={'white'} fontWeight={500}>
              {stakingInfo
                ? stakingInfo.active
                  ? `${stakingInfo.earnedAmount.toSignificant(4, {
                      groupSeparator: ','
                    })} ${dappData.governanceToken?.symbol} / $${
                      govTokenPrice
                        ? stakingInfo.earnedAmount
                            .multiply(govTokenPrice?.raw)
                            .multiply(govTokenPrice?.scalar)
                            .toSignificant(2, { groupSeparator: ',' })
                        : '0'
                    }`
                  : `0 ${dappData.governanceToken?.symbol}`
                : '-'}
            </TYPE.white>
          </BottomSection>
        </>
      )}
      <DialogueModal isOpen={showModal} onClose={closeModal} title={isStaking || isArchived ? 'Manage' : 'Deposit'}>
        <Manage
          currencyIdA={currencyId(currency0, baseCurrency, settings.blockchainSettings.currency)}
          currencyIdB={currencyId(currency1, baseCurrency, settings.blockchainSettings.currency)}
          dappData={dappData}
          stakingRewardInfo={stakingInfo}
        />
      </DialogueModal>
    </Wrapper>
  )
}
