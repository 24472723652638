import { TTwinVault } from 'constants/settings/types/TNpcs'
import { TSettings } from 'constants/settings/types/TSettings'
import { BigNumber } from 'ethers'
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useState, useEffect, useMemo } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { useTwinVaultInfo, TwinVaultInfo } from './useTwinVaultInfo'
import { getTokensInfo, tryParseAmount } from '../utils'
import { CurrencyAmount, Token } from '@/lib/sdk/index'

export type TuseTwinVaultState = {
  state: {
    account: string | null | undefined
    loading: boolean
    error: string | null
    twinVaultInfo: TwinVaultInfo
    rewardBalance: CurrencyAmount | null
    rewardBalanceTwo: CurrencyAmount | null
    isClaimAllToggled: boolean
    typedValue: string
    parsedAmount: CurrencyAmount | undefined
    stakeAmount: CurrencyAmount | null
    tokenToStake: Token | undefined
    tokenRewardOne: Token | undefined
    tokenRewardTwo: Token | undefined
    userDepositedTokenBalance: CurrencyAmount | null
    approval: ApprovalState
    signatureData: { v: number; r: string; s: string; deadline: number } | null
    deadline: BigNumber | undefined
  }

  handleInputChange: (value: string) => void
  toggleClaimAll: () => void
  approveCallback: () => Promise<void>
  setSignatureData: (data: { v: number; r: string; s: string; deadline: number } | null) => void
  setTypedValue: React.Dispatch<React.SetStateAction<string>>
}

export function useTwinVaultState(
  twinVault: TTwinVault,
  account: string | null | undefined,
  library: any,
  settings: TSettings
): TuseTwinVaultState {
  const currentBlock = useBlockNumber()
  const { twinVaultInfo, loading, error } = useTwinVaultInfo(twinVault, currentBlock)

  // Manage state
  const [rewardBalance, setRewardBalance] = useState<CurrencyAmount | null>(null)
  const [rewardBalanceTwo, setRewardBalanceTwo] = useState<CurrencyAmount | null>(null)
  const [isClaimAllToggled, setIsClaimAllToggled] = useState(false)
  const [typedValue, setTypedValue] = useState('')
  const [tokenToStake, setTokenToStake] = useState<Token | undefined>(undefined)
  const [tokenRewardOne, setTokenRewardOne] = useState<Token | undefined>(undefined)
  const [tokenRewardTwo, setTokenRewardTwo] = useState<Token | undefined>(undefined)
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [userDepositedTokenBalance, setUserDepositedTokenBalance] = useState<CurrencyAmount | null>(null)

  // Parse the typed value into a valid CurrencyAmount
  const parsedAmount = useMemo(() => {
    return tryParseAmount(typedValue, tokenToStake)
  }, [typedValue, tokenToStake])

  // Get transaction deadline
  const deadline = useTransactionDeadline()

  // Approval state and callback for staking
  const [approval, approveCallback] = useApproveCallback(parsedAmount, twinVault.contractAddress)

  // Handle input changes
  const handleInputChange = (value: string) => setTypedValue(value)
  const toggleClaimAll = () => setIsClaimAllToggled(prev => !prev)

  // Fetch Token Info based on contract addresses
  useEffect(() => {
    let isMounted = true
    if (twinVaultInfo && twinVaultInfo.stakeToken && library) {
      const fetchTokenInfo = async (isMounted: boolean) => {
        if (isMounted) {
          try {
            if (
              twinVaultInfo &&
              twinVaultInfo.stakeToken &&
              twinVaultInfo.rewardToken &&
              twinVaultInfo.rewardTokenTwo
            ) {
              // Use the contract address to get the token objects
              const [tokenStake, tokenRewardOne, tokenRewardTwo] = await getTokensInfo(
                settings.multiCallAddr,
                [twinVaultInfo.stakeToken[0], twinVaultInfo.rewardToken[0], twinVaultInfo.rewardTokenTwo[0]],
                library,
                settings.blockchainSettings.chainId
              )

              setTokenToStake(tokenStake)
              setTokenRewardOne(tokenRewardOne)
              setTokenRewardTwo(tokenRewardTwo)
              setUserDepositedTokenBalance(twinVaultInfo?.userInfo?.amount ?? null)
            }
          } catch (error) {
            console.error('Error fetching token info:', error)
          }
        }
      }

      fetchTokenInfo(isMounted)
    }
    return () => {
      isMounted = false
    }
  }, [twinVaultInfo, library, settings])

  // Logic for handling rewards and token state
  useEffect(() => {
    if (twinVaultInfo) {
      const { pendingReward, pendingRewardTwo } = twinVaultInfo

      if (pendingReward && pendingRewardTwo) {
        // Calculate pending rewards for Reward Token 1
        setRewardBalance(pendingReward)
        setRewardBalanceTwo(pendingRewardTwo)
      }
    }
  }, [twinVaultInfo, currentBlock])

  // Modify the withdrawFee in the return state to handle loading state
  return {
    state: {
      account,
      loading,
      error,
      twinVaultInfo,
      rewardBalance,
      rewardBalanceTwo,
      isClaimAllToggled,
      typedValue,
      parsedAmount,
      stakeAmount: twinVaultInfo.userInfo?.amount ?? null,
      tokenToStake,
      tokenRewardOne,
      tokenRewardTwo,
      userDepositedTokenBalance,
      approval,
      signatureData,
      deadline
    },
    handleInputChange,
    toggleClaimAll,
    approveCallback,
    setSignatureData,
    setTypedValue
  }
}
