import { AutoColumn } from 'components/Column'
import { NPC } from 'constants/settings/types/TNpcs'
import React from 'react'
import { TYPE } from 'theme'
import { SpriteFrameProps } from '../components/SpriteFrame'

export const createSingleFrame = (
  npc: NPC,
  textOverride?: string,
  overrideProps?: Partial<SpriteFrameProps>
): SpriteFrameProps => ({
  useSpriteSheet: true,
  imageUrl: npc.spritesheetUrl,
  frameWidth: npc.frameWidth,
  frameHeight: npc.frameHeight,
  numColumns: npc.spritesheetNumColumns,
  duration: 1,
  spriteRow: 0,
  content: (
    <AutoColumn gap="md">
      <TYPE.largeHeader color="text2">{npc.dialogue?.npcName ?? 'Merchant'}</TYPE.largeHeader>
      {textOverride ? (
        <TYPE.body>{textOverride}</TYPE.body>
      ) : (
        <TYPE.body>
          {npc.dialogue?.text && npc.dialogue.text.length
            ? npc.dialogue.text[Math.floor(Math.random() * npc.dialogue.text.length)]
            : ''}
        </TYPE.body>
      )}
    </AutoColumn>
  ),
  ...overrideProps
})
