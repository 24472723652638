import { NPCAnimatedSprite } from 'game/types/NPCAnimatedSprite'
import { Texture } from 'pixi.js'

// Function to update the NPC idle animation based on direction
export function updateIdleAnimation(npcSprite: NPCAnimatedSprite, lastDirection: string, rows: Texture[][]) {
  const originalWidth = npcSprite.width
  const originalHeight = npcSprite.height

  if (lastDirection === 'left') {
    npcSprite.textures = rows[1]
    npcSprite.scale.x = npcSprite.NPCData?.hasLeftFacingSprites ? 1 : -1 // Flip horizontally for left
  } else if (lastDirection === 'right') {
    npcSprite.textures = rows[1]
    npcSprite.scale.x = npcSprite.NPCData?.hasLeftFacingSprites ? -1 : 1 // Face right
  } else if (lastDirection === 'up') {
    npcSprite.textures = rows[2]
    npcSprite.scale.x = 1 // Face right
  } else if (lastDirection === 'down') {
    npcSprite.textures = rows[0]
    npcSprite.scale.x = 1 // Face right
  }

  // Preserve original size
  npcSprite.width = originalWidth
  npcSprite.height = originalHeight
  npcSprite.play()
}
