// TwinVaultContext.tsx

import { TTwinVault, TDappData } from 'constants/settings/types/TNpcs'
import { useActiveWeb3React } from 'hooks'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import { useTransactionHandling, TuseTransactionhandling } from './hooks/useTransactionHandling'
import { TuseTwinVaultState, useTwinVaultState } from './hooks/useTwinVaultState'

interface TwinVaultProviderProps {
  children: ReactNode
  twinVault: TTwinVault
  dappData: TDappData
}

type TuseTwinVaultContext = {
  twinVaultState: TuseTwinVaultState
  twinVault: TTwinVault
  transactionHandling: TuseTransactionhandling
  dappData: TDappData
}
// Create the context
const TwinVaultContext = createContext<TuseTwinVaultContext | undefined>(undefined)

// Provider component
export const TwinVaultProvider = ({ children, twinVault, dappData }: TwinVaultProviderProps) => {
  const { account, library } = useActiveWeb3React()
  const { settings } = useAppConstant()
  const twinVaultState = useTwinVaultState(twinVault, account, library, settings)
  const transactionHandling = useTransactionHandling(twinVaultState, twinVault)
  const contextValueMemo = useMemo(() => ({ twinVaultState, transactionHandling, twinVault, dappData }), [
    twinVaultState,
    transactionHandling,
    twinVault,
    dappData
  ])
  return <TwinVaultContext.Provider value={contextValueMemo}>{children}</TwinVaultContext.Provider>
}

// Custom hook to use the TwinVault context
export const useTwinVaultContext = () => {
  const context = useContext(TwinVaultContext)
  if (context === undefined) {
    throw new Error('useTwinVaultContext must be used within a TwinVaultProvider')
  }
  return context
}
