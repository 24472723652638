import { Contract } from '@ethersproject/contracts'
import { BigNumber, ethers } from 'ethers'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TokenAmount } from '@/lib/sdk/index'

interface BreakdownProps {
  farmContract: Contract | null
  sumRewards: TokenAmount
}

const BreakdownContainer = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-family: Arial, sans-serif;
`

const BreakdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 10px;
`

const LockedValue = styled.span`
  color: #787878;
`

const UnlockedValue = styled.span`
  color: ${({ theme }) => theme.primary2};
`

const Breakdown: React.FC<BreakdownProps> = ({ farmContract, sumRewards }) => {
  const [locked, setLocked] = useState<string>('0')
  const [unlocked, setUnlocked] = useState<string>('0')

  useEffect(() => {
    const calculateLockedAndUnlocked = async () => {
      if (farmContract && sumRewards && sumRewards.raw) {
        try {
          // Get the current block number
          const currentBlock = await farmContract.provider.getBlockNumber()

          // Call the contract to get the lockup percentage for the current block
          const lockupPercentage: BigNumber = await farmContract.getLockUpPercentage(currentBlock)

          // Convert lockup percentage to a base of 10000 (for precision, e.g., 98% -> 9800)
          const lockupPercentBase = lockupPercentage.toNumber()

          // Calculate locked and unlocked values
          const totalRewards = ethers.BigNumber.from(sumRewards.raw.toString())
          const lockedTokens = totalRewards.mul(lockupPercentBase).div(100) // Convert percentage to integer math
          const unlockedTokens = totalRewards.sub(lockedTokens)

          // Set the state with the calculated values (converting to string for display)
          setLocked(ethers.utils.formatUnits(lockedTokens, sumRewards.token.decimals))
          setUnlocked(ethers.utils.formatUnits(unlockedTokens, sumRewards.token.decimals))
        } catch (error) {
          console.error('Error calculating lockup:', error)
        }
      }
    }

    calculateLockedAndUnlocked()
  }, [farmContract, sumRewards])

  // Helper function to format numbers with 6 decimal places
  const formatValue = (value: string) => {
    const numValue = parseFloat(value)
    return numValue.toFixed(6) // Limit to 6 decimal places
  }

  return (
    <BreakdownContainer>
      <BreakdownItem>
        <span>Locked Token Balance:</span>
        <LockedValue>{formatValue(locked)}</LockedValue>
      </BreakdownItem>
      <BreakdownItem>
        <span>Unlocked Token Balance:</span>
        <UnlockedValue>{formatValue(unlocked)}</UnlockedValue>
      </BreakdownItem>
    </BreakdownContainer>
  )
}

export default Breakdown
