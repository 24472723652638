import { AutoColumn } from 'components/Column'
import styled from 'styled-components'

export const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
export const Wrapper = styled(AutoColumn)`
  justify-items: center;
  border-radius: 12px;
  width: 100%;
  position: relative;
  opacity: 1;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

export const MenuItem = styled.div<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.blue1}
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.white};
  padding: 15px 60px;
  margin: 20px 0;
  position: relative;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 0.1s ease-in-out;
  -webkit-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  background-color: ${({ theme }) => theme.bg2};
  text-decoration: none;
  text-align: center;

  &:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }

  &:active {
    transform: scale(0.98); /* Slightly shrink when clicked */
  }

  /* Red corner highlights only appear on hover */
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
  }

  &::before {
    top: -8px;
    left: -8px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  &::after {
    bottom: -8px;
    right: -8px;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }

  &:hover::before,
  &:hover::after {
    border: 1px solid ${({ theme }) => theme.red1}; /* Red corner on hover */
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 10px 20px;
    min-width: 75%;
    font-size: 14px;
  `};
`
export const StatusLabel = styled.div`
  position: absolute;
  top: 10px;
  left: -10px;
  background-color: ${({ theme }) => theme.red1}; /* Background color of the label */
  color: ${({ theme }) => theme.white};
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  transform-origin: 0 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
`
export const SectionTitle = styled.h3`
  margin: 20px 0 10px;
  color: ${({ theme }) => theme.white};
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.white};
  padding-bottom: 10px;
`

// Styled components for tabs and scrollable content
export const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.bg1};
`

export const TabButton = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  color: ${props => (props.active ? props.theme.red1 : props.theme.primary2)};
  background-color: ${props => (props.active ? props.theme.bg2 : 'transparent')};
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom: ${props => (props.active ? `2px solid ${props.theme.red1}` : '2px solid transparent')};

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

export const ContentWrapperModal = styled(AutoColumn)`
  max-height: 300px; /* Set max height for scrolling */
  overflow-y: auto;
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.bg2};
  justify-items: center;
`
