import { Interface } from '@ethersproject/abi'
import { TDappData } from 'constants/settings/types/TNpcs'
import { ethers } from 'ethers'
import useStablePrice from 'hooks/useStablePrice'
import { StakingRewardsInfo, useStakingRewardsInfo } from 'hooks/useStakingRewardsInfo'
import { useMemo, useEffect, useState } from 'react'
import { useBlockNumber } from 'state/application/hooks'
import { getCurrentLockUpPercentage } from 'utils/getCurrentLockUpPercentage'
import useUSDCPrice from 'utils/useUSDCPrice'
import { abi as IUniswapV2PairABI } from '../../constants/abis/contracts/IUniswapV2Pair.json'
import { useActiveWeb3React } from '../../hooks'
import useBUSDPrice from '../../hooks/useBUSDPrice'
import { useMasterFarmContract } from '../../hooks/useContract'
import useFilterStakingRewardsInfo from '../../hooks/useFilterStakingRewardsInfo'
import useTokensWithWETHPrices from '../../hooks/useTokensWithWETHPrices'
import { useAppSettings } from '../../providers/AppSettingsProvider/AppSettingsProvider'
import { useMultipleContractSingleData } from '../../state/multicall/hooks'
import calculateApr from '../../utils/calculateApr'
import calculateWethAdjustedTotalStakedAmount from '../../utils/calculateWethAdjustedTotalStakedAmount'
import determineBaseToken from '../../utils/determineBaseToken'
import getBlocksPerYear from '../../utils/getBlocksPerYear'
import validStakingInfo from '../../utils/validStakingInfo'
import { useSingleCallResult, useSingleContractMultipleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import { CurrencyAmount, JSBI, Token, TokenAmount, Pair, Fraction } from '@/lib/sdk/index'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export const STAKING_GENESIS = 6502000

export const REWARDS_DURATION_DAYS = 60
export interface StakingInfo {
  // the pool id (pid) of the pool
  pid: number
  // the tokens involved in this pair
  tokens: [Token, Token]
  // baseToken used for TVL & APR calculations
  baseToken: Token | undefined
  // the allocation point for the given pool
  allocPoint: JSBI
  // start block for all the rewards pools
  startBlock: number
  // base rewards per block
  baseRewardsPerBlock: TokenAmount
  // pool specific rewards per block
  poolRewardsPerBlock: TokenAmount
  // blocks generated per year
  blocksPerYear: JSBI
  // pool share vs all pools
  poolShare: Fraction
  // the percentage of rewards locked
  lockedRewardsPercentageUnits: number
  // the percentage of rewards locked
  unlockedRewardsPercentageUnits: number
  // the total supply of lp tokens in existence
  totalLpTokenSupply: TokenAmount
  // the amount of currently total staked tokens in the pool
  totalStakedAmount: TokenAmount
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the ratio of the user's share of the pool
  stakedRatio: Fraction
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account - which will be locked
  lockedEarnedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account - which will be unlocked
  unlockedEarnedAmount: TokenAmount
  // value of total staked amount, measured in weth
  valueOfTotalStakedAmountInWeth: TokenAmount | Fraction | undefined
  // value of total staked amount, measured in a USD stable coin (busd, usdt, usdc or a mix thereof)
  valueOfTotalStakedAmountInUsd: Fraction | undefined
  // pool APR
  apr: Fraction | undefined
  // if pool is active
  active: boolean
  // Dev Deposit Fee
  depositFee: string
  // lock up schedule
  lockupSchedulePercentage: number
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(
  active: boolean | undefined = undefined,
  dappData: TDappData,
  stakingInfo: StakingRewardsInfo[],
  pairToFilterBy?: Pair | null
): StakingInfo[] {
  const [lockupPercentage, setLockupPercentage] = useState<number>(999999)
  const { chainId, account } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const masterFarmContract = useMasterFarmContract(dappData)
  const currentBlock = useBlockNumber()

  const masterInfo = useFilterStakingRewardsInfo(chainId, active, stakingInfo, pairToFilterBy)
  const tokensWithPrices = useTokensWithWETHPrices(dappData)

  const weth = tokensWithPrices?.WETH?.token

  const wethBusdPrice = useBUSDPrice(dappData, weth)
  const wethUsdcPrice = useUSDCPrice(dappData, weth)
  const wethStablePrice = useStablePrice(dappData, weth)

  const wethStableBusdUsdcPrice = wethStablePrice ?? wethUsdcPrice ?? wethBusdPrice

  const govToken = tokensWithPrices?.govToken?.token
  const govTokenWETHPrice = tokensWithPrices?.govToken?.price
  const blocksPerYear = getBlocksPerYear(settings.blockchainSettings.blockTime)

  const pids = useMemo(() => masterInfo.map(({ pid }) => pid), [masterInfo])

  const pidAccountMapping = useMemo(
    () => masterInfo.map(({ pid }) => (account ? [pid, account] : [undefined, undefined])),
    [masterInfo, account]
  )

  const pendingRewards = useSingleContractMultipleData(masterFarmContract, 'pendingReward', pidAccountMapping)
  const userInfos = useSingleContractMultipleData(masterFarmContract, 'userInfo', pidAccountMapping)

  const poolInfos = useSingleContractMultipleData(
    masterFarmContract,
    'poolInfo',
    pids.map(pids => [pids])
  )

  const lpTokenAddresses = useMemo(() => {
    return poolInfos.reduce<string[]>((memo, poolInfo) => {
      if (poolInfo && !poolInfo.loading && poolInfo.result) {
        const [lpTokenAddress] = poolInfo.result
        memo.push(lpTokenAddress)
      }
      return memo
    }, [])
  }, [poolInfos])

  const lpTokenTotalSupplies = useMultipleContractSingleData(lpTokenAddresses, PAIR_INTERFACE, 'totalSupply')
  const lpTokenReserves = useMultipleContractSingleData(lpTokenAddresses, PAIR_INTERFACE, 'getReserves')
  const lpTokenBalances = useMultipleContractSingleData(lpTokenAddresses, PAIR_INTERFACE, 'balanceOf', [
    masterFarmContract?.address
  ])

  // getNewRewardPerBlock uses pid = 0 to return the base rewards
  // poolIds have to be +1'd to map to their actual pid
  // also include pid 0 to get the base emission rate
  let adjustedPids = pids.map(pid => pid + 1)
  adjustedPids = [...[0], ...adjustedPids]

  const poolRewardsPerBlock = useSingleContractMultipleData(
    masterFarmContract,
    'getNewRewardPerBlock',
    adjustedPids.map(adjustedPids => [adjustedPids])
  )

  //const poolLength = useSingleCallResult(masterFarmContract, 'poolLength')
  const startBlock = useSingleCallResult(masterFarmContract, 'START_BLOCK')
  const lockRewardsRatio = useSingleCallResult(
    masterFarmContract,
    'getLockUpPercentage',
    currentBlock ? [currentBlock] : [0]
  )

  //const rewardPerBlock = useSingleCallResult(masterFarmContract, 'REWARD_PER_BLOCK')

  useEffect(() => {
    const fetchLockupPercentage = async () => {
      if (masterFarmContract) {
        const percentage = await getCurrentLockUpPercentage(masterFarmContract)
        setLockupPercentage(percentage)
      }
    }

    fetchLockupPercentage()
  }, [masterFarmContract])

  return useMemo(() => {
    if (!chainId || !weth || !govToken) return []

    return pids.reduce<StakingInfo[]>((memo, pid, index) => {
      const tokens = masterInfo[index].tokens
      const poolInfo = poolInfos[index]

      // amount uint256, rewardDebt uint256, rewardDebtAtBlock uint256, lastWithdrawBlock uint256, firstDepositBlock uint256, blockdelta uint256, lastDepositBlock uint256
      const userInfo = userInfos[index]
      const pendingReward = pendingRewards[index]
      const lpTokenTotalSupply = lpTokenTotalSupplies[index]
      const lpTokenReserve = lpTokenReserves[index]
      const lpTokenBalance = lpTokenBalances[index]
      // poolRewardsPerBlock indexes have to be +1'd to get the actual specific pool data
      const baseRewardsPerBlock = poolRewardsPerBlock[0]
      const specificPoolRewardsPerBlock = poolRewardsPerBlock[index + 1]

      if (
        validStakingInfo(
          tokens,
          poolInfo,
          pendingReward,
          userInfo,
          baseRewardsPerBlock,
          specificPoolRewardsPerBlock,
          lockRewardsRatio,
          lpTokenTotalSupply,
          lpTokenReserve,
          lpTokenBalance,
          startBlock
        )
      ) {
        const baseBlockRewards = new TokenAmount(govToken, JSBI.BigInt(baseRewardsPerBlock?.result?.[0] ?? 0))

        const poolBlockRewards = specificPoolRewardsPerBlock?.result?.[0]
          ? new TokenAmount(govToken, JSBI.BigInt(specificPoolRewardsPerBlock?.result?.[0] ?? 0))
          : baseBlockRewards

        const poolShare = new Fraction(poolBlockRewards.raw, baseBlockRewards.raw)

        const lockedRewardsPercentageUnits = Number(lockRewardsRatio.result?.[0] ?? 0)
        const unlockedRewardsPercentageUnits = 100 - lockedRewardsPercentageUnits

        const calculatedTotalPendingRewards = JSBI.BigInt(pendingReward?.result?.[0] ?? 0)
        const calculatedLockedPendingRewards = JSBI.divide(
          JSBI.multiply(calculatedTotalPendingRewards, JSBI.BigInt(lockedRewardsPercentageUnits)),
          JSBI.BigInt(100)
        )
        const calculatedUnlockedPendingRewards = JSBI.divide(
          JSBI.multiply(calculatedTotalPendingRewards, JSBI.BigInt(unlockedRewardsPercentageUnits)),
          JSBI.BigInt(100)
        )

        if (!dappData.factoryAddress || !dappData.initHash) {
          throw new Error('Factory address or init hash is undefined')
        }

        const dummyPair = new Pair(
          new TokenAmount(tokens[0], '0'),
          new TokenAmount(tokens[1], '0'),
          dappData.factoryAddress,
          dappData.initHash
        )
        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(userInfo?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(lpTokenBalance.result?.[0] ?? 0)
        )
        const stakedRatio = new Fraction(stakedAmount.raw, totalStakedAmount.raw)

        const totalLpTokenSupply = new TokenAmount(
          dummyPair.liquidityToken,
          JSBI.BigInt(lpTokenTotalSupply.result?.[0] ?? 0)
        )
        const totalPendingRewardAmount = new TokenAmount(govToken, calculatedTotalPendingRewards)
        const totalPendingLockedRewardAmount = new TokenAmount(govToken, calculatedLockedPendingRewards)
        const totalPendingUnlockedRewardAmount = new TokenAmount(govToken, calculatedUnlockedPendingRewards)
        const startsAtBlock = startBlock.result?.[0] ?? 0

        // poolInfo: lpToken address, allocPoint uint256, lastRewardBlock uint256, accGovTokenPerShare uint256
        const poolInfoResult = poolInfo.result
        const allocPoint = JSBI.BigInt(poolInfoResult && poolInfoResult[1])
        const active = poolInfoResult && JSBI.GT(JSBI.BigInt(allocPoint), 0) ? true : false
        let depositFee = '999999'

        if (poolInfoResult) {
          const etherValue = ethers.utils.formatEther(poolInfoResult[2])
          depositFee = (Number(etherValue) * 100).toFixed(2) + '%'
        }

        const baseToken = determineBaseToken(
          tokensWithPrices,
          tokens,
          settings.blockchainSettings.currency,
          settings.wrappedCurrency
        )

        if (!dappData.factoryAddress || !dappData.initHash) {
          throw new Error('Factory address or init hash is undefined')
        }
        const totalStakedAmountWETH = calculateWethAdjustedTotalStakedAmount(
          chainId,
          baseToken,
          tokensWithPrices,
          tokens,
          totalLpTokenSupply,
          totalStakedAmount,
          lpTokenReserve?.result,
          dappData.factoryAddress,
          dappData.initHash,
          settings.blockchainSettings.currency,
          settings.wrappedCurrency
        )

        const totalStakedAmountInStableBusdUsdPrice =
          wethStableBusdUsdcPrice && totalStakedAmountWETH
            ? totalStakedAmountWETH?.multiply(wethStableBusdUsdcPrice.adjusted)
            : undefined

        const apr = totalStakedAmountWETH
          ? calculateApr(govTokenWETHPrice, baseBlockRewards, blocksPerYear, poolShare, totalStakedAmountWETH)
          : undefined

        const stakingInfo = {
          pid: pid,
          allocPoint: allocPoint,
          tokens: tokens,
          baseToken: baseToken,
          startBlock: startsAtBlock,
          baseRewardsPerBlock: baseBlockRewards,
          poolRewardsPerBlock: poolBlockRewards,
          blocksPerYear: blocksPerYear,
          poolShare: poolShare,
          lockedRewardsPercentageUnits: lockedRewardsPercentageUnits,
          unlockedRewardsPercentageUnits: unlockedRewardsPercentageUnits,
          totalLpTokenSupply: totalLpTokenSupply,
          totalStakedAmount: totalStakedAmount,
          stakedAmount: stakedAmount,
          stakedRatio: stakedRatio,
          earnedAmount: totalPendingRewardAmount,
          lockedEarnedAmount: totalPendingLockedRewardAmount,
          unlockedEarnedAmount: totalPendingUnlockedRewardAmount,
          valueOfTotalStakedAmountInWeth: totalStakedAmountWETH,
          valueOfTotalStakedAmountInUsd: totalStakedAmountInStableBusdUsdPrice,
          apr: apr,
          active: active,
          depositFee: depositFee,
          lockupSchedulePercentage: lockupPercentage
        }
        memo.push(stakingInfo)
      }
      return memo
    }, [])
  }, [
    chainId,
    masterInfo,
    tokensWithPrices,
    weth,
    govToken,
    govTokenWETHPrice,
    pids,
    poolInfos,
    userInfos,
    pendingRewards,
    lpTokenTotalSupplies,
    lpTokenReserves,
    lpTokenBalances,
    blocksPerYear,
    startBlock,
    lockRewardsRatio,
    poolRewardsPerBlock
  ])
}

export function useTotalGovTokensEarned(
  dappData: TDappData,
  stakingInfo: StakingRewardsInfo[]
): TokenAmount | undefined {
  const stakingInfos = useStakingInfo(true, dappData, stakingInfo)

  return useMemo(() => {
    if (!dappData.governanceToken) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(dappData.governanceToken, '0')
      ) ?? new TokenAmount(dappData.governanceToken, '0')
    )
  }, [stakingInfos, dappData.governanceToken])
}

export function useTotalLockedGovTokensEarned(dappData: TDappData): TokenAmount | undefined {
  const { stakingRewardsInfo, fetchStakingRewards } = useStakingRewardsInfo(dappData)

  // Automatically fetch the staking rewards information when the component mounts
  useEffect(() => {
    fetchStakingRewards()
  }, [fetchStakingRewards])
  const stakingInfos = useStakingInfo(true, dappData, stakingRewardsInfo)

  return useMemo(() => {
    if (!dappData.governanceToken) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.lockedEarnedAmount),
        new TokenAmount(dappData.governanceToken, '0')
      ) ?? new TokenAmount(dappData.governanceToken, '0')
    )
  }, [stakingInfos, dappData.governanceToken])
}

export function useTotalUnlockedGovTokensEarned(dappData: TDappData): TokenAmount | undefined {
  const { stakingRewardsInfo, fetchStakingRewards } = useStakingRewardsInfo(dappData)

  // Automatically fetch the staking rewards information when the component mounts
  useEffect(() => {
    fetchStakingRewards()
  }, [fetchStakingRewards])

  const stakingInfos = useStakingInfo(true, dappData, stakingRewardsInfo)

  return useMemo(() => {
    if (!dappData.governanceToken) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.unlockedEarnedAmount),
        new TokenAmount(dappData.governanceToken, '0')
      ) ?? new TokenAmount(dappData.governanceToken, '0')
    )
  }, [stakingInfos, dappData.governanceToken])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = stakingAmount
    ? tryParseAmount(typedValue, stakingAmount.token)
    : undefined

  const parsedAmount =
    parsedInput && stakingAmount && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
