import React, { useEffect, useState } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { BannerContainer, ToggleButton, RingingBell } from './WarningBanner.styles'

interface WarningBannerProps {
  warnings?: string[]
  showWarning: boolean
}

const warningMessages = [
  "Welcome, explorers! Our GUI is evolving, and we're excited to share the progress as we continue to improve it.",
  "Hey, adventurers! We're still fine-tuning our GUI, and we appreciate your patience as we make things better.",
  'Greetings, travelers! Our GUI is undergoing some updates—stay tuned for a smoother, more intuitive experience soon!',
  "Hello, intrepid explorers! Our GUI is getting a makeover, and we're excited for you to see the improvements coming your way.",
  'Welcome, fellow adventurers! Our GUI is still a work in progress, but big enhancements are on the horizon!'
]

const WarningBanner: React.FC<WarningBannerProps> = ({ warnings, showWarning }) => {
  const [randomWarning, setRandomWarning] = useState('')
  const [expanded, setExpanded] = useState(false)
  const displayWarnings = warnings && warnings.length > 0 ? warnings : warningMessages

  const toggleExpand = () => {
    setExpanded(prev => !prev)
  }

  useEffect(() => {
    if (showWarning) {
      const randomIndex = Math.floor(Math.random() * displayWarnings.length)
      setRandomWarning(displayWarnings[randomIndex])
    }
  }, [warnings, showWarning])

  if (!showWarning) {
    return null
  }

  return (
    <>
      <BannerContainer expanded={expanded}>{expanded && <div>{randomWarning}</div>}</BannerContainer>

      <ToggleButton expanded={expanded} onClick={toggleExpand}>
        <RingingBell expanded={expanded} />
        {expanded ? <ArrowDownCircle /> : null}
      </ToggleButton>
    </>
  )
}

export default WarningBanner
