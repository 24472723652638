import styled from 'styled-components'

// Styled components for the dropdown
export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const DropdownButton = styled.a`
  cursor: pointer;
  font-size: 12px;
  display: flex;
  margin: 0 auto;
  color: ${({ theme }) => theme.text1};
  justify-content: space-between;
  width: 100px;
  height: 20px;
  align-items: center;
`

export const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  background-color: #2c2f36;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1;
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;

  /* Ensure content respects boundaries */
  width: 80vw;
  max-width: 450px;
  max-height: 450px;

  /* Prevent horizontal scroll and enforce wrapping */
  overflow-x: hidden;
`

export const DropdownItem = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
  padding: 0.75rem 1.25rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  font-size: 1rem;
  font-weight: 500;
  color: ${({ isSelected, isDisabled }) => (isDisabled ? '#B0B0B0' : isSelected ? '#fff' : '#8a8f98')};
  background-color: ${({ isSelected }) => (isSelected ? '#3a82f7' : 'transparent')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  max-width: 100%; /* Enforces the item to stay within parent width */

  /* Text wrapping and preventing overflow */
  white-space: normal; /* Allows wrapping */
  overflow-wrap: break-word; /* Breaks long words */
  word-wrap: break-word; /* Legacy support */
  word-break: break-word; /* Ensures word breaking */
  text-overflow: ellipsis; /* Adds ellipsis if needed */
  box-sizing: border-box;

  &:hover {
    background-color: ${({ isSelected, isDisabled }) =>
      isDisabled ? 'transparent' : isSelected ? '#3a82f7' : '#353840'};
    color: ${({ isDisabled }) => (isDisabled ? '#B0B0B0' : '#fff')};
  }
`

export const ConnectedLabel = styled.span`
  background-color: #1fd984;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
`

export const TbaLabel = styled.span`
  background-color: #007bff;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
`
