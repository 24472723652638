import React, { useState, useEffect, useRef, ReactNode } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'
import styled, { keyframes } from 'styled-components'

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`

const scrollAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(10px); }
`

const ScrollButton = styled.button<{ isAtBottom: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary1 || '#1e3a8a'};
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  animation: ${props => !props.isAtBottom && scrollAnimation} 1s infinite;
  width: 50px;
  height: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

  transform: scale(1);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 0 5px ${({ theme }) => theme.primary1});
  }
`

const ScrollContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
`

interface ScrollArrowButtonProps {
  children: ReactNode
}

export const ScrollArrowButton: React.FC<ScrollArrowButtonProps> = ({ children }) => {
  const [isAtBottom, setIsAtBottom] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current

    const handleScroll = () => {
      if (scrollContainer) {
        const isBottom =
          Math.abs(scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight) < 1
        setIsAtBottom(isBottom)
      }
    }

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollContainerRef])

  const scrollToPosition = () => {
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      if (isAtBottom) {
        scrollContainer.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        scrollContainer.scrollTo({ top: scrollContainer.scrollHeight, behavior: 'smooth' })
      }
    }
  }

  return (
    <ScrollContainer ref={scrollContainerRef}>
      {/* Add temporary content to test scrollability */}
      {children}
      <ButtonWrapper>
        <ScrollButton onClick={scrollToPosition} isAtBottom={isAtBottom}>
          {isAtBottom ? <ArrowUp size={24} /> : <ArrowDown size={24} />}
        </ScrollButton>
      </ButtonWrapper>
    </ScrollContainer>
  )
}
