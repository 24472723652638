import { TDappData } from 'constants/settings/types/TNpcs'
import { useAppSettings } from 'providers/AppSettingsProvider/AppSettingsProvider'
import React from 'react'
import { useActiveWeb3React } from '../../hooks'
import useFilterStakingInfos from '../../hooks/useFilterStakingInfos'
import useTotalCombinedTVL from '../../hooks/useTotalCombinedTVL'
import { useStakingInfo } from '../../state/stake/hooks'
import { CustomMouseoverTooltip } from '../Tooltip/custom'

export default function CombinedTVL({ dappData }: { dappData: TDappData }) {
  const { chainId } = useActiveWeb3React()
  const { settings } = useAppSettings()
  const vaultTokensSettings = chainId ? settings.vaultToken : undefined
  const isActive = true
  const filteredStakingInfos = useFilterStakingInfos(useStakingInfo(isActive, dappData), isActive)
  const TVLs = useTotalCombinedTVL(filteredStakingInfos, dappData)

  return (
    <>
      {TVLs?.stakingPoolTVL?.greaterThan('0') && (
        <CustomMouseoverTooltip
          element={
            <>
              {TVLs.stakingPoolTVL?.greaterThan('0') && (
                <>
                  <b>Staking:</b> $
                  {TVLs.stakingPoolTVL.toSignificant(8, {
                    groupSeparator: ','
                  })}
                  <br />
                </>
              )}
              {TVLs.totalVaultTokensTVL?.greaterThan('0') && (
                <>
                  <b>{vaultTokensSettings?.name}:</b> $
                  {TVLs.totalVaultTokensTVL.toSignificant(8, {
                    groupSeparator: ','
                  })}
                  <br />
                </>
              )}
              {TVLs.totalCombinedTVL?.greaterThan('0') && (
                <>
                  <b>Total:</b> $
                  {TVLs.totalCombinedTVL.toSignificant(8, {
                    groupSeparator: ','
                  })}
                </>
              )}
            </>
          }
        >
          {TVLs.totalCombinedTVL?.greaterThan('0') && (
            <>TVL: ${TVLs.totalCombinedTVL.toSignificant(8, { groupSeparator: ',' })}</>
          )}
        </CustomMouseoverTooltip>
      )}
    </>
  )
}
