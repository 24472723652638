import { Interface } from '@ethersproject/abi'
import { TDappData } from 'constants/settings/types/TNpcs'
import { useToken } from 'hooks/Tokens'
import useTokenWETHPrice from 'hooks/useTokenWETHPrice'
import { useAppConstant } from 'providers/AppSettingsProvider/hooks/useAppConstant'
import { useMultipleContractSingleData } from 'state/multicall/hooks'
import { calculateTwinVaultApr } from 'utils/calculateApr'
import getBlocksPerYear from 'utils/getBlocksPerYear'
import { abi as IUniswapV2PairABI } from '../../../constants/abis/contracts/IUniswapV2Pair.json'
import { Token, CurrencyAmount, Fraction, TokenAmount } from '@/lib/sdk/index'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export interface TuseAprHandling {
  dappData: TDappData
  tokenToStake: Token | undefined
  tokenRewardOne: Token | undefined
  tokenRewardTwo: Token | undefined
  rewardPerBlock: CurrencyAmount | null
  rewardTwoPerBlock: CurrencyAmount | null
  totalStakeTokenBalance: CurrencyAmount | null
}

export function useAprHandling({
  dappData,
  tokenToStake,
  tokenRewardOne,
  tokenRewardTwo,
  rewardPerBlock,
  rewardTwoPerBlock,
  totalStakeTokenBalance
}: TuseAprHandling): Fraction | undefined {
  // hook(s)
  const { settings } = useAppConstant()

  const blocksPerYear = getBlocksPerYear(settings.blockchainSettings.blockTime)

  const pairToken0AddressResult = useMultipleContractSingleData([tokenToStake?.address], PAIR_INTERFACE, 'token0')
  const pairToken0Address = pairToken0AddressResult[0]?.result

  const pairToken1AddressResult = useMultipleContractSingleData([tokenToStake?.address], PAIR_INTERFACE, 'token1')
  const pairToken1Address = pairToken1AddressResult[0]?.result

  const pairToken0 = useToken(pairToken0Address ? (pairToken0Address[0] as string | undefined) : undefined)
  const pairToken1 = useToken(pairToken1Address ? (pairToken1Address[0] as string | undefined) : undefined)

  const tokenToUse = settings.wrappedCurrency.address == pairToken0?.address ? 1 : 0

  const lpTokenReservesResult = useMultipleContractSingleData([tokenToStake?.address], PAIR_INTERFACE, 'getReserves')
  const lpTokenReserves = lpTokenReservesResult[0]?.result

  const tokenRewardOneWethPrice = useTokenWETHPrice(tokenRewardOne, dappData)
  const tokenRewardTwoWethPrice = useTokenWETHPrice(tokenRewardTwo, dappData)

  const pairToken0WethPrice = useTokenWETHPrice(pairToken0 ?? undefined, dappData)
  const pairToken1WethPrice = useTokenWETHPrice(pairToken1 ?? undefined, dappData)

  let apr = undefined

  if (
    !tokenToStake ||
    !tokenRewardOne ||
    !tokenRewardTwo ||
    !rewardPerBlock ||
    !rewardTwoPerBlock ||
    !totalStakeTokenBalance ||
    !tokenRewardOneWethPrice ||
    !tokenRewardTwoWethPrice ||
    !lpTokenReserves ||
    !pairToken0Address ||
    !pairToken0 ||
    !pairToken1 ||
    !pairToken0WethPrice ||
    !pairToken1WethPrice
  ) {
    return apr
  }
  const tokenAmount =
    tokenToUse == 0
      ? new TokenAmount(pairToken0, lpTokenReserves?.reserve0)
      : new TokenAmount(pairToken1, lpTokenReserves?.reserve1)

  const pairTokenToUseWethPrice = tokenToUse == 0 ? pairToken0WethPrice : pairToken1WethPrice

  const valueOfTotalStakedAmountInPairCurrency = tokenAmount.multiply(pairTokenToUseWethPrice).multiply('2')

  console.log('bill price', parseFloat(tokenRewardOneWethPrice.toFixed(6)) * 0.109422)
  console.log('sea price', parseFloat(tokenRewardTwoWethPrice.toFixed(6)) * 0.109422)
  console.log('rewardPerBlock', rewardPerBlock.toFixed(6))
  console.log('rewardTwoPerBlock', rewardTwoPerBlock.toFixed(6))
  console.log('blocksPerYear', blocksPerYear.toString())
  console.log(
    'valueOfTotalStakedAmountInPairCurrency',
    parseFloat(valueOfTotalStakedAmountInPairCurrency.toFixed(6)) * 0.109422
  )

  apr = calculateTwinVaultApr(
    tokenRewardOneWethPrice,
    tokenRewardTwoWethPrice,
    rewardPerBlock,
    rewardTwoPerBlock,
    blocksPerYear,
    valueOfTotalStakedAmountInPairCurrency
  )

  return apr
}
